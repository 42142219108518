import React from "react";
import LocationList from "./card/LocationList";
import CategoryList from "./card/CategoryList";

const SearchBar = () => {
  return (
    <div className="w-full sm:pt-10">
      <div className="w-full  sm:hidden pt-2 pb-3 flex items-center mx-auto justify-center Accent_Red_bg">
        <div className="flex text-md sm:text-xl w-5/6">
          <input
            type="text"
            className="w-full rounded-tl-full rounded-bl-full placeholder-[#D1D1D1] text-[#191308] pl-5 py-2 border-2 border-[#F6AE28] focus:outline-none border-x-0 "
            placeholder="Search providers/services"
          />
          <button className="bg-white px-2 sm:px-4 py-2 rounded-tr-full rounded-br-full border-2 border-[#F6AE28]  border-l-0 ">
            <img
              className="w-6 sm:w-10"
              src="https://img.icons8.com/?size=100&id=111487&format=png&color=F6AE28"
              alt="search icon"
            />
          </button>
        </div>
      </div>
      <div className="pt-2 sm:pt-0 hidden sm:flex items-center mx-auto justify-center pb-4 sm:pb-8 font-semibold">
        <h2 className="flex text-xl sm:text-3xl text-[#F6AE28] capitalize text-center ">
          Search using the following filter
        </h2>
      </div>
      <div className="max-w-screen-lg py-3 my-2 border border-[#F6AE28] sm:py-0 sm:my-0 sm:border-0 flex items-center mx-auto pl-8 sm:pl-0 sm:justify-center">
        <div className="flex text-md gap-11 sm:text-xl ">
          <button
            onClick={() =>
              document.getElementById("locationList_modal").showModal()
            }
            className="flex items-center px-1 py-1 rounded-md text-[#F6AE28] font-semibold transition hover:font-bold "
          >
            <img
              className="w-7"
              src="https://img.icons8.com/?size=100&id=3723&format=png&color=F6AE28"
              alt="LocationIcon"
            />
            <span className="ml-2">Location</span>
          </button>
          <button 
          onClick={() =>
            document.getElementById("CategoryList_modal").showModal()
          }
          className="flex items-center px-1 py-1 rounded-md text-[#F6AE28] font-semibold transition hover:font-bold ">
            <img
              className="w-7"
              src="https://img.icons8.com/?size=100&id=66014&format=png&color=F6AE28"
              alt="CategoryIcon"
            />
            <span className="ml-2">Category</span>
          </button>
          {/* <select className="capitalize bg-white pl-4 py-2 w-20 sm:w-28 rounded-tl-full rounded-bl-full border-2 border-[#F6AE28]   sm:border-r-8 border-r-transparent overflow-y-hidden">
            <option
              className="text-[#D1D1D1] font-semibold cursor-pointer"
              value="filter"
            >
              District
            </option>
            <option className="font-medium cursor-pointer" value="filter">
              Dhaka
            </option>
            <option className="font-medium cursor-pointer" value="filter">
              Rajshahi
            </option>
          </select>
          <select className="capitalize bg-white py-2 w-14 sm:w-24  border-2 border-[#F6AE28]  border-x-0 overflow-y-hidden ">
            <option
              className="text-[#D1D1D1] font-semibold cursor-pointer  "
              value="filter"
            >
              Thana
            </option>
            <option className="font-medium cursor-pointer " value="filter">
              Ramna
            </option>
            <option className="font-medium cursor-pointer " value="filter">
              Banani
            </option>
          </select>
          <select className="capitalize bg-white py-2 w-20 sm:w-40  border-2 border-[#F6AE28]  sm:border-l-8 border-x-transparent overflow-y-hidden ">
            <option
              className="text-[#D1D1D1] font-semibold cursor-pointer  "
              value="filter"
            >
              Service Type
            </option>
            <option className="font-medium cursor-pointer " value="filter">
              Electritian
            </option>
            <option className="font-medium cursor-pointer " value="filter">
              Tiles
            </option>
          </select>
          <select className="rounded-tr-full rounded-br-full capitalize bg-white py-2 w-24 sm:w-40  border-2 border-[#F6AE28]  border-x-transparent  overflow-y-hidden ">
            <option
              className="text-[#D1D1D1] font-semibold cursor-pointer  "
              value="filter"
            >
              Provider Type
            </option>
            <option className="font-medium cursor-pointer " value="filter">
              Business
            </option>
            <option className="font-medium cursor-pointer " value="filter">
              Individual
            </option>
          </select> */}
        </div>
        <div className="hidden sm:flex text-[10px] sm:text-xl ml-10">
          <input
            type="text"
            className=" rounded-tl-full rounded-bl-full placeholder-[#D1D1D1] text-[#191308] pl-4 sm:pl-7 pb-1 border-2 border-[#F6AE28] focus:outline-none border-x-0 "
            placeholder="Type here for search"
          />
          <button className="bg-white px-2 py-2 rounded-tr-full rounded-br-full border-2 border-[#F6AE28]  border-l-0 ">
            <img
              className="w-6 sm:w-10"
              src="https://img.icons8.com/?size=100&id=111487&format=png&color=F6AE28"
              alt="search icon"
            />
          </button>
        </div>
      </div>
      {/* modal section  */}
      <LocationList />
      <CategoryList />
    </div>
  );
};

export default SearchBar;
