import React from "react";
import { Link } from "react-router-dom";
import YoutubeModal from "./YoutubeModal";

const TutorialVideo = () => {
  const ytId = "r-thd4PJKBw";
  const ytId2 = "jDgEqhz94-Y";
  return (
    <div className="">
      <Link to={"/tutorial"}>
        <h2 className="text-xl md:text-4xl font-bold text-[#F6AE28] mb-4 pt-8 sm:pt-0 underline">
          Tutorial
        </h2>
      </Link>
      <div className="grid grid-cols-1 sm:grid-cols-12 gap-10">
        <div className="sm:col-span-6 ">
          <Link
            to={"#"}
            onClick={() => document.getElementById("ytVideo_modal").showModal()}
          >
            <div
              className="h-40 bg-cover text-center overflow-hidden relative"
              style={{
                backgroundImage:
                  " url('https://img.youtube.com/vi/" +
                  ytId +
                  "/maxresdefault.jpg')",
              }}
            >
              <div className="absolute inset-0 bg-gradient-to-t from-gray-300 via-gray-300/40 hover:from-gray-200"></div>

              <div className="absolute inset-0 flex justify-center items-center z-10">
                <img
                  src="https://img.icons8.com/?size=100&id=qLVB1tIe9Ts9&format=png&color=000000"
                  alt="youtubeIcon"
                  className="w-16"
                />
              </div>
            </div>
          </Link>
          <div className="border border-t bg-[#d1d1d1] rounded-b lg:rounded-b-none lg:rounded-r flex flex-col justify-between leading-normal">
            <div className="p-2 ">
              <button
                onClick={() =>
                  document.getElementById("ytVideo_modal").showModal()
                }
                className=" mb-2 hover:text-[#B80000] transition duration-500 ease-in-out"
              >
                <h3 className="text-left font-bold text-lg ">
                  House construction process step by step
                </h3>
              </button>
              <p className="text-gray-700 text-xs mt-2 two-lines">
                House construction process step by step on 7 x 14 meter lot
                area. Complete house construction step by step Building
                construction process step by step House construction work
              </p>
            </div>
          </div>
        </div>

        <div className="sm:col-span-6 text-[rgb(209,209,209)] h-80 overflow-auto">
          <div className="flex items-start mb-3 pb-3">
            <Link to={"#"} className="inline-block mr-3">
              <div
                onClick={() =>
                  document.getElementById("ytVideo_modal").showModal()
                }
                className="w-20 h-12 bg-cover bg-center relative"
                style={{
                  backgroundImage:
                    "url('https://img.youtube.com/vi/" +
                    ytId2 +
                    "/maxresdefault.jpg')",
                }}
              >
                <div className="absolute inset-0 bg-gradient-to-t from-gray-300 via-gray-300/40 hover:from-gray-200"></div>

                <div className="absolute inset-0 flex justify-center items-center z-10">
                  <img
                    src="https://img.icons8.com/?size=100&id=qLVB1tIe9Ts9&format=png&color=000000"
                    alt="youtubeIcon"
                    className="w-5"
                  />
                </div>
              </div>
            </Link>
            <div className="">
              <p className="text-xs">Aug 24</p>
              <Link
                onClick={() =>
                  document.getElementById("ytVideo_modal").showModal()
                }
                to={"#"}
                className="font-medium hover:text-[#B80000] leading-none"
              >
                <h4 className="text-sm font-semibold">
                  How To Lay Bricks For Beginners Using a line
                </h4>
              </Link>
            </div>
          </div>
          <div className="flex items-start mb-3 pb-3">
            <Link to={"#"} className="inline-block mr-3">
              <div
                onClick={() =>
                  document.getElementById("ytVideo_modal").showModal()
                }
                className="w-20 h-12 bg-cover bg-center relative"
                style={{
                  backgroundImage:
                    "url('https://img.youtube.com/vi/" +
                    ytId2 +
                    "/maxresdefault.jpg')",
                }}
              >
                <div className="absolute inset-0 bg-gradient-to-t from-gray-300 via-gray-300/40 hover:from-gray-200"></div>

                <div className="absolute inset-0 flex justify-center items-center z-10">
                  <img
                    src="https://img.icons8.com/?size=100&id=qLVB1tIe9Ts9&format=png&color=000000"
                    alt="youtubeIcon"
                    className="w-5"
                  />
                </div>
              </div>
            </Link>
            <div className="">
              <p className="text-xs">Aug 24</p>
              <Link
                onClick={() =>
                  document.getElementById("ytVideo_modal").showModal()
                }
                to={"#"}
                className="font-medium hover:text-[#B80000] leading-none"
              >
                <h4 className="text-sm font-semibold">
                  How To Lay Bricks For Beginners Using a line
                </h4>
              </Link>
            </div>
          </div>
          <div className="flex items-start mb-3 pb-3">
            <Link to={"#"} className="inline-block mr-3">
              <div
                onClick={() =>
                  document.getElementById("ytVideo_modal").showModal()
                }
                className="w-20 h-12 bg-cover bg-center relative"
                style={{
                  backgroundImage:
                    "url('https://img.youtube.com/vi/" +
                    ytId2 +
                    "/maxresdefault.jpg')",
                }}
              >
                <div className="absolute inset-0 bg-gradient-to-t from-gray-300 via-gray-300/40 hover:from-gray-200"></div>

                <div className="absolute inset-0 flex justify-center items-center z-10">
                  <img
                    src="https://img.icons8.com/?size=100&id=qLVB1tIe9Ts9&format=png&color=000000"
                    alt="youtubeIcon"
                    className="w-5"
                  />
                </div>
              </div>
            </Link>
            <div className="">
              <p className="text-xs">Aug 24</p>
              <Link
                onClick={() =>
                  document.getElementById("ytVideo_modal").showModal()
                }
                to={"#"}
                className="font-medium hover:text-[#B80000] leading-none"
              >
                <h4 className="text-sm font-semibold">
                  How To Lay Bricks For Beginners Using a line
                </h4>
              </Link>
            </div>
          </div>
          <div className="flex items-start mb-3 pb-3">
            <Link to={"#"} className="inline-block mr-3">
              <div
                onClick={() =>
                  document.getElementById("ytVideo_modal").showModal()
                }
                className="w-20 h-12 bg-cover bg-center relative"
                style={{
                  backgroundImage:
                    "url('https://img.youtube.com/vi/" +
                    ytId2 +
                    "/maxresdefault.jpg')",
                }}
              >
                <div className="absolute inset-0 bg-gradient-to-t from-gray-300 via-gray-300/40 hover:from-gray-200"></div>

                <div className="absolute inset-0 flex justify-center items-center z-10">
                  <img
                    src="https://img.icons8.com/?size=100&id=qLVB1tIe9Ts9&format=png&color=000000"
                    alt="youtubeIcon"
                    className="w-5"
                  />
                </div>
              </div>
            </Link>
            <div className="">
              <p className="text-xs">Aug 24</p>
              <Link
                onClick={() =>
                  document.getElementById("ytVideo_modal").showModal()
                }
                to={"#"}
                className="font-medium hover:text-[#B80000] leading-none"
              >
                <h4 className="text-sm font-semibold">
                  How To Lay Bricks For Beginners Using a line
                </h4>
              </Link>
            </div>
          </div>
          <div className="flex items-start mb-3 pb-3">
            <Link to={"#"} className="inline-block mr-3">
              <div
                onClick={() =>
                  document.getElementById("ytVideo_modal").showModal()
                }
                className="w-20 h-12 bg-cover bg-center relative"
                style={{
                  backgroundImage:
                    "url('https://img.youtube.com/vi/" +
                    ytId2 +
                    "/maxresdefault.jpg')",
                }}
              >
                <div className="absolute inset-0 bg-gradient-to-t from-gray-300 via-gray-300/40 hover:from-gray-200"></div>

                <div className="absolute inset-0 flex justify-center items-center z-10">
                  <img
                    src="https://img.icons8.com/?size=100&id=qLVB1tIe9Ts9&format=png&color=000000"
                    alt="youtubeIcon"
                    className="w-5"
                  />
                </div>
              </div>
            </Link>
            <div className="">
              <p className="text-xs">Aug 24</p>
              <Link
                onClick={() =>
                  document.getElementById("ytVideo_modal").showModal()
                }
                to={"#"}
                className="font-medium hover:text-[#B80000] leading-none"
              >
                <h4 className="text-sm font-semibold">
                  How To Lay Bricks For Beginners Using a line
                </h4>
              </Link>
            </div>
          </div>
        </div>
      </div>
      {/* Modal section  */}
      <YoutubeModal ytId={ytId} />
    </div>
  );
};

export default TutorialVideo;
