import React from "react";
import { Link } from "react-router-dom";

const LocationList = () => {
  // const [isVisible, setIsVisible] = useState(false);
  // const handleList = () => {
  //   setIsVisible(!isVisible)
  // };
  const handleList = (e) => {
    e.stopPropagation();

    let submenu = e.target.querySelector("ul");
    let downArrow = e.target.querySelector("img:first-child");
    let rightArrow = e.target.querySelector("img:last-child");
    console.log(submenu);
    if (!submenu) return;

    if (submenu.style.display === "none" || !submenu.style.display) {
      submenu.style.display = "block";
      downArrow.style.display = "block";
      rightArrow.style.display = "none";
      
    } else {
      submenu.style.display = "none";
      downArrow.style.display = "none";
      rightArrow.style.display = "block";
    }
  };

  return (
    <div>
      <dialog id="locationList_modal" className="modal ">
        <div className="modal-box">
          <div className="modal-action pr-2 mt-0">
            <form method="dialog">
              <button className="p-1 Organic_Black bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg">
                <svg
                  className="w-5 h-5"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </button>
            </form>
          </div>
          <div className="mt-2 relative z-10">
            <h2 className="flex items-center text-2xl font-semibold">
              Select City or Division
            </h2>

            {/* <div className="flex pt-4">
              <select className="capitalize bg-white pl-4 py-2 w-20 sm:w-28 rounded-tl-full rounded-bl-full border-2 border-[#F6AE28]   sm:border-r-8 border-r-transparent overflow-y-hidden">
                <option
                  className="text-[#D1D1D1] font-semibold cursor-pointer"
                  value="filter"
                >
                  District
                </option>
                <option className="font-medium cursor-pointer" value="filter">
                  Dhaka
                </option>
                <option className="font-medium cursor-pointer" value="filter">
                  Rajshahi
                </option>
              </select>
              <select className="capitalize bg-white py-2 w-14 sm:w-24  border-2 border-[#F6AE28]  border-x-0 overflow-y-hidden ">
                <option
                  className="text-[#D1D1D1] font-semibold cursor-pointer  "
                  value="filter"
                >
                  Thana
                </option>
                <option className="font-medium cursor-pointer " value="filter">
                  Ramna
                </option>
                <option className="font-medium cursor-pointer " value="filter">
                  Banani
                </option>
              </select>
              <select className="capitalize bg-white py-2 w-20 sm:w-40  border-2 border-[#F6AE28]  sm:border-l-8 border-x-transparent overflow-y-hidden ">
                <option
                  className="text-[#D1D1D1] font-semibold cursor-pointer  "
                  value="filter"
                >
                  Service Type
                </option>
                <option className="font-medium cursor-pointer " value="filter">
                  Electritian
                </option>
                <option className="font-medium cursor-pointer " value="filter">
                  Tiles
                </option>
              </select>
              <select className="rounded-tr-full rounded-br-full capitalize bg-white py-2 w-24 sm:w-40  border-2 border-[#F6AE28]  border-x-transparent  overflow-y-hidden ">
                <option
                  className="text-[#D1D1D1] font-semibold cursor-pointer  "
                  value="filter"
                >
                  Provider Type
                </option>
                <option className="font-medium cursor-pointer " value="filter">
                  Business
                </option>
                <option className="font-medium cursor-pointer " value="filter">
                  Individual
                </option>
              </select>
            </div> */}
            <ul className="pl-4 mt-4 w-full text-left font-medium text-lg leading-none border-[#d1d1d1] divide-y divide-[#d1d1d1]">
              <li className="border-b ">
                <Link
                  to={"#"}
                  className="relative pl-3 py-4 cursor-pointer flex flex-col  hover:font-semibold"
                >
                  <span className="absolute top-3 left-0 w-1 h-7 bg-[#F6AE28] rounded-r-md"></span>
                  All of Bangladesh
                </Link>
              </li>
            </ul>
            <div className="pt-9">
              <div className="py-2 divide-0 text-xl"> Cites</div>

              <ul className="border-b text-left font-medium text-lg leading-none divide-y divide-gray-300 rounded-md py-2 pl-4">
                <li
                  onClick={handleList}
                  className="relative pl-3 py-4 cursor-pointer flex flex-col  hover:font-semibold"
                >
                  <span className="absolute top-3 left-0 w-1 h-7 bg-[#F6AE28] rounded-r-md"></span>
                  Dhaka
                  <span className="absolute right-5 top-4">
                    
                      <img
                        className="w-5 downArrow"
                        style={{ display: "none" }}
                        src="https://img.icons8.com/?size=100&id=39786&format=png&color=000000"
                        alt="downArrow"
                      />
                    
                      <img
                        className="w-5 rightArrow"
                        src="https://img.icons8.com/?size=100&id=7849&format=png&color=000000"
                        alt="rightArrow"
                      />
                    
                  </span>
                  <ul
                    style={{ display: "none" }}
                    className="border-b text-left text-md font-light leading-none divide-y divide-gray-300 w-full flex-1 rounded-md px-4 py-2 mt-2"
                  >
                    <li className="py-3 hover:font-normal">
                      <div className="flex ">
                        <span className="">Rampura</span>
                      </div>
                    </li>
                    <li className="py-3 hover:font-normal">
                      <div className="flex  ">
                        <span className="">Dhanmondi</span>
                      </div>
                    </li>
                  </ul>
                </li>

                <li className="relative pl-3 py-4 cursor-pointer flex flex-col  hover:font-semibold"
                >
                  <span className="absolute top-3 left-0 w-1 h-7 bg-[#F6AE28] rounded-r-md"></span>
                  Rajshahi
                </li>
                <li className="relative pl-3 py-4 cursor-pointer flex flex-col  hover:font-semibold"
                >
                  <span className="absolute top-3 left-0 w-1 h-7 bg-[#F6AE28] rounded-r-md"></span>
                  Khulna
                </li>
                <li className="relative pl-3 py-4 cursor-pointer flex flex-col  hover:font-semibold"
                >
                  <span className="absolute top-3 left-0 w-1 h-7 bg-[#F6AE28] rounded-r-md"></span>
                  Chattagram
                </li>
              </ul>
            </div>
            <div className="pt-9">
              <div className="py-2 divide-0 text-xl"> Divisions</div>

              <ul className="border-b text-left font-medium text-lg leading-none divide-y divide-gray-300 rounded-md py-2 pl-4">
                <li
                  onClick={handleList}
                  className="relative pl-3 py-4 cursor-pointer flex flex-col  hover:font-semibold"
                >
                  <span className="absolute top-3 left-0 w-1 h-7 bg-[#F6AE28] rounded-r-md"></span>
                  Dhaka Division
                  <span className="absolute right-5 top-4">
                    
                      <img
                        className="w-5 downArrow"
                        style={{ display: "none" }}
                        src="https://img.icons8.com/?size=100&id=39786&format=png&color=000000"
                        alt="downArrow"
                      />
                   
                      <img
                        className="w-5 rightArrow"
                        src="https://img.icons8.com/?size=100&id=7849&format=png&color=000000"
                        alt="rightArrow"
                      />
                   
                  </span>
                  <ul
                    style={{ display: "none" }}
                    className="border-b text-left text-md font-light leading-none divide-y divide-gray-300 w-full flex-1 rounded-md px-4 py-2 mt-2"
                  >
                    <li className="py-3 hover:font-normal">
                      <div className="flex ">
                        <span className="">All of Dhaka Division</span>
                      </div>
                    </li>
                    <li className="py-3 hover:font-normal">
                      <div className="flex  ">
                        <span className="">Tangail</span>
                      </div>
                    </li>
                    <li className="py-3 hover:font-normal">
                      <div className="flex  ">
                        <span className="">Gazipur</span>
                      </div>
                    </li>
                    <li className="py-3 hover:font-normal">
                      <div className="flex  ">
                        <span className="">Faridpur</span>
                      </div>
                    </li>
                  </ul>
                </li>
                <li
                  onClick={handleList}
                  className="relative pl-3 py-4 cursor-pointer flex flex-col  hover:font-semibold"
                >
                  <span className="absolute top-3 left-0 w-1 h-7 bg-[#F6AE28] rounded-r-md"></span>
                  Chattogram Division
                  <span className="absolute right-5 top-4">
                    
                      <img
                        className="w-5 downArrow"
                        style={{ display: "none" }}
                        src="https://img.icons8.com/?size=100&id=39786&format=png&color=000000"
                        alt="downArrow"
                      />
                    
                      <img
                        className="w-5 rightArrow"
                        src="https://img.icons8.com/?size=100&id=7849&format=png&color=000000"
                        alt="rightArrow"
                      />
                   
                  </span>
                  <ul
                    style={{ display: "none" }}
                    className="border-b text-left text-md font-light leading-none divide-y divide-gray-300 w-full flex-1 rounded-md px-4 py-2 mt-2"
                  >
                    <li className="py-3 hover:font-normal">
                      <div className="flex ">
                        <span className="">All of Chattogram Division</span>
                      </div>
                    </li>
                    <li className="py-3 hover:font-normal">
                      <div className="flex  ">
                        <span className="">Cumilla</span>
                      </div>
                    </li>
                    <li className="py-3 hover:font-normal">
                      <div className="flex  ">
                        <span className="">Noakhali</span>
                      </div>
                    </li>
                  </ul>
                </li>
                <li
                  onClick={handleList}
                  className="relative pl-3 py-4 cursor-pointer flex flex-col  hover:font-semibold"
                >
                  <span className="absolute top-3 left-0 w-1 h-7 bg-[#F6AE28] rounded-r-md"></span>
                  Rajshahi Division
                  <span className="absolute right-5 top-4">
                    
                      <img
                        className="w-5 downArrow"
                        style={{ display: "none" }}
                        src="https://img.icons8.com/?size=100&id=39786&format=png&color=000000"
                        alt="downArrow"
                      />
                   
                      <img
                        className="w-5 rightArrow"
                        src="https://img.icons8.com/?size=100&id=7849&format=png&color=000000"
                        alt="rightArrow"
                      />
                   
                  </span>
                  <ul
                    style={{ display: "none" }}
                    className="border-b text-left text-md font-light leading-none divide-y divide-gray-300 w-full flex-1 rounded-md px-4 py-2 mt-2"
                  >
                    <li className="py-3 hover:font-normal">
                      <div className="flex ">
                        <span className="">All of Rajshahi Division</span>
                      </div>
                    </li>
                    <li className="py-3 hover:font-normal">
                      <div className="flex  ">
                        <span className="">Pabna</span>
                      </div>
                    </li>
                    <li className="py-3 hover:font-normal">
                      <div className="flex  ">
                        <span className="">Natore</span>
                      </div>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>

            {/* <ul className="w-full text-left font-medium text-lg leading-none border-[#d1d1d1] divide-y border-b divide-[#d1d1d1]">
              <li onClick={handleList} className="py-3.5 ">
                <span className="ml-5 mr-2.5 w-1 h-7 bg-[#F6AE28] rounded-r-md"></span>
                <span className="w-full flex items-center text-[#191308]  hover:bg-amber-50">
                  Dhaka
                </span>
                <ul
                  style={{ display: "none" }}
                  className=" pl-5 w-full text-left font-medium text-lg leading-none border-[#d1d1d1] divide-y border-t divide-[#d1d1d1]"
                >
                  <li>
                    <Link
                      className="py-3.5 w-full flex items-center text-[#191308]  hover:bg-amber-50"
                      to={"#"}
                    >
                      <span className="ml-5 mr-2.5 w-1 h-7 bg-[#F6AE28] rounded-r-md"></span>
                      Rampura
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="py-3.5 w-full flex items-center text-[#191308]  hover:bg-amber-50"
                      to={"#"}
                    >
                      <span className="ml-5 mr-2.5 w-1 h-7 bg-[#F6AE28] rounded-r-md"></span>
                      Dhanmondi
                    </Link>
                  </li>
                </ul>
              </li>

              <li>
                <Link
                  className="dropdown py-3.5 w-full flex items-center text-[#191308]  hover:bg-amber-50"
                  to={"#"}
                  onClick={handleList}
                >
                  <span className="ml-5 mr-2.5 w-1 h-7 bg-[#F6AE28] rounded-r-md"></span>
                  Chattagram
                  <span className="absolute right-4 top-4">
                    {isVisible ? (
                      <img
                        src="https://img.icons8.com/?size=100&id=2760&format=png&color=000000"
                        alt="downArrow"
                        className="w-5"
                      />
                    ) : (
                      <img
                        src="https://img.icons8.com/?size=100&id=7849&format=png&color=000000"
                        alt="downArrow"
                        className="w-5"
                      />
                    )}
                  </span>
                </Link>
                {isVisible && (
                  <ul className="pl-5 w-full text-left font-medium text-lg leading-none border-[#d1d1d1] divide-y border-t divide-[#d1d1d1]">
                    <li>
                      <Link
                        className="py-3.5 w-full flex items-center text-[#191308]  hover:bg-amber-50"
                        to={"#"}
                      >
                        <span className="ml-5 mr-2.5 w-1 h-7 bg-[#F6AE28] rounded-r-md"></span>
                        Rampura
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="py-3.5 w-full flex items-center text-[#191308]  hover:bg-amber-50"
                        to={"#"}
                      >
                        <span className="ml-5 mr-2.5 w-1 h-7 bg-[#F6AE28] rounded-r-md"></span>
                        Dhanmondi
                      </Link>
                    </li>
                  </ul>
                )}
              </li>
              <li>
                <Link
                  className="py-3.5 w-full flex items-center text-[#191308]  hover:bg-amber-50"
                  to={"#"}
                >
                  <span className="ml-5 mr-2.5 w-1 h-7 bg-[#F6AE28] rounded-r-md"></span>
                  Rajshahi
                </Link>
              </li>
              <li>
                <Link
                  className="py-3.5 w-full flex items-center text-[#191308]  hover:bg-amber-50"
                  to={"#"}
                >
                  <span className="ml-5 mr-2.5 w-1 h-7 bg-[#F6AE28] rounded-r-md"></span>
                  Khulna
                </Link>
              </li>
            </ul>
            <div className="pt-10 pb-3 divide-0 text-xl"> Divisions</div>
            <ul className="w-full text-left font-medium text-lg leading-none border-[#d1d1d1] divide-y border-b divide-[#d1d1d1]">
              <li>
                <Link
                  className="py-3.5 w-full flex items-center text-[#191308]  hover:bg-amber-50"
                  to={"#"}
                >
                  <span className="ml-5 mr-2.5 w-1 h-7 bg-[#F6AE28] rounded-r-md"></span>
                  Dhaka Division
                </Link>
              </li>
              <li>
                <Link
                  className="py-3.5 w-full flex items-center text-[#191308]  hover:bg-amber-50"
                  to={"#"}
                >
                  <span className="ml-5 mr-2.5 w-1 h-7 bg-[#F6AE28] rounded-r-md"></span>
                  Chattagram Division
                </Link>
              </li>
              <li>
                <Link
                  className="py-3.5 w-full flex items-center text-[#191308]  hover:bg-amber-50"
                  to={"#"}
                >
                  <span className="ml-5 mr-2.5 w-1 h-7 bg-[#F6AE28] rounded-r-md"></span>
                  Rajshahi Division
                </Link>
              </li>
              <li>
                <Link
                  className="py-3.5 w-full flex items-center text-[#191308]  hover:bg-amber-50"
                  to={"#"}
                >
                  <span className="ml-5 mr-2.5 w-1 h-7 bg-[#F6AE28] rounded-r-md"></span>
                  Khulna Division
                </Link>
              </li>
            </ul> */}
          </div>
        </div>
      </dialog>
    </div>
  );
};

export default LocationList;
