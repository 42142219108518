import React from "react";

const YoutubeModal = (props) => {
  return (
    <div>
      <dialog id="ytVideo_modal" className="modal ">
        <div className="modal-box max-w-screen-md mx-auto">
          <div className="modal-action pr-2 mt-0">
            <form method="dialog">
              <button className="p-1 Organic_Black bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg">
                <svg
                  className="w-5 h-5"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </button>
            </form>
          </div>

          <div className="max-w-screen-lg mx-auto py-5  text-[#191308]">
            <div className="mb-10 rounded overflow-hidden flex flex-col mx-auto">
              <div className="flex items-center w-full col-span-6">
                <div className="px-6 h-96 lg:h-100% w-full max-w-2xl col-span-6 flex items-center mx-auto">
                  <div className="w-full h-full">
                    <div className="w-full h-full">
                      <iframe
                        width="100%"
                        height="100%"  
                        src={"https://www.youtube.com/embed/"+props.ytId+"?si=dXEirkzHbZueXgBU"}
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        referrerpolicy="strict-origin-when-cross-origin"
                        allowfullscreen
                      ></iframe>
                    </div>
                  </div>
                </div>
              </div>
              <div className="pt-7 pl-3 sm:pl-8">
                <h2 className="text-xl sm:text-2xl font-semibold inline-block mb-2">
                House construction process step by step
                </h2>
              </div>

              <div className="pl-3 sm:pl-8">
                <p className="text-[#191308] py-5 text-md leading-8">
                House construction process step by step on 7 x 14 meter lot
                area. Complete house construction step by step Building
                construction process step by step House construction work
                </p>
              </div>
            </div>
          </div>
        </div>
      </dialog>
    </div>
  );
};

export default YoutubeModal;
