import React from "react";
import { useEffect } from "react";
import AboutImg from "../assets/img/user-about-sec-img.png";

import HwRegister from "../components/card/HwRegister";

function Users() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="pt-16 ">
      <div className="sm:flex items-center max-w-screen-xl mx-auto ">
        <div className="sm:w-1/2  sm:p-10 ">
          <div data-aos="zoom-out"
                data-aos-easing="linear"
                data-aos-delay="700" data-aos-once="true" className="image object-center text-center ">
            <img src={AboutImg} alt="user-about-sec-img" />
          </div>
        </div>
        <div className="sm:w-1/2 p-5">
          <div  className="text">
            <h2 data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-delay="700" data-aos-once="true"  className="my-4 font-bold text-3xl Orange_Yellow sm:text-4xl ">
              Welcome{" "}
              <span className="Light_Grey">
                to the heart of the BMC Portal!
              </span>
            </h2>
            <p data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-delay="1000" data-aos-once="true" className="Light_Grey text-justify">
              This is where you connect with the skilled professionals who will
              bring your construction vision to life. Whether you're a homeowner
              embarking on a dream renovation or a construction professional
              seeking to expand your reach, the BMC Portal provides the tools
              and connections you need. Our user-friendly platform caters to
              both sides of the construction equation, streamlining
              communication, fostering collaboration, and ensuring a successful
              project journey.
            </p>
          </div>
        </div>
      </div>
      <div className="bg-gradient-to-r from-red-700 to-red-800 w-full mb-20">
        <div className="max-w-7xl mx-auto py-8 px-4 sm:py-10 sm:px-6 lg:px-8  lg:py-12">
          <div className="max-w-6xl mx-auto  sm:grid sm:grid-cols-4 sm:gap-8 ">
            <div className="flex flex-col sm:col-span-2 md:col-span-3 pb-3">
              <h2 data-aos="fade-right"
                data-aos-easing="linear"
                data-aos-delay="500" data-aos-once="true" className="pb-1 text-2xl  md:text-3xl font-semibold Light_Grey text-center sm:text-left">
                Turn your dream project into reality. Sign up as a homeowner
                today and connect with qualified professionals to bring your
                vision to life!
              </h2>
            </div>
            <div className="flex flex-col pt-3 sm:col-span-2 md:col-span-1">
              <button
              data-aos="fade-left"
              data-aos-easing="linear"
              data-aos-delay="500"
              data-aos-once="true"
                className="px-4 md:px-2 sm:px-6 py-2 sm:py-4 text-center Organic_Black Orange_Yellow_bg border border_Orange_Yellow rounded hover:bg-transparent hover:text-gray-300 "
                
                onClick={() =>
                  document.getElementById("homewonerRegister_modal").showModal()
                }
              >
                <div className="order-2 text-2xl sm:text-2xl lg:text-3xl md:text-xl font-semibold ">
                  Homeowners
                </div>
                <div className="order-1 text-xl sm:text-xl lg:text-2xl md:text-lg font-bold ">Register</div>
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* homeowner Register modal  */}
      <HwRegister />
    </div>
  );
}

export default Users;
