import React from "react";
import Faq from "../components/Faq";
import { useEffect } from "react";

function Support() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="pt-16 bg-gray-50 bg-opacity-10">
      <div className="">
        <div className="max-w-7xl mx-auto py-8 px-4 sm:px-6 lg:py-20 lg:px-8">
          <div className="max-w-2xl lg:max-w-4xl mx-auto text-center">
            <h2
              className="text-3xl font-extrabold Orange_Yellow"
              id="contactUs"
              data-aos="zoom-out"
              data-aos-anchor-placement="center-bottom" data-aos-once="true"
            >
              Visit Our Location
            </h2>
            <p data-aos="zoom-in" data-aos-once="true" className="mt-3 text-lg Light_Grey">
              Let us serve you the best
            </p>
          </div>
          <div className="mt-8 lg:mt-20">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
              <div>
                <div 
                data-aos="fade-left"
                data-aos-duration="700" data-aos-once="true"
                className="max-w-full mx-auto rounded-lg overflow-hidden">
                  <div className="border-t border-[#D1D1D1] px-6 py-4">
                    <h3 className="text-lg font-bold Light_Grey">Contact</h3>
                    <p className="mt-1 font-bold Light_Grey">
                      <a href="tel:+8801313444716">Phone: +880 1313 444716</a>
                    </p>
                    <a className="flex m-1" href="tel:+8801313444716">
                      <div className="flex-shrink-0">
                        <div className="flex items-center justify-between h-10 w-30 rounded-md bg-[#F6AE28] text-[#191308] p-2">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="currentColor"
                            className="w-6 h-6"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z"
                            />
                          </svg>
                          Call now
                        </div>
                      </div>
                    </a>
                  </div>
                  <div className="border-t border-gray-200 px-6 py-4">
                    <h3 className="text-lg font-medium Light_Grey capitalize">
                      SEND US A MESSAGE
                    </h3>
                    <p className="mt-1 font-bold Light_Grey">
                      <a href="mailto:bmc.bangladesh@yahoo.com">
                        Email: bmc.bangladesh@yahoo.com
                      </a>
                    </p>
                  </div>
                  <div className="px-6 py-4">
                    <h3 className="text-lg font-medium Light_Grey">
                      Our Address
                    </h3>
                    <p className="mt-1 Light_Grey">
                      Head Office : <br />
                      House # KA-24, Pragati Sarani A Mozid Tower,
                      <br />
                      4th Floor, Kuril, Vatara Dhaka - 1229
                    </p>
                  </div>
                </div>
              </div>
              <div 
              data-aos="fade-right"
                data-aos-duration="1000" data-aos-once="true"
              className="rounded-lg overflow-hidden order-none sm:order-first bg-white bg-opacity-80">
                <iframe
                  title="map"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3650.1679809818156!2d90.41843997426088!3d23.812624986386737!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3755c6528dde05e5%3A0x95c580d55b05cdd4!2sBMC%20GROUP%20-%20Head%20Office%20%7C%20Super%20Salt%20Industry%20in%20Bangladesh!5e0!3m2!1sen!2sbd!4v1723716912942!5m2!1sen!2sbd"
                  className="w-full border-0"
                  width="600"
                  height="450"
                  allowFullScreen=""
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
        <div className=" w-full h-px max-w-6xl mx-auto mt-2  lineSingle"></div>
        <Faq />
      </div>
    </div>
  );
}

export default Support;
