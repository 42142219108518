import React from 'react'
import { useEffect } from 'react'

function Providers() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div className='pt-16'>
       
      <main className="grid min-h-full place-items-center bg-white bg-opacity-10 px-6 py-24  sm:py-64 lg:px-8">
        <h1
          data-aos="fade-right"
          data-aos-duration="900"
          className="Light_Grey font-extrabold text-3xl"
        >
          Providers
        </h1>
      </main>
    </div>
  )
}

export default Providers
