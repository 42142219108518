import React from "react";
import { Link } from "react-router-dom";
import YoutubeModal from "../components/card/YoutubeModal";

const Tutorial = () => {
  const ytId = "r-thd4PJKBw";
  return (
    <div className="pt-16 max-w-screen-xl mx-auto">
      <div className="py-10 px-8 bg-white bg-opacity-10">
        <h2 className="text-xl md:text-4xl font-bold text-[#F6AE28] mb-4 pt-8 sm:pt-0">
          Tutorial
        </h2>
        <div className="flex flex-wrap mx-auto items-stretch space-y-4 text-left flex-col sm:flex-row sm:space-y-0  sm:text-center">
          <div className="w-full sm:w-1/3 pr-5 pb-5">
            <Link
              className="flex w-full items-center rounded-xl hover:!shadow-[#d1d1d1] 
             border border-[#d1d1d1] border-opacity-10 px-4 py-6 text-[#d1d1d1]
              duration-200 hover:border-opacity-0 hover:no-underline 
               flex-col hover:shadow-sm "
              to={"#"}
              onClick={() =>
                document.getElementById("ytVideo_modal").showModal()
              }
            >
              <div className="relative">
                <img
                  className="mr-4 w-full sm:mr-0  "
                  src={
                    "https://img.youtube.com/vi/" + ytId + "/maxresdefault.jpg"
                  }
                  alt="Framework7"
                />
                <div className="absolute inset-0 bg-gradient-to-t from-gray-300 via-gray-300/40 hover:from-gray-200"></div>

                <div className="absolute inset-0 flex justify-center items-center z-10">
                  <img
                    src="https://img.icons8.com/?size=100&id=qLVB1tIe9Ts9&format=png&color=000000"
                    alt="youtubeIcon"
                    className="w-16"
                  />
                </div>
              </div>
              <div>
                <div className="font-semibold text-xl text-[#d1d1d1] dark:text-white sm:mt-4 sm:mb-2">
                House construction process step by step
                </div>
                <div className="text-xs opacity-75 two-lines">
                House construction process step by step on 7 x 14 meter lot
                area. Complete house construction step by step Building
                construction process step by step House construction work
                </div>
              </div>
            </Link>
          </div>
          <div className="w-full sm:w-1/3 pr-5 pb-5">
            <Link
              className="flex w-full items-center rounded-xl hover:!shadow-[#d1d1d1] 
             border border-[#d1d1d1] border-opacity-10 px-4 py-6 text-[#d1d1d1]
              duration-200 hover:border-opacity-0 hover:no-underline 
               flex-col hover:shadow-sm "
              to={"#"}
              onClick={() =>
                document.getElementById("ytVideo_modal").showModal()
              }
            >
              <div className="relative">
                <img
                  className="mr-4 w-full sm:mr-0  "
                  src={
                    "https://img.youtube.com/vi/" + ytId + "/maxresdefault.jpg"
                  }
                  alt="Framework7"
                />
                <div className="absolute inset-0 bg-gradient-to-t from-gray-300 via-gray-300/40 hover:from-gray-200"></div>

                <div className="absolute inset-0 flex justify-center items-center z-10">
                  <img
                    src="https://img.icons8.com/?size=100&id=qLVB1tIe9Ts9&format=png&color=000000"
                    alt="youtubeIcon"
                    className="w-16"
                  />
                </div>
              </div>
              <div>
                <div className="font-semibold text-xl text-[#d1d1d1] dark:text-white sm:mt-4 sm:mb-2">
                House construction process step by step
                </div>
                <div className="text-xs opacity-75 two-lines">
                House construction process step by step on 7 x 14 meter lot
                area. Complete house construction step by step Building
                construction process step by step House construction work
                </div>
              </div>
            </Link>
          </div>
          <div className="w-full sm:w-1/3 pr-5 pb-5">
            <Link
              className="flex w-full items-center rounded-xl hover:!shadow-[#d1d1d1] 
             border border-[#d1d1d1] border-opacity-10 px-4 py-6 text-[#d1d1d1]
              duration-200 hover:border-opacity-0 hover:no-underline 
               flex-col hover:shadow-sm "
              to={"#"}
              onClick={() =>
                document.getElementById("ytVideo_modal").showModal()
              }
            >
              <div className="relative">
                <img
                  className="mr-4 w-full sm:mr-0  "
                  src={
                    "https://img.youtube.com/vi/" + ytId + "/maxresdefault.jpg"
                  }
                  alt="Framework7"
                />
                <div className="absolute inset-0 bg-gradient-to-t from-gray-300 via-gray-300/40 hover:from-gray-200"></div>

                <div className="absolute inset-0 flex justify-center items-center z-10">
                  <img
                    src="https://img.icons8.com/?size=100&id=qLVB1tIe9Ts9&format=png&color=000000"
                    alt="youtubeIcon"
                    className="w-16"
                  />
                </div>
              </div>
              <div>
                <div className="font-semibold text-xl text-[#d1d1d1] dark:text-white sm:mt-4 sm:mb-2">
                House construction process step by step
                </div>
                <div className="text-xs opacity-75 two-lines">
                House construction process step by step on 7 x 14 meter lot
                area. Complete house construction step by step Building
                construction process step by step House construction work
                </div>
              </div>
            </Link>
          </div>
          <div className="w-full sm:w-1/3 pr-5 pb-5">
            <Link
              className="flex w-full items-center rounded-xl hover:!shadow-[#d1d1d1] 
             border border-[#d1d1d1] border-opacity-10 px-4 py-6 text-[#d1d1d1]
              duration-200 hover:border-opacity-0 hover:no-underline 
               flex-col hover:shadow-sm "
              to={"#"}
              onClick={() =>
                document.getElementById("ytVideo_modal").showModal()
              }
            >
              <div className="relative">
                <img
                  className="mr-4 w-full sm:mr-0  "
                  src={
                    "https://img.youtube.com/vi/" + ytId + "/maxresdefault.jpg"
                  }
                  alt="Framework7"
                />
                <div className="absolute inset-0 bg-gradient-to-t from-gray-300 via-gray-300/40 hover:from-gray-200"></div>

                <div className="absolute inset-0 flex justify-center items-center z-10">
                  <img
                    src="https://img.icons8.com/?size=100&id=qLVB1tIe9Ts9&format=png&color=000000"
                    alt="youtubeIcon"
                    className="w-16"
                  />
                </div>
              </div>
              <div>
                <div className="font-semibold text-xl text-[#d1d1d1] dark:text-white sm:mt-4 sm:mb-2">
                House construction process step by step
                </div>
                <div className="text-xs opacity-75 two-lines">
                House construction process step by step on 7 x 14 meter lot
                area. Complete house construction step by step Building
                construction process step by step House construction work
                </div>
              </div>
            </Link>
          </div>
          <div className="w-full sm:w-1/3 pr-5 pb-5">
            <Link
              className="flex w-full items-center rounded-xl hover:!shadow-[#d1d1d1] 
             border border-[#d1d1d1] border-opacity-10 px-4 py-6 text-[#d1d1d1]
              duration-200 hover:border-opacity-0 hover:no-underline 
               flex-col hover:shadow-sm "
              to={"#"}
              onClick={() =>
                document.getElementById("ytVideo_modal").showModal()
              }
            >
              <div className="relative">
                <img
                  className="mr-4 w-full sm:mr-0  "
                  src={
                    "https://img.youtube.com/vi/" + ytId + "/maxresdefault.jpg"
                  }
                  alt="Framework7"
                />
                <div className="absolute inset-0 bg-gradient-to-t from-gray-300 via-gray-300/40 hover:from-gray-200"></div>

                <div className="absolute inset-0 flex justify-center items-center z-10">
                  <img
                    src="https://img.icons8.com/?size=100&id=qLVB1tIe9Ts9&format=png&color=000000"
                    alt="youtubeIcon"
                    className="w-16"
                  />
                </div>
              </div>
              <div>
                <div className="font-semibold text-xl text-[#d1d1d1] dark:text-white sm:mt-4 sm:mb-2">
                House construction process step by step
                </div>
                <div className="text-xs opacity-75 two-lines">
                House construction process step by step on 7 x 14 meter lot
                area. Complete house construction step by step Building
                construction process step by step House construction work
                </div>
              </div>
            </Link>
          </div>
          <div className="w-full sm:w-1/3 pr-5 pb-5">
            <Link
              className="flex w-full items-center rounded-xl hover:!shadow-[#d1d1d1] 
             border border-[#d1d1d1] border-opacity-10 px-4 py-6 text-[#d1d1d1]
              duration-200 hover:border-opacity-0 hover:no-underline 
               flex-col hover:shadow-sm "
              to={"#"}
              onClick={() =>
                document.getElementById("ytVideo_modal").showModal()
              }
            >
              <div className="relative">
                <img
                  className="mr-4 w-full sm:mr-0  "
                  src={
                    "https://img.youtube.com/vi/" + ytId + "/maxresdefault.jpg"
                  }
                  alt="Framework7"
                />
                <div className="absolute inset-0 bg-gradient-to-t from-gray-300 via-gray-300/40 hover:from-gray-200"></div>

                <div className="absolute inset-0 flex justify-center items-center z-10">
                  <img
                    src="https://img.icons8.com/?size=100&id=qLVB1tIe9Ts9&format=png&color=000000"
                    alt="youtubeIcon"
                    className="w-16"
                  />
                </div>
              </div>
              <div>
                <div className="font-semibold text-xl text-[#d1d1d1] dark:text-white sm:mt-4 sm:mb-2">
                House construction process step by step
                </div>
                <div className="text-xs opacity-75 two-lines">
                House construction process step by step on 7 x 14 meter lot
                area. Complete house construction step by step Building
                construction process step by step House construction work
                </div>
              </div>
            </Link>
          </div>
          <div className="w-full sm:w-1/3 pr-5 pb-5">
            <Link
              className="flex w-full items-center rounded-xl hover:!shadow-[#d1d1d1] 
             border border-[#d1d1d1] border-opacity-10 px-4 py-6 text-[#d1d1d1]
              duration-200 hover:border-opacity-0 hover:no-underline 
               flex-col hover:shadow-sm "
              to={"#"}
              onClick={() =>
                document.getElementById("ytVideo_modal").showModal()
              }
            >
              <div className="relative">
                <img
                  className="mr-4 w-full sm:mr-0  "
                  src={
                    "https://img.youtube.com/vi/" + ytId + "/maxresdefault.jpg"
                  }
                  alt="Framework7"
                />
                <div className="absolute inset-0 bg-gradient-to-t from-gray-300 via-gray-300/40 hover:from-gray-200"></div>

                <div className="absolute inset-0 flex justify-center items-center z-10">
                  <img
                    src="https://img.icons8.com/?size=100&id=qLVB1tIe9Ts9&format=png&color=000000"
                    alt="youtubeIcon"
                    className="w-16"
                  />
                </div>
              </div>
              <div>
                <div className="font-semibold text-xl text-[#d1d1d1] dark:text-white sm:mt-4 sm:mb-2">
                House construction process step by step
                </div>
                <div className="text-xs opacity-75 two-lines">
                House construction process step by step on 7 x 14 meter lot
                area. Complete house construction step by step Building
                construction process step by step House construction work
                </div>
              </div>
            </Link>
          </div>
          <div className="w-full sm:w-1/3 pr-5 pb-5">
            <Link
              className="flex w-full items-center rounded-xl hover:!shadow-[#d1d1d1] 
             border border-[#d1d1d1] border-opacity-10 px-4 py-6 text-[#d1d1d1]
              duration-200 hover:border-opacity-0 hover:no-underline 
               flex-col hover:shadow-sm "
              to={"#"}
              onClick={() =>
                document.getElementById("ytVideo_modal").showModal()
              }
            >
              <div className="relative">
                <img
                  className="mr-4 w-full sm:mr-0  "
                  src={
                    "https://img.youtube.com/vi/" + ytId + "/maxresdefault.jpg"
                  }
                  alt="Framework7"
                />
                <div className="absolute inset-0 bg-gradient-to-t from-gray-300 via-gray-300/40 hover:from-gray-200"></div>

                <div className="absolute inset-0 flex justify-center items-center z-10">
                  <img
                    src="https://img.icons8.com/?size=100&id=qLVB1tIe9Ts9&format=png&color=000000"
                    alt="youtubeIcon"
                    className="w-16"
                  />
                </div>
              </div>
              <div>
                <div className="font-semibold text-xl text-[#d1d1d1] dark:text-white sm:mt-4 sm:mb-2">
                House construction process step by step
                </div>
                <div className="text-xs opacity-75 two-lines">
                House construction process step by step on 7 x 14 meter lot
                area. Complete house construction step by step Building
                construction process step by step House construction work
                </div>
              </div>
            </Link>
          </div>
          <div className="w-full sm:w-1/3 pr-5 pb-5">
            <Link
              className="flex w-full items-center rounded-xl hover:!shadow-[#d1d1d1] 
             border border-[#d1d1d1] border-opacity-10 px-4 py-6 text-[#d1d1d1]
              duration-200 hover:border-opacity-0 hover:no-underline 
               flex-col hover:shadow-sm "
              to={"#"}
              onClick={() =>
                document.getElementById("ytVideo_modal").showModal()
              }
            >
              <div className="relative">
                <img
                  className="mr-4 w-full sm:mr-0  "
                  src={
                    "https://img.youtube.com/vi/" + ytId + "/maxresdefault.jpg"
                  }
                  alt="Framework7"
                />
                <div className="absolute inset-0 bg-gradient-to-t from-gray-300 via-gray-300/40 hover:from-gray-200"></div>

                <div className="absolute inset-0 flex justify-center items-center z-10">
                  <img
                    src="https://img.icons8.com/?size=100&id=qLVB1tIe9Ts9&format=png&color=000000"
                    alt="youtubeIcon"
                    className="w-16"
                  />
                </div>
              </div>
              <div>
                <div className="font-semibold text-xl text-[#d1d1d1] dark:text-white sm:mt-4 sm:mb-2">
                House construction process step by step
                </div>
                <div className="text-xs opacity-75 two-lines">
                House construction process step by step on 7 x 14 meter lot
                area. Complete house construction step by step Building
                construction process step by step House construction work
                </div>
              </div>
            </Link>
          </div>
          <div className="w-full sm:w-1/3 pr-5 pb-5">
            <Link
              className="flex w-full items-center rounded-xl hover:!shadow-[#d1d1d1] 
             border border-[#d1d1d1] border-opacity-10 px-4 py-6 text-[#d1d1d1]
              duration-200 hover:border-opacity-0 hover:no-underline 
               flex-col hover:shadow-sm "
              to={"#"}
              onClick={() =>
                document.getElementById("ytVideo_modal").showModal()
              }
            >
              <div className="relative">
                <img
                  className="mr-4 w-full sm:mr-0  "
                  src={
                    "https://img.youtube.com/vi/" + ytId + "/maxresdefault.jpg"
                  }
                  alt="Framework7"
                />
                <div className="absolute inset-0 bg-gradient-to-t from-gray-300 via-gray-300/40 hover:from-gray-200"></div>

                <div className="absolute inset-0 flex justify-center items-center z-10">
                  <img
                    src="https://img.icons8.com/?size=100&id=qLVB1tIe9Ts9&format=png&color=000000"
                    alt="youtubeIcon"
                    className="w-16"
                  />
                </div>
              </div>
              <div>
                <div className="font-semibold text-xl text-[#d1d1d1] dark:text-white sm:mt-4 sm:mb-2">
                House construction process step by step
                </div>
                <div className="text-xs opacity-75 two-lines">
                House construction process step by step on 7 x 14 meter lot
                area. Complete house construction step by step Building
                construction process step by step House construction work
                </div>
              </div>
            </Link>
          </div>
        </div>
      </div>
      {/* modal section  */}
      <YoutubeModal ytId={ytId} />
    </div>
  );
};

export default Tutorial;
