import React, { useEffect, useState } from "react";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

function Feedback() {
  const [slidePerview, setSlidePerview] = useState(3);
  useEffect(() => {
    const checkScreenSize = () => {
      if (window.innerWidth <= 750) {
        setSlidePerview(1);
      } else if (window.innerWidth >= 751) {
        setSlidePerview(3);
      }
    };
    window.addEventListener("resize", checkScreenSize);
    return () => window.removeEventListener("resize", checkScreenSize);
  }, []);
  return (
    <div>
      <div
        id="testimonials"
        aria-label="What our customers are saying"
        className="bg-gray-50 bg-opacity-10 py-14 sm:py-20 Feedback"
      >
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <div
            data-aos="zoom-out"
            data-aos-anchor-placement="center-bottom" data-aos-once="true"
            className="mx-auto max-w-2xl md:text-center mb-16"
          >
            <h2 className="Light_Grey font-display text-3xl tracking-tight sm:text-3xl font-bold ">
              What Our Customers Are Saying
            </h2>
          </div>
          <div data-aos="fade-left" data-aos-easing="linear"
                  data-aos-duration="800" data-aos-once="true">
          <Swiper
            modules={[Navigation, Pagination, Scrollbar, A11y]}
            slidesPerView={slidePerview}
            spaceBetween={30}
            pagination={{ clickable: true }}
            scrollbar={{ draggable: true }}
            onSwiper={(swiper) => console.log(swiper)}
            onSlideChange={() => console.log("slide change")}
            
          >
            <ul className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-6 sm:gap-8 lg:mt-20 lg:max-w-none lg:grid-cols-3">
              <SwiperSlide
              
              >
                <li>
                  <ul className="flex flex-col gap-y-6 sm:gap-y-8">
                    <li>
                      <figure className="relative rounded-2xl bg-white p-6 shadow-xl shadow-slate-900/10">
                        <svg
                          aria-hidden="true"
                          width="105"
                          height="78"
                          className="absolute left-6 top-6 fill-slate-100"
                        >
                          <path d="M25.086 77.292c-4.821 0-9.115-1.205-12.882-3.616-3.767-2.561-6.78-6.102-9.04-10.622C1.054 58.534 0 53.411 0 47.686c0-5.273.904-10.396 2.712-15.368 1.959-4.972 4.746-9.567 8.362-13.786a59.042 59.042 0 0 1 12.43-11.3C28.325 3.917 33.599 1.507 39.324 0l11.074 13.786c-6.479 2.561-11.677 5.951-15.594 10.17-3.767 4.219-5.65 7.835-5.65 10.848 0 1.356.377 2.863 1.13 4.52.904 1.507 2.637 3.089 5.198 4.746 3.767 2.41 6.328 4.972 7.684 7.684 1.507 2.561 2.26 5.5 2.26 8.814 0 5.123-1.959 9.19-5.876 12.204-3.767 3.013-8.588 4.52-14.464 4.52Zm54.24 0c-4.821 0-9.115-1.205-12.882-3.616-3.767-2.561-6.78-6.102-9.04-10.622-2.11-4.52-3.164-9.643-3.164-15.368 0-5.273.904-10.396 2.712-15.368 1.959-4.972 4.746-9.567 8.362-13.786a59.042 59.042 0 0 1 12.43-11.3C82.565 3.917 87.839 1.507 93.564 0l11.074 13.786c-6.479 2.561-11.677 5.951-15.594 10.17-3.767 4.219-5.65 7.835-5.65 10.848 0 1.356.377 2.863 1.13 4.52.904 1.507 2.637 3.089 5.198 4.746 3.767 2.41 6.328 4.972 7.684 7.684 1.507 2.561 2.26 5.5 2.26 8.814 0 5.123-1.959 9.19-5.876 12.204-3.767 3.013-8.588 4.52-14.464 4.52Z"></path>
                        </svg>
                        <blockquote className="relative">
                          <p className="text-lg tracking-tight six-lines  Organic_Black">
                            I had a great experience working with Raqiibul islam
                            from BMC Portal. He arrived promptly for our
                            appointment and quickly diagnosed the issue with our
                            leaky faucet. Raqiibul explained the problem clearly
                            and provided a detailed estimate for the repair. He
                            completed the work efficiently and left the
                            workspace clean and tidy. I appreciated his
                            professionalism and expertise throughout the
                            process. I would hire ABC Plumbing again for any
                            future needs.
                          </p>
                        </blockquote>
                        <figcaption className="relative mt-6 flex items-center justify-between border-t border_Light_Grey pt-6">
                          <div>
                            <div className="font-display text-base text-slate-900">
                              Karim Ahmed
                            </div>
                          </div>
                          <div className="overflow-hidden rounded-full bg-slate-50">
                            <img
                              alt="img"
                              className="h-14 w-14 object-cover bg-transparent"
                              src="https://randomuser.me/api/portraits/men/15.jpg"
                            />
                          </div>
                        </figcaption>
                      </figure>
                    </li>
                  </ul>
                </li>
              </SwiperSlide>
              <SwiperSlide
              
              >
                <li>
                  <ul className="flex flex-col gap-y-6 sm:gap-y-8">
                    <li>
                      <figure className="relative rounded-2xl bg-white p-6 shadow-xl shadow-slate-900/10">
                        <svg
                          aria-hidden="true"
                          width="105"
                          height="78"
                          className="absolute left-6 top-6 fill-slate-100"
                        >
                          <path d="M25.086 77.292c-4.821 0-9.115-1.205-12.882-3.616-3.767-2.561-6.78-6.102-9.04-10.622C1.054 58.534 0 53.411 0 47.686c0-5.273.904-10.396 2.712-15.368 1.959-4.972 4.746-9.567 8.362-13.786a59.042 59.042 0 0 1 12.43-11.3C28.325 3.917 33.599 1.507 39.324 0l11.074 13.786c-6.479 2.561-11.677 5.951-15.594 10.17-3.767 4.219-5.65 7.835-5.65 10.848 0 1.356.377 2.863 1.13 4.52.904 1.507 2.637 3.089 5.198 4.746 3.767 2.41 6.328 4.972 7.684 7.684 1.507 2.561 2.26 5.5 2.26 8.814 0 5.123-1.959 9.19-5.876 12.204-3.767 3.013-8.588 4.52-14.464 4.52Zm54.24 0c-4.821 0-9.115-1.205-12.882-3.616-3.767-2.561-6.78-6.102-9.04-10.622-2.11-4.52-3.164-9.643-3.164-15.368 0-5.273.904-10.396 2.712-15.368 1.959-4.972 4.746-9.567 8.362-13.786a59.042 59.042 0 0 1 12.43-11.3C82.565 3.917 87.839 1.507 93.564 0l11.074 13.786c-6.479 2.561-11.677 5.951-15.594 10.17-3.767 4.219-5.65 7.835-5.65 10.848 0 1.356.377 2.863 1.13 4.52.904 1.507 2.637 3.089 5.198 4.746 3.767 2.41 6.328 4.972 7.684 7.684 1.507 2.561 2.26 5.5 2.26 8.814 0 5.123-1.959 9.19-5.876 12.204-3.767 3.013-8.588 4.52-14.464 4.52Z"></path>
                        </svg>
                        <blockquote className="relative">
                          <p className="text-lg tracking-tight text-slate-900  six-lines Organic_Black">
                            We hired BMC Portal Electrician to upgrade the
                            wiring in our home office. The electrician Razzak
                            was friendly and knowledgeable and completed the job
                            within the estimated timeframe. However, we did
                            encounter a few hiccups along the way. There was
                            some miscommunication about the scope of work, which
                            led to additional costs that weren’t initially
                            discussed. While the final result was satisfactory,
                            I wish there had been clearer upfront communication
                            about the project details and pricing. Overall, it
                            was a decent experience with room for improvement.
                          </p>
                        </blockquote>
                        <figcaption className="relative mt-6 flex items-center justify-between border-t border_Light_Grey pt-6">
                          <div>
                            <div className="font-display text-base text-slate-900">
                              Jannatul Islam
                            </div>
                          </div>
                          <div className="overflow-hidden rounded-full bg-slate-50">
                            <img
                              alt="img"
                              className="h-14 w-14 object-cover bg-transparent"
                              src="https://randomuser.me/api/portraits/women/15.jpg"
                            />
                          </div>
                        </figcaption>
                      </figure>
                    </li>
                  </ul>
                </li>
              </SwiperSlide>
              <SwiperSlide
              
              >
                <li>
                  <ul className="flex flex-col gap-y-6 sm:gap-y-8">
                    <li>
                      <figure className="relative rounded-2xl bg-white p-6 shadow-xl shadow-slate-900/10">
                        <svg
                          aria-hidden="true"
                          width="105"
                          height="78"
                          className="absolute left-6 top-6 fill-slate-100"
                        >
                          <path d="M25.086 77.292c-4.821 0-9.115-1.205-12.882-3.616-3.767-2.561-6.78-6.102-9.04-10.622C1.054 58.534 0 53.411 0 47.686c0-5.273.904-10.396 2.712-15.368 1.959-4.972 4.746-9.567 8.362-13.786a59.042 59.042 0 0 1 12.43-11.3C28.325 3.917 33.599 1.507 39.324 0l11.074 13.786c-6.479 2.561-11.677 5.951-15.594 10.17-3.767 4.219-5.65 7.835-5.65 10.848 0 1.356.377 2.863 1.13 4.52.904 1.507 2.637 3.089 5.198 4.746 3.767 2.41 6.328 4.972 7.684 7.684 1.507 2.561 2.26 5.5 2.26 8.814 0 5.123-1.959 9.19-5.876 12.204-3.767 3.013-8.588 4.52-14.464 4.52Zm54.24 0c-4.821 0-9.115-1.205-12.882-3.616-3.767-2.561-6.78-6.102-9.04-10.622-2.11-4.52-3.164-9.643-3.164-15.368 0-5.273.904-10.396 2.712-15.368 1.959-4.972 4.746-9.567 8.362-13.786a59.042 59.042 0 0 1 12.43-11.3C82.565 3.917 87.839 1.507 93.564 0l11.074 13.786c-6.479 2.561-11.677 5.951-15.594 10.17-3.767 4.219-5.65 7.835-5.65 10.848 0 1.356.377 2.863 1.13 4.52.904 1.507 2.637 3.089 5.198 4.746 3.767 2.41 6.328 4.972 7.684 7.684 1.507 2.561 2.26 5.5 2.26 8.814 0 5.123-1.959 9.19-5.876 12.204-3.767 3.013-8.588 4.52-14.464 4.52Z"></path>
                        </svg>
                        <blockquote className="relative">
                          <p className="text-lg tracking-tight six-lines  Organic_Black">
                            We couldn’t be happier with our kitchen remodel by
                            PQR Contracting! The entire team was a pleasure to
                            work with from BMC Portal Contractor through the
                            final reveal. They listened carefully to our needs
                            and preferences and provided expert guidance
                            throughout the design process. The project manager
                            Kayum Hasnat kept us informed every step of the way
                            and promptly addressed our questions or concerns.
                            The craftsmanship and attention to detail in the
                            finished space are truly impressive. Our new kitchen
                            exceeds our expectations, and we’re grateful to PQR
                            for bringing our vision to life!
                          </p>
                        </blockquote>
                        <figcaption className="relative mt-6 flex items-center justify-between border-t border_Light_Grey pt-6">
                          <div>
                            <div className="font-display text-base text-slate-900">
                              Shoriful Islam
                            </div>
                          </div>
                          <div className="overflow-hidden rounded-full bg-slate-50">
                            <img
                              alt="img"
                              className="h-14 w-14 object-cover bg-transparent"
                              src="https://randomuser.me/api/portraits/men/10.jpg"
                            />
                          </div>
                        </figcaption>
                      </figure>
                    </li>
                  </ul>
                </li>
              </SwiperSlide>
              <SwiperSlide
              
              >
                <li>
                  <ul className="flex flex-col gap-y-6 sm:gap-y-8">
                    <li>
                      <figure className="relative rounded-2xl bg-white p-6 shadow-xl shadow-slate-900/10">
                        <svg
                          aria-hidden="true"
                          width="105"
                          height="78"
                          className="absolute left-6 top-6 fill-slate-100"
                        >
                          <path d="M25.086 77.292c-4.821 0-9.115-1.205-12.882-3.616-3.767-2.561-6.78-6.102-9.04-10.622C1.054 58.534 0 53.411 0 47.686c0-5.273.904-10.396 2.712-15.368 1.959-4.972 4.746-9.567 8.362-13.786a59.042 59.042 0 0 1 12.43-11.3C28.325 3.917 33.599 1.507 39.324 0l11.074 13.786c-6.479 2.561-11.677 5.951-15.594 10.17-3.767 4.219-5.65 7.835-5.65 10.848 0 1.356.377 2.863 1.13 4.52.904 1.507 2.637 3.089 5.198 4.746 3.767 2.41 6.328 4.972 7.684 7.684 1.507 2.561 2.26 5.5 2.26 8.814 0 5.123-1.959 9.19-5.876 12.204-3.767 3.013-8.588 4.52-14.464 4.52Zm54.24 0c-4.821 0-9.115-1.205-12.882-3.616-3.767-2.561-6.78-6.102-9.04-10.622-2.11-4.52-3.164-9.643-3.164-15.368 0-5.273.904-10.396 2.712-15.368 1.959-4.972 4.746-9.567 8.362-13.786a59.042 59.042 0 0 1 12.43-11.3C82.565 3.917 87.839 1.507 93.564 0l11.074 13.786c-6.479 2.561-11.677 5.951-15.594 10.17-3.767 4.219-5.65 7.835-5.65 10.848 0 1.356.377 2.863 1.13 4.52.904 1.507 2.637 3.089 5.198 4.746 3.767 2.41 6.328 4.972 7.684 7.684 1.507 2.561 2.26 5.5 2.26 8.814 0 5.123-1.959 9.19-5.876 12.204-3.767 3.013-8.588 4.52-14.464 4.52Z"></path>
                        </svg>
                        <blockquote className="relative">
                          <p className="text-lg tracking-tight six-lines  Organic_Black">
                            We couldn’t be happier with our kitchen remodel by
                            PQR Contracting! The entire team was a pleasure to
                            work with from BMC Portal Contractor through the
                            final reveal. They listened carefully to our needs
                            and preferences and provided expert guidance
                            throughout the design process. The project manager
                            Kayum Hasnat kept us informed every step of the way
                            and promptly addressed our questions or concerns.
                            The craftsmanship and attention to detail in the
                            finished space are truly impressive. Our new kitchen
                            exceeds our expectations, and we’re grateful to PQR
                            for bringing our vision to life!
                          </p>
                        </blockquote>
                        <figcaption className="relative mt-6 flex items-center justify-between border-t border_Light_Grey pt-6">
                          <div>
                            <div className="font-display text-base text-slate-900">
                              Shoriful Islam
                            </div>
                          </div>
                          <div className="overflow-hidden rounded-full bg-slate-50">
                            <img
                              alt="img"
                              className="h-14 w-14 object-cover bg-transparent"
                              src="https://randomuser.me/api/portraits/men/10.jpg"
                            />
                          </div>
                        </figcaption>
                      </figure>
                    </li>
                  </ul>
                </li>
              </SwiperSlide>
            </ul>
          </Swiper></div>
        </div>
      </div>
    </div>
  );
}

export default Feedback;
