import "./App.css";
import Navbar from "./components/Navbar";
import { Outlet } from "react-router-dom";

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

//components
import Footer from "./components/Footer";


function App() {
  
  return (
    <>
      <div className="overflow-x-hidden">
        <Navbar />
        <Outlet />
        <Footer />
      </div>
    </>
  );
}

export default App;
