import React from "react";
import CountUp from "react-countup";

const CounterSection = () => {
  return (
    <div>
      <div className="bg-[#B80000] mb-14">
        <div className="max-w-screen-xl mx-auto py-10 px-8">
          <div className="grid grid-cols-2 sm:grid-cols-4 gap-6 pb-8 pt-4 mx-auto">
            <div
              title="Live Project"
              className="flex flex-col justify-center items-center gap-2 border-2 border-dashed border-[#D1D1D1] p-4 rounded-md h-32 text-[#D1D1D1]"
            >
              <div className="flex gap-2 items-center">
                <img
                  className="w-10"
                  src="https://img.icons8.com/?size=100&id=44463&format=png&color=D1D1D1"
                  alt="constructionIcon"
                />
                <span className="font-bold text-3xl md:text-4xl">
                  <CountUp
                    start={0}
                    end={21}
                    delay={1}
                    duration={3}
                    enableScrollSpy={true}
                    scrollSpyOnce
                  >
                    {" "}
                    {({ countUpRef }) => <span ref={countUpRef} />}
                  </CountUp>
                </span>
              </div>
              <span className="font-semibold text-2xl text-center">
                Live Project
              </span>
            </div>
            <div
              title="Providers"
              className="flex flex-col justify-center items-center gap-2 border-2 border-dashed border-[#D1D1D1] p-4 rounded-md h-32 text-[#D1D1D1]"
            >
              <div className="flex gap-2 items-center">
                <img
                  className="w-10"
                  src="https://img.icons8.com/?size=100&id=60773&format=png&color=D1D1D1"
                  alt="constructionIcon"
                />
                <span className="font-bold text-3xl md:text-4xl">
                  <CountUp
                    start={0}
                    end={88}
                    delay={1}
                    duration={5}
                    enableScrollSpy={true}
                    scrollSpyOnce
                  >
                    {" "}
                    {({ countUpRef }) => <span ref={countUpRef} />}
                  </CountUp>
                </span>
              </div>
              <span className="font-semibold text-2xl text-center">
                Providers
              </span>
            </div>
            <div
              title="Services"
              className="flex flex-col justify-center items-center gap-2 border-2 border-dashed border-[#D1D1D1] p-4 rounded-md h-32 text-[#D1D1D1]"
            >
              <div className="flex gap-2 items-center">
                <img
                  className="w-10"
                  src="https://img.icons8.com/?size=100&id=30379&format=png&color=D1D1D1"
                  alt="constructionIcon"
                />
                <span className="font-bold text-3xl md:text-4xl">
                  <CountUp
                    start={0}
                    end={197}
                    delay={1}
                    duration={10}
                    enableScrollSpy={true}
                    scrollSpyOnce
                  >
                    {" "}
                    {({ countUpRef }) => <span ref={countUpRef} />}
                  </CountUp>
                </span>
              </div>
              <span className="font-semibold text-2xl text-center">
                Services
              </span>
            </div>
            <div
              title="Total Project"
              className="flex flex-col justify-center items-center gap-2 border-2 border-dashed border-[#D1D1D1] p-4 rounded-md h-32 text-[#D1D1D1]"
            >
              <div className="flex gap-2 items-center">
                <img
                  className="w-10"
                  src="https://img.icons8.com/?size=100&id=11695&format=png&color=D1D1D1"
                  alt="constructionIcon"
                />
                <span className="font-bold text-3xl md:text-4xl">
                  <CountUp
                    start={0}
                    end={128}
                    delay={1}
                    duration={8}
                    enableScrollSpy={true}
                    scrollSpyOnce
                  >
                    {" "}
                    {({ countUpRef }) => <span ref={countUpRef} />}
                  </CountUp>
                </span>
              </div>
              <span className="font-semibold text-2xl text-center">
                Total Project
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CounterSection;
