import React from "react";
import { useEffect } from "react";
import HwLogin from "../components/card/HwLogin";
import PrLogin from "../components/card/PrLogin";
import HwRegister from "../components/card/HwRegister";
import PrRegister from "../components/card/PrRegister";

function Account() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <div className="container mx-auto p-4 pt-36 pb-24  flex flex-col items-center justify-center Light_Grey">
        <div className="w-10/12 sm:w-8/12 md:w-6/12 lg:w-5/12 xl:w-4/12 mb-4">
          <h1
            data-aos="zoom-out"
            data-aos-duration="900"
            data-aos-anchor-placement="top-center"
            data-aos-once="true"
            className="text-3xl font-semibold pb-8"
          >
            Login
          </h1>
        </div>
        <div className="w-10/12 sm:w-8/12 md:w-6/12 lg:w-5/12 xl:w-4/12 ">
          <button
            data-aos="zoom-out"
            data-aos-duration="900"
            data-aos-once="true"
            onClick={() =>
              document.getElementById("homewonerLogin_modal").showModal()
            }
            // className="capitalize w-full bg-gradient-to-r from-blue-600 to-cyan-400 text-white px-4 py-2 text-xl rounded font-medium focus:ring ring-black ring-opacity-10"

            className="w-full Orange_Yellow_bg Organic_Black capitalize px-4 py-2 text-xl rounded font-medium "
          >
            Homewoner login
          </button>
          <div
            data-aos="zoom-in"
            data-aos-duration="1400"
            data-aos-once="true"
            className="flex-row items-center pt-5 pb-8"
          >
            <label htmlFor="remember-me">Don't have an account?</label>
            <button
              onClick={() =>
                document.getElementById("homewonerRegister_modal").showModal()
              }
              className="hover:link px-2 Orange_Yellow font-semibold hover:font-bold "
            >
              Create account
            </button>
          </div>
          <div
            data-aos="zoom-in"
            data-aos-duration="500"
            data-aos-once="true"
            className=" w-full h-px max-w-6xl mx-auto mt-2 mb-10 lineSingle"
          ></div>
          <button
            data-aos="zoom-out"
            data-aos-duration="1500"
            data-aos-once="true"
            onClick={() =>
              document.getElementById("providerLogin_modal").showModal()
            }
            // className="capitalize w-full bg-gradient-to-r from-rose-600 to-red-400 text-white px-4 py-2 text-xl rounded font-medium focus:ring ring-black ring-opacity-10"
            className="w-full Orange_Yellow_bg Organic_Black capitalize px-4 py-2 text-xl rounded font-medium "
          >
            Provider login
          </button>
          
          <div
            data-aos="zoom-in"
            data-aos-duration="1700"
            data-aos-once="true"
            className="flex-row items-center pt-5 pb-8 "
          >
            <label htmlFor="remember-me">Want to join BMCportal?</label>
            <button
              onClick={() =>
                document.getElementById("providerRegister_modal").showModal()
              }
              className="hover:link px-2 Orange_Yellow font-semibold hover:font-bold "
            >
              Sign Up
            </button>
          </div>
        </div>
      </div>

      {/* Modal section  */}
      <HwLogin />
      <PrLogin />
      <HwRegister />
      <PrRegister />
      {/* Modal section  */}
    </div>
  );
}

export default Account;
