import React, { useEffect, useState } from "react";
import { useItemsList } from "../components/hook/useItemsList";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { Pagination, Typography } from "@mui/material";

const ServicesAll = () => {
  let { items } = useItemsList();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [currentPage, setCurrentPage] = useState(1);
  const handleChange = (event, value) => {
    setCurrentPage(value);
    const ele = document.getElementById("serviceAllHead");
    window.scrollTo(ele.offsetLeft, ele.offsetTop);
  };
  const [postsPerPage, setPostsPerPage] = useState(8);
  console.log(setPostsPerPage);

  const lastPostIndex = currentPage * postsPerPage;
  const firstPostIndex = lastPostIndex - postsPerPage;

  const currentPosts = items.slice(firstPostIndex, lastPostIndex);

  return (
    <div className="pt-16 max-w-screen-xl mx-auto ">
      <div className="services-banner-sec">
        <div className="h-[200px] md:h-[300px] ">
          <div className="max-w-screen-xl mx-auto pt-20 md:pt-28 ">
            {/* relative h-[400px] md:h-[570px] bg-gradient-to-b from-amber-600 via-amber-400 to-amber-50 */}
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.5, ease: "easeOut", delay: 0.1 }}
              className="flex flex-col gap-4 md:gap-8 justify-center items-center w-full h-full px-3 md:px-0"
            >
              <motion.h1
                initial={{ opacity: 0, scale: 0.7, y: 0 }}
                animate={{ opacity: 1, scale: 1, y: 0 }}
                transition={{ duration: 1, ease: "easeOut", delay: 0.1 }}
                id="typewriter"
                className="relative text-4xl md:text-5xl lg:text-6xl font-bold text-white text-center"
              >
                All Services
              </motion.h1>
              <motion.p
                initial={{ opacity: 0, x: 50 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 1, ease: "easeOut", delay: 0.1 }}
                className="text-gray-200 relative capitalize"
              >
                Building your future with passion
              </motion.p>
            </motion.div>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-11 gap-4">
        <div className="grid col-span-11" id="serviceAllHead" >
          <div className="navbar " >
            <h1 className="flex-1 text-xl pl-2 sm:pl-10 font-semibold tracking-tight text-[#d1d1d1] sm:text-3xl font-title">
              <Pagination
                count={Math.ceil(items.length / postsPerPage)}
                page={currentPage}
                onChange={handleChange}
                color="primary"
              />
            </h1>
            <div className="flex-none sm:pr-10 pr-2 text-[#d1d1d1]">
              <Typography>Current Page: {currentPage}</Typography>
            </div>
          </div>
        </div>
        <div className="grid col-span-11  grid-cols-3">
          {currentPosts.map((item) => (
            <Link
              to={
                item.id +
                "?name=" +
                item.title.replace(/\s+/g, "_").toLowerCase()
              }
              key={item.id}
            >
              <div
                data-aos-once="true"
                data-aos="fade-right"
                data-aos-duration="700"
                data-aos-easing="linear"
                className="w-full mx-auto p-4"
              >
                <div className="flex gap-3 bg-white border border-gray-300 rounded-xl overflow-hidden items-center justify-start">
                  <div className="relative w-28 h-32 flex-shrink-0 ml-2">
                    <img
                      className="object-contain absolute left-0 top-0 w-full h-full object-center transition duration-50"
                      loading="lazy"
                      src={item.thumbnail}
                      alt={item.title}
                    />
                  </div>

                  <div className="flex flex-col gap-2 py-2">
                    <p className="text-xl font-bold">{item.title}</p>

                    <p className="text-gray-500">{item.description}</p>

                    <span className="flex items-center justify-start text-gray-500">
                      <svg
                        className="w-4 h-4 mr-1 mt-1"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M12.586 4.586a2 2 0 112.828 2.828l-3 3a2 2 0 01-2.828 0 1 1 0 00-1.414 1.414 4 4 0 005.656 0l3-3a4 4 0 00-5.656-5.656l-1.5 1.5a1 1 0 101.414 1.414l1.5-1.5zm-5 5a2 2 0 012.828 0 1 1 0 101.414-1.414 4 4 0 00-5.656 0l-3 3a4 4 0 105.656 5.656l1.5-1.5a1 1 0 10-1.414-1.414l-1.5 1.5a2 2 0 11-2.828-2.828l3-3z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                      {/* <a href="amitpachange.com" target="_blank">
                        amitpachange.com
                      </a> */}
                    </span>
                  </div>
                </div>
              </div>
            </Link>
          ))}
        </div>
      </div>
      <div className="!text-[#d1d1d1] pl-2 py-5 sm:py-10 text-lg">
        <Pagination
          count={Math.ceil(items.length / postsPerPage)}
          page={currentPage}
          onChange={handleChange}
          color="primary"
        />
      </div>
    </div>
  );
};

export default ServicesAll;
