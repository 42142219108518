import React from "react";

function Faq() {
  return (
    <div>
      <div className="py-24 px-8 max-w-5xl mx-auto flex flex-col md:flex-row gap-12 ">
        <div
          data-aos="zoom-out"
          data-aos-duration="1000"
          data-aos-once="true"
          className="flex flex-col text-left basis-1/2"
        >
          <p className="inline-block font-semibold Orange_Yellow mb-4">
            Insurance FAQ
          </p>
          <p className="sm:text-4xl text-3xl font-extrabold text-base-content Light_Grey">
            Frequently Asked Questions
          </p>
        </div>
        <div className="basis-1/2 gap-y-2 grid Organic_Black ">
          <div
            data-aos="fade-up"
            data-aos-duration="500"
            data-aos-once="true"
            className="collapse collapse-plus bg-base-200"
          >
            <input type="radio" name="my-accordion-3" />
            <div className="collapse-title flex-1 gap-2 items-center w-full py-5 text-base font-semibold text-left border-t md:text-lg border-base-content/10">
              How secure is my insurance information?
            </div>
            <div className="collapse-content transition-all duration-300 ease-in-out">
              <div className="pb-5 leading-relaxed">
                <div className="space-y-2 leading-relaxed">
                  We prioritize the security of your insurance information. We
                  use advanced encryption and strict data protection measures to
                  ensure your data is safe and confidential.
                </div>
              </div>
            </div>
          </div>
          <div
            data-aos="fade-up"
            data-aos-duration="700"
            data-aos-once="true"
            className="collapse collapse-plus bg-base-200"
          >
            <input type="radio" name="my-accordion-3" />
            <div className="collapse-title flex-1 gap-2 items-center w-full py-5 text-base font-semibold text-left border-t md:text-lg border-base-content/10">
              How secure is my insurance information?
            </div>
            <div className="collapse-content transition-all duration-300 ease-in-out">
              <div className="pb-5 leading-relaxed">
                <div className="space-y-2 leading-relaxed">
                  We prioritize the security of your insurance information. We
                  use advanced encryption and strict data protection measures to
                  ensure your data is safe and confidential.
                </div>
              </div>
            </div>
          </div>
          <div
            data-aos="fade-up"
            data-aos-duration="900"
            data-aos-once="true"
            className="collapse collapse-plus bg-base-200"
          >
            <input type="radio" name="my-accordion-3" />
            <div className="collapse-title flex-1 gap-2 items-center w-full py-5 text-base font-semibold text-left border-t md:text-lg border-base-content/10">
              How secure is my insurance information?
            </div>
            <div className="collapse-content transition-all duration-300 ease-in-out">
              <div className="pb-5 leading-relaxed">
                <div className="space-y-2 leading-relaxed">
                  We prioritize the security of your insurance information. We
                  use advanced encryption and strict data protection measures to
                  ensure your data is safe and confidential.
                </div>
              </div>
            </div>
          </div>
          <div className=" flex-1 gap-2 items-center w-full py-5 text-left border-t border-base-content/10"></div>
        </div>
      </div>
    </div>
  );
}

export default Faq;
