import React from "react";
import { Link } from "react-router-dom";

const CategoryList = () => {
  const handleList = (e) => {
    e.stopPropagation();

    let submenu = e.target.querySelector("ul");
    let downArrow = e.target.querySelector("img:first-child");
    let rightArrow = e.target.querySelector("img:last-child");
    console.log(submenu);
    if (!submenu) return;

    if (submenu.style.display === "none" || !submenu.style.display) {
      submenu.style.display = "block";
      downArrow.style.display = "block";
      rightArrow.style.display = "none";
      
    } else {
      submenu.style.display = "none";
      downArrow.style.display = "none";
      rightArrow.style.display = "block";
    }
  };

  return (
    <div>
      <dialog id="CategoryList_modal" className="modal ">
        <div className="modal-box">
          <div className="modal-action pr-2 mt-0">
            <form method="dialog">
              <button className="p-1 Organic_Black bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg">
                <svg
                  className="w-5 h-5"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </button>
            </form>
          </div>
          <div className="mt-2 relative z-10">
            <h2 className="flex items-center text-2xl font-semibold">
              Select Service or Provider
            </h2>

            <ul className="border-b pl-4 mt-4 pb-2 w-full text-left font-medium text-lg leading-none border-[#d1d1d1] divide-y divide-[#d1d1d1]">
              <li className=" ">
                <Link
                  to={"#"}
                  className="relative pl-3 py-4 cursor-pointer flex flex-col  hover:font-semibold"
                >
                  <span className="absolute top-3 left-0 w-1 h-7 bg-[#F6AE28] rounded-r-md"></span>
                  All of Services
                </Link>
              </li>
              <li className=" ">
                <Link
                  to={"#"}
                  className="relative pl-3 py-4 cursor-pointer flex flex-col  hover:font-semibold"
                >
                  <span className="absolute top-3 left-0 w-1 h-7 bg-[#F6AE28] rounded-r-md"></span>
                  All of Providers
                </Link>
              </li>
            </ul>
            <div className="pt-9">
              <div className="py-2 divide-0 text-xl"> Services</div>

              <ul className="border-b text-left font-medium text-lg leading-none divide-y divide-gray-300 rounded-md py-2 pl-4">
                <li
                  onClick={handleList}
                  className="relative pl-3 py-4 cursor-pointer flex flex-col  hover:font-semibold"
                >
                  <span className="absolute top-3 left-0 w-1 h-7 bg-[#F6AE28] rounded-r-md"></span>
                  Construction Material
                  <span className="absolute right-5 top-4">
                    
                      <img
                        className="w-5 downArrow"
                        style={{ display: "none" }}
                        src="https://img.icons8.com/?size=100&id=39786&format=png&color=000000"
                        alt="downArrow"
                      />
                    
                      <img
                        className="w-5 rightArrow"
                        src="https://img.icons8.com/?size=100&id=7849&format=png&color=000000"
                        alt="rightArrow"
                      />
                    
                  </span>
                  <ul
                    style={{ display: "none" }}
                    className="border-b text-left text-md font-light leading-none divide-y divide-gray-300 w-full flex-1 rounded-md px-4 py-2 mt-2"
                  >
                    <li className="py-3 hover:font-normal">
                      <div className="flex ">
                        <span className="">Bricks</span>
                      </div>
                    </li>
                    <li className="py-3 hover:font-normal">
                      <div className="flex  ">
                        <span className="">Bathroom Fittings</span>
                      </div>
                    </li>
                    <li className="py-3 hover:font-normal">
                      <div className="flex  ">
                        <span className="">Tiles</span>
                      </div>
                    </li>
                    <li className="py-3 hover:font-normal">
                      <div className="flex  ">
                        <span className="">Sand Mining & Merchants</span>
                      </div>
                    </li>
                  </ul>
                </li>
                <li
                  onClick={handleList}
                  className="relative pl-3 py-4 cursor-pointer flex flex-col  hover:font-semibold"
                >
                  <span className="absolute top-3 left-0 w-1 h-7 bg-[#F6AE28] rounded-r-md"></span>
                  Electrical Equipment
                  <span className="absolute right-5 top-4">
                    
                      <img
                        className="w-5 downArrow"
                        style={{ display: "none" }}
                        src="https://img.icons8.com/?size=100&id=39786&format=png&color=000000"
                        alt="downArrow"
                      />
                    
                      <img
                        className="w-5 rightArrow"
                        src="https://img.icons8.com/?size=100&id=7849&format=png&color=000000"
                        alt="rightArrow"
                      />
                    
                  </span>
                  <ul
                    style={{ display: "none" }}
                    className="border-b text-left text-md font-light leading-none divide-y divide-gray-300 w-full flex-1 rounded-md px-4 py-2 mt-2"
                  >
                    <li className="py-3 hover:font-normal">
                      <div className="flex  ">
                        <span className="">
                          Air Conditioning-Equipment & Parts
                        </span>
                      </div>
                    </li>
                    <li className="py-3 hover:font-normal">
                      <div className="flex  ">
                        <span className="">Electrical Meters</span>
                      </div>
                    </li>
                    <li className="py-3 hover:font-normal">
                      <div className="flex  ">
                        <span className=""> Light & Lamps</span>
                      </div>
                    </li>
                  </ul>
                </li>
                <li
                  onClick={handleList}
                  className="relative pl-3 py-4 cursor-pointer flex flex-col  hover:font-semibold"
                >
                  <span className="absolute top-3 left-0 w-1 h-7 bg-[#F6AE28] rounded-r-md"></span>
                  Furniture & Decoration
                  <span className="absolute right-5 top-4">
                    
                      <img
                        className="w-5 downArrow"
                        style={{ display: "none" }}
                        src="https://img.icons8.com/?size=100&id=39786&format=png&color=000000"
                        alt="downArrow"
                      />
                    
                      <img
                        className="w-5 rightArrow"
                        src="https://img.icons8.com/?size=100&id=7849&format=png&color=000000"
                        alt="rightArrow"
                      />
                    
                  </span>
                  <ul
                    style={{ display: "none" }}
                    className="border-b text-left text-md font-light leading-none divide-y divide-gray-300 w-full flex-1 rounded-md px-4 py-2 mt-2"
                  >
                    <li className="py-3 hover:font-normal">
                      <div className="flex ">
                        <span className="">Bamboo Products</span>
                      </div>
                    </li>
                    <li className="py-3 hover:font-normal">
                      <div className="flex  ">
                        <span className="">Wooden Furniture</span>
                      </div>
                    </li>
                    <li className="py-3 hover:font-normal">
                      <div className="flex  ">
                        <span className="">Plastic Door</span>
                      </div>
                    </li>
                    <li className="py-3 hover:font-normal">
                      <div className="flex  ">
                        <span className="">Steel Furniture</span>
                      </div>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
            <div className="pt-9">
              <div className="py-2 divide-0 text-xl"> Providers</div>

              <ul className="border-b text-left font-medium text-lg leading-none divide-y divide-gray-300 rounded-md py-2 pl-4">
                <li className="relative pl-3 py-4 cursor-pointer flex flex-col  hover:font-semibold"
                >
                  <span className="absolute top-3 left-0 w-1 h-7 bg-[#F6AE28] rounded-r-md"></span>
                  Civil Engineers
                </li>
                <li className="relative pl-3 py-4 cursor-pointer flex flex-col  hover:font-semibold"
                >
                  <span className="absolute top-3 left-0 w-1 h-7 bg-[#F6AE28] rounded-r-md"></span>
                  Electrical Engineers
                </li>
                <li className="relative pl-3 py-4 cursor-pointer flex flex-col  hover:font-semibold"
                >
                  <span className="absolute top-3 left-0 w-1 h-7 bg-[#F6AE28] rounded-r-md"></span>
                  Architects
                </li>
                <li className="relative pl-3 py-4 cursor-pointer flex flex-col  hover:font-semibold"
                >
                  <span className="absolute top-3 left-0 w-1 h-7 bg-[#F6AE28] rounded-r-md"></span>
                  Consultants
                </li>
              </ul>
            </div>
          </div>
        </div>
      </dialog>
    </div>
  );
};

export default CategoryList;
