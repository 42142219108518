import React from "react";

function Features() {
  return (
    <div>
      <section className="py-8 bg-gray-50 bg-opacity-10 max-w-screen-xl mx-auto px-6">
        <div className="container mx-auto text-center px-4">
          <div data-aos="zoom-in" data-aos-once="true" className="mb-4 py-14 ">
            <h2 className="text-3xl font-bold  Light_Grey">Features</h2>
          </div>
          <div className="flex flex-wrap -mx-4">
            <div className="w-full md:w-1/3 px-4 mb-8 ">
              <div
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-delay="300" data-aos-once="true"
              >
                <div className="bg-white p-8 shadow-md rounded-md">
                  <i className="fas fa-lock text-4xl Corporate_Blue mb-4"></i>
                  <h3 className="text-xl font-bold Orange_Yellow mb-2">
                    Secure
                  </h3>
                  <p className="Organic_Black">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  </p>
                </div>
              </div>
            </div>
            <div className="w-full md:w-1/3 px-4 mb-8">
              <div
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-delay="500" data-aos-once="true"
              >
                <div className="bg-white p-8 shadow-md rounded-md">
                  <i className="fas fa-globe-americas text-4xl Corporate_Blue mb-4"></i>
                  <h3 className="text-xl font-bold Orange_Yellow mb-2">
                    Global
                  </h3>
                  <p className="Organic_Black">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  </p>
                </div>
              </div>
            </div>
            <div className="w-full md:w-1/3 px-4 mb-8">
              <div
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-delay="800" data-aos-once="true"
              >
                <div className="bg-white p-8 shadow-md rounded-md">
                  <i className="fas fa-users text-4xl Corporate_Blue mb-4"></i>
                  <h3 className="text-xl font-bold Orange_Yellow mb-2">
                    Collaborative
                  </h3>
                  <p className="Organic_Black">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Features;
