import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

function FeaturedProject() {
  const [slidePerview, setSlidePerview] = useState(3);
  useEffect(() => {
    const checkScreenSize = () => {
      if (window.innerWidth <= 450) {
        setSlidePerview(1);
      } else if (window.innerWidth >= 451) {
        setSlidePerview(3);
      }
    };
    window.addEventListener("resize", checkScreenSize);
    return () => window.removeEventListener("resize", checkScreenSize);
  }, []);
  return (
    <div className="">
      <div className="max-w-7xl mx-auto pb-16 px-8 ">
        <div className="flex justify-center text-3xl md:text-3xl font-bold capitalize py-24 Light_Grey">

        <h2
          data-aos="zoom-out"
          data-aos-easing="linear" data-aos-once="true"
          className=""
        >
          Featured project
        </h2>
        </div>
        <Swiper
          modules={[Navigation, Pagination, Scrollbar, A11y]}
          slidesPerView={slidePerview}
          spaceBetween={30}
          navigation
          scrollbar={{ draggable: true }}
          onSwiper={(swiper) => console.log(swiper)}
          onSlideChange={() => console.log("slide change")}
          data-aos="fade-up-right"
          data-aos-easing="linear"
                  data-aos-duration="800" data-aos-once="true"
        >
          <ul className="grid gap-8 sm:grid-cols-2 lg:grid-cols-3 p-2 xl:p-5">
            <SwiperSlide   >
              <li className="relative bg-white flex flex-col justify-between border rounded shadow-md hover:shadow-amber-400">
                <NavLink className="relative" to={"#"}>
                  <img
                    className="rounded relative w-full object-cover aspect-video"
                    src="https://images.pexels.com/photos/7218525/pexels-photo-7218525.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                    alt="Featured project"
                    loading="lazy"
                  />
                  <div className="flex flex-col justify-beetween gap-3 px-4 pt-2">
                    <div className="flex justify-center items-center text-xl font-semibold Orange_Yellow hover:text-amber-600 two-lines text-ellipsis">
                      <span>Karim's stunning Room wall Painting</span>
                      {/* <small className="font-medium text-sm">
                  - Most advanced language A.I
                </small> */}
                    </div>

                    <p className="Organic_Black two-lines">
                      First-time buyer Karim used BMC Portal to completely
                      colorize wall her two-bedroom, kitchen, drawing room in
                      Mohammadpur, Dhaka. He happy to work with us. You won’t
                      believe the results!
                    </p>
                  </div>
                </NavLink>
                <div className="flex flex-col justify-beetween gap-3 px-4 py-5">
                  <div className="flex flex-wrap items-center justify-end text-sm gap-2">
                    <NavLink
                      to={"#"}
                      className="flex items-center Orange_Yellow border border_Orange_Yellow hover:text-amber-600 hover:border-amber-600 py-2 px-6 gap-2 rounded "
                    >
                      <span>View More</span>
                      <svg
                        className="w-4 h-4 ml-2"
                        fill="none"
                        stroke="currentColor"
                        // stroke-linecap="round"
                        // stroke-linejoin="round"
                        // stroke-width="2"
                        viewBox="0 0 24 24"
                      >
                        <path d="M14 5l7 7m0 0l-7 7m7-7H3"></path>
                      </svg>
                    </NavLink>
                  </div>
                </div>
                {/* <div className="flex flex-col justify-beetween gap-3 px-4 pb-2">
              <ul className="flex flex-wrap text-sm gap-2 my-1">
                <li className="flex items-center gap-2">
                  <span>Content Generation,</span>
                </li>
                <li className="flex items-center gap-2">
                  <span>Marketing,</span>
                </li>
                <li className="flex items-center gap-2">
                  <span>SEO,</span>
                </li>
                <li className="flex items-center gap-2">
                  <span>Writing</span>
                </li>
              </ul>
            </div> */}
              </li>
            </SwiperSlide>
            <SwiperSlide   >
              <li  className="relative bg-white flex flex-col justify-between border rounded shadow-md hover:shadow-amber-400">
                <NavLink className="relative" to={"#"}>
                  <img
                    className="rounded relative w-full object-cover aspect-video"
                    src="https://images.pexels.com/photos/7218525/pexels-photo-7218525.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                    alt="Featured project"
                    loading="lazy"
                  />
                  <div className="flex flex-col justify-beetween gap-3 px-4 pt-2">
                    <div className="flex justify-center items-center text-xl font-semibold Orange_Yellow hover:text-amber-600 two-lines text-ellipsis">
                      <span>Karim's stunning Room wall Painting</span>
                    </div>

                    <p className="Organic_Black two-lines">
                      First-time buyer Karim used BMC Portal to completely
                      colorize wall her two-bedroom, kitchen, drawing room in
                      Mohammadpur, Dhaka. He happy to work with us. You won’t
                      believe the results!
                    </p>
                  </div>
                </NavLink>
                <div className="flex flex-col justify-beetween gap-3 px-4 py-5">
                  <div className="flex flex-wrap items-center justify-end text-sm gap-2">
                    <NavLink
                      to={"#"}
                      className="flex items-center Orange_Yellow border border_Orange_Yellow hover:text-amber-600 hover:border-amber-600 py-2 px-6 gap-2 rounded "
                    >
                      <span>View More</span>
                      <svg
                        className="w-4 h-4 ml-2"
                        fill="none"
                        stroke="currentColor"
                        // stroke-linecap="round"
                        // stroke-linejoin="round"
                        // stroke-width="2"
                        viewBox="0 0 24 24"
                      >
                        <path d="M14 5l7 7m0 0l-7 7m7-7H3"></path>
                      </svg>
                    </NavLink>
                  </div>
                </div>
              </li>
            </SwiperSlide>
            <SwiperSlide   >
              <li className="relative bg-white flex flex-col justify-between border rounded shadow-md hover:shadow-amber-400">
                <NavLink className="relative" to={"#"}>
                  <img
                    className="rounded relative w-full object-cover aspect-video"
                    src="https://images.pexels.com/photos/7218525/pexels-photo-7218525.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                    alt="Featured project"
                    loading="lazy"
                  />
                  <div className="flex flex-col justify-beetween gap-3 px-4 pt-2">
                    <div className="flex justify-center items-center text-xl font-semibold Orange_Yellow hover:text-amber-600 two-lines text-ellipsis">
                      <span>Karim's stunning Room wall Painting</span>
                    </div>

                    <p className="Organic_Black two-lines">
                      First-time buyer Karim used BMC Portal to completely
                      colorize wall her two-bedroom, kitchen, drawing room in
                      Mohammadpur, Dhaka. He happy to work with us. You won’t
                      believe the results!
                    </p>
                  </div>
                </NavLink>
                <div className="flex flex-col justify-beetween gap-3 px-4 py-5">
                  <div className="flex flex-wrap items-center justify-end text-sm gap-2">
                    <NavLink
                      to={"#"}
                      className="flex items-center Orange_Yellow border border_Orange_Yellow hover:text-amber-600 hover:border-amber-600 py-2 px-6 gap-2 rounded "
                    >
                      <span>View More</span>
                      <svg
                        className="w-4 h-4 ml-2"
                        fill="none"
                        stroke="currentColor"
                        // stroke-linecap="round"
                        // stroke-linejoin="round"
                        // stroke-width="2"
                        viewBox="0 0 24 24"
                      >
                        <path d="M14 5l7 7m0 0l-7 7m7-7H3"></path>
                      </svg>
                    </NavLink>
                  </div>
                </div>
              </li>
            </SwiperSlide>
            <SwiperSlide   >
              <li className="relative bg-white flex flex-col justify-between border rounded shadow-md hover:shadow-amber-400">
                <NavLink className="relative" to={"#"}>
                  <img
                    className="rounded relative w-full object-cover aspect-video"
                    src="https://images.pexels.com/photos/7218525/pexels-photo-7218525.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                    alt="Featured project"
                    loading="lazy"
                  />
                  <div className="flex flex-col justify-beetween gap-3 px-4 pt-2">
                    <div className="flex justify-center items-center text-xl font-semibold Orange_Yellow hover:text-amber-600 two-lines text-ellipsis">
                      <span>Karim's stunning Room wall Painting</span>
                    </div>

                    <p className="Organic_Black two-lines">
                      First-time buyer Karim used BMC Portal to completely
                      colorize wall her two-bedroom, kitchen, drawing room in
                      Mohammadpur, Dhaka. He happy to work with us. You won’t
                      believe the results!
                    </p>
                  </div>
                </NavLink>
                <div className="flex flex-col justify-beetween gap-3 px-4 py-5">
                  <div className="flex flex-wrap items-center justify-end text-sm gap-2">
                    <NavLink
                      to={"#"}
                      className="flex items-center Orange_Yellow border border_Orange_Yellow hover:text-amber-600 hover:border-amber-600 py-2 px-6 gap-2 rounded "
                    >
                      <span>View More</span>
                      <svg
                        className="w-4 h-4 ml-2"
                        fill="none"
                        stroke="currentColor"
                        // stroke-linecap="round"
                        // stroke-linejoin="round"
                        // stroke-width="2"
                        viewBox="0 0 24 24"
                      >
                        <path d="M14 5l7 7m0 0l-7 7m7-7H3"></path>
                      </svg>
                    </NavLink>
                  </div>
                </div>
              </li>
            </SwiperSlide>
          </ul>
        </Swiper>
      </div>
    </div>
  );
}

export default FeaturedProject;
