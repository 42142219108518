import React from "react";

function Overview() {
  return (
    <div>
      <div className="max-w-screen-xl mx-auto">
        <div className="py-8 md:py-16 px-7">
          <div className="mx-auto flex flex-col items-center space-y-4 text-center pb-16">
            <h2
              data-aos="fade-up"
              data-aos-easing="linear"
              data-aos-duration="500" data-aos-once="true"
              className="font-bold text-3xl leading-[1.1] sm:text-3xl md:text-3xl capitalize Light_Grey "
            >
              Portal overview
            </h2>

            {/* <p className="max-w-[85%] leading-normal text-muted-foreground text-sm sm:text-md sm:leading-7 capitalize text-gray-400">
              Connecting Homeowners with Trusted Construction Professionals
            </p> */}
            <p
              data-aos="fade-up"
              data-aos-easing="linear"
              data-aos-duration="800" data-aos-once="true"
              className="Light_Grey max-w-[85%] leading-normal text-muted-foreground sm:text-lg sm:leading-7 capitalize "
            >
              BMC Portal is your one-stop shop to find qualified construction
              professionals, manage your project seamlessly, and access valuable
              resources all in one place.
            </p>
          </div>
          <div className="space-y-16">
            <div className="shadow-sm sm:shadow-none shadow-amber-500  py-5 sm:py-0 space-y-8 md:space-y-0 text-center md:text-left md:space-x-16 md:justify-center md:flex md:items-center ">
              <div
                data-aos="fade-right"
                data-aos-easing="linear"
                data-aos-duration="800" data-aos-once="true"
                className="space-y-4"
              >
                <h3 className="Orange_Yellow font-medium text-xl md:text-2xl capitalize">
                  Homeowners Benefits
                </h3>
                <ul className="Light_Grey text-sm leading-6">
                  <li className="ml-4">
                    <p className="group flex items-start py-1 Light_Grey ">
                      <svg
                        width="3"
                        height="24"
                        viewBox="0 -9 3 24"
                        className="mr-2 stroke-2 Orange_Yellow overflow-visible group-hover:text-amber-600 dark:Orange_Yellow dark:group-hover:text-amber-600"
                      >
                        <path
                          d="M0 0L3 3L0 6"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                        ></path>
                      </svg>
                      Breathe easy and find peace of mind throughout your
                      construction project.
                    </p>
                  </li>
                  <li className="ml-4">
                    <p className="group flex items-start py-1 Light_Grey ">
                      <svg
                        width="3"
                        height="24"
                        viewBox="0 -9 3 24"
                        className="mr-2 stroke-2 Orange_Yellow overflow-visible group-hover:text-amber-600 dark:Orange_Yellow dark:group-hover:text-amber-600"
                      >
                        <path
                          d="M0 0L3 3L0 6"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                        ></path>
                      </svg>
                      Get matched with qualified and reliable construction
                      professionals for any renovation, remodel, or new build.
                    </p>
                  </li>
                  <li className="ml-4">
                    <p className="group flex items-start py-1 Light_Grey ">
                      <svg
                        width="3"
                        height="24"
                        viewBox="0 -9 3 24"
                        className="mr-2 stroke-2 Orange_Yellow overflow-visible group-hover:text-amber-600 dark:Orange_Yellow dark:group-hover:text-amber-600"
                      >
                        <path
                          d="M0 0L3 3L0 6"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                        ></path>
                      </svg>
                      Access a wealth of resources to guide you through the
                      process, from budgeting and planning to project
                      completion.
                    </p>
                  </li>
                  <li className="ml-4">
                    <p className="group flex items-start py-1 Light_Grey ">
                      <svg
                        width="3"
                        height="24"
                        viewBox="0 -9 3 24"
                        className="mr-2 stroke-2 Orange_Yellow overflow-visible group-hover:text-amber-600 dark:Orange_Yellow dark:group-hover:text-amber-600"
                      >
                        <path
                          d="M0 0L3 3L0 6"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                        ></path>
                      </svg>
                      Communicate seamlessly with your chosen contractor through
                      secure messaging and document sharing features.
                    </p>
                  </li>
                  <li className="ml-4">
                    <p className="group flex items-start py-1 Light_Grey ">
                      <svg
                        width="3"
                        height="24"
                        viewBox="0 -9 3 24"
                        className="mr-2 stroke-2 Orange_Yellow overflow-visible group-hover:text-amber-600 dark:Orange_Yellow dark:group-hover:text-amber-600"
                      >
                        <path
                          d="M0 0L3 3L0 6"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                        ></path>
                      </svg>
                      Leverage the review and rating system to make informed
                      decisions and find the perfect fit for your project.
                    </p>
                  </li>
                </ul>
              </div>
              <div className="mx-auto w-1/2 md:w-1/3">
                <img
                  data-aos="fade-left"
                  data-aos-easing="linear"
                  data-aos-duration="800" data-aos-once="true"
                  src="https://images.pexels.com/photos/7579138/pexels-photo-7579138.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                  alt=""
                />
              </div>
            </div>
            {/* md:flex-row-reverse */}
            <div className="shadow-sm sm:shadow-none shadow-amber-500 py-5 sm:py-0 space-y-8 md:space-y-0 text-center md:text-left md:space-x-16 md:justify-center md:flex md:items-center ">
              <div className="mx-auto md:mx-0 w-1/2 md:w-1/3 ">
                <img
                  data-aos="fade-right"
                  data-aos-easing="linear"
                  data-aos-duration="800" data-aos-once="true"
                  src="https://images.pexels.com/photos/416405/pexels-photo-416405.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                  alt=""
                  className="w-full"
                />
              </div>
              <div className="space-y-4">
                <div
                  data-aos="fade-left"
                  data-aos-easing="linear"
                  data-aos-duration="500" data-aos-once="true"
                >
                  <h3 className="Orange_Yellow font-medium text-xl md:text-2xl capitalize">
                    Construction Professionals Benefits
                  </h3>
                  <ul className="text-slate-700 text-sm leading-6">
                    <li className="ml-4">
                      <p className="group flex items-start py-1 Light_Grey ">
                        <svg
                          width="3"
                          height="24"
                          viewBox="0 -9 3 24"
                          className="mr-2 stroke-2 Orange_Yellow overflow-visible group-hover:text-amber-600 dark:Orange_Yellow dark:group-hover:text-amber-600"
                        >
                          <path
                            d="M0 0L3 3L0 6"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                          ></path>
                        </svg>
                        Expand your reach and connect with a wider pool of
                        potential clients actively seeking your services.
                      </p>
                    </li>
                    <li className="ml-4">
                      <p className="group flex items-start py-1 Light_Grey ">
                        <svg
                          width="3"
                          height="24"
                          viewBox="0 -9 3 24"
                          className="mr-2 stroke-2 Orange_Yellow overflow-visible group-hover:text-amber-600 dark:Orange_Yellow dark:group-hover:text-amber-600"
                        >
                          <path
                            d="M0 0L3 3L0 6"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                          ></path>
                        </svg>
                        Showcase your expertise and experience through a
                        dedicated profile and project portfolio.
                      </p>
                    </li>
                    <li className="ml-4">
                      <p className="group flex items-start py-1 Light_Grey ">
                        <svg
                          width="3"
                          height="24"
                          viewBox="0 -9 3 24"
                          className="mr-2 stroke-2 Orange_Yellow overflow-visible group-hover:text-amber-600 dark:Orange_Yellow dark:group-hover:text-amber-600"
                        >
                          <path
                            d="M0 0L3 3L0 6"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                          ></path>
                        </svg>
                        Streamline communication with clients using secure
                        messaging tools and project management features
                        (optional).
                      </p>
                    </li>
                    <li className="ml-4">
                      <p className="group flex items-start py-1 Light_Grey ">
                        <svg
                          width="3"
                          height="24"
                          viewBox="0 -9 3 24"
                          className="mr-2 stroke-2 Orange_Yellow overflow-visible group-hover:text-amber-600 dark:Orange_Yellow dark:group-hover:text-amber-600"
                        >
                          <path
                            d="M0 0L3 3L0 6"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                          ></path>
                        </svg>
                        Gain valuable exposure through targeted lead generation
                        opportunities and featured listings.
                      </p>
                    </li>
                    <li className="ml-4">
                      <p className="group flex items-start py-1 Light_Grey ">
                        <svg
                          width="3"
                          height="24"
                          viewBox="0 -9 3 24"
                          className="mr-2 stroke-2 Orange_Yellow overflow-visible group-hover:text-amber-600 dark:Orange_Yellow dark:group-hover:text-amber-600"
                        >
                          <path
                            d="M0 0L3 3L0 6"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                          ></path>
                        </svg>
                        Stay informed about industry trends and best practices
                        through the comprehensive Construction Resource Center.
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Overview;
