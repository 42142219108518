import React from 'react'
import { useEffect } from 'react'
import ConstractionGuide from '../components/card/ConstractionGuide'

function Resources() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div className='pt-16 max-w-screen-xl mx-auto'>
       <ConstractionGuide />
      
    </div>
  )
}

export default Resources
