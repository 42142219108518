import React, { useEffect, useState } from "react";
import { useItemsList } from "../components/hook/useItemsList";
import { Link } from "react-router-dom";
import SearchBar from "../components/SearchBar";
import { Pagination, Typography } from "@mui/material";

function Projects() {
  const [page, setPage] = useState(1);
  const handleChange = (event, value) => {
    setPage(value);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  let { items } = useItemsList();
  // const categories = items.map((item) => item.category);
  // const cats = new Set(categories);
  console.log(items);

  return (
    <div className="pt-16 max-w-screen-xl mx-auto">
      <SearchBar />
      <div className="grid grid-cols-12 mx-8 bg-white bg-opacity-10 my-8">
        <div className="col-span-12 pb-10 ">
          <div className=" relative mx-auto ">
            <div className="navbar ">
              <h1
                data-aos-once="true"
                data-aos="fade-right"
                data-aos-duration="700"
                data-aos-easing="linear"
                className="flex-1 text-xl pl-2 sm:pl-10 pt-10 font-semibold tracking-tight text-[#d1d1d1] sm:text-3xl font-title"
              >
                Service Post
              </h1>
              <p className="flex-none pt-10 sm:pr-10 pr-2 text-[#d1d1d1]">
                <Typography>Page: {page}</Typography>
              </p>
            </div>
            <div className="flex flex-wrap  mx-auto lg:w-full md:w-5/6 xl:shadow-small-blue px-7">
              <div
                data-aos-once="true"
                data-aos="fade-up"
                data-aos-duration="900"
                data-aos-easing="linear"
                className="block w-full text-center p-1 mt-10 lg:w-1/4 "
              >
                <Link to={"#"}>
                  <article
                    className="relative isolate flex flex-col justify-end overflow-hidden rounded-2xl 
                  px-2 py-5 h-56 max-w-sm mx-auto "
                  >
                    <img
                      src="https://images.pexels.com/photos/18274115/pexels-photo-18274115/free-photo-of-close-up-of-a-water-pipe.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                      alt="University of Southern California"
                      className="absolute inset-0 h-full w-full object-cover"
                    />
                    <div className="absolute inset-0 bg-gradient-to-t from-gray-300 via-gray-300/40 hover:from-gray-200"></div>

                    <div className="flex gap-0 ">
                      <h3 className="text-left z-10 text-lg md:text-2xl font-bold text-[#191308] capitalize pl-2">
                        Pipe leaking in kitchen
                      </h3>
                    </div>
                    <div className="flex items-center gap-0 pl-2 py-2">
                      <img
                        src="https://img.icons8.com/?size=100&id=3723&format=png&color=341f66"
                        className="block z-10 w-4 sm:w-5 pt-1 "
                        alt="location icon"
                      />
                      <p className="z-10 text-left text-sm md:text-md  text-[#191308] capitalize pl-1 ">
                        Banasree, Rampura, Dhaka
                      </p>
                    </div>
                    <div className="flex items-center gap-0 pl-2">
                      <div class="h-4 w-4 sm:h-8 sm:w-8 overflow-hidden z-10 rounded-full">
                        <img
                          src="https://img.icons8.com/?size=100&id=u4U9G3tGGHu1&format=png&color=341f66"
                          className="block z-10 w-10 sm:w-20 pt-1"
                          alt="user head icon"
                        />
                      </div>

                      <h3 className="z-10 text-left text-sm md:text-xl font-semibold text-[#191308] capitalize pl-1 ">
                        Abdur Rahim
                      </h3>
                    </div>

                    <div className="z-10 gap-y-1 overflow-hidden text-sm leading-6 "></div>
                  </article>
                </Link>
              </div>
              <div
                data-aos-once="true"
                data-aos="fade-down"
                data-aos-duration="900"
                data-aos-easing="linear"
                className="block w-full text-center p-1 mt-10 lg:w-1/4"
              >
                <Link to={"#"}>
                  <article
                    className="relative isolate flex flex-col justify-end overflow-hidden rounded-2xl 
                  px-2 py-5 h-56 max-w-sm mx-auto "
                  >
                    <img
                      src="https://images.pexels.com/photos/28153616/pexels-photo-28153616/free-photo-of-two-cones.png?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                      alt="University of Southern California"
                      className="absolute inset-0 h-full w-full object-cover"
                    />
                    <div className="absolute inset-0 bg-gradient-to-t from-gray-300 via-gray-300/40 hover:from-gray-200"></div>
                    <div className="flex gap-0 ">
                      <h3 className="text-left z-10 text-lg md:text-2xl font-bold text-[#191308] capitalize pl-2">
                        electrical wiring 1200ft flat
                      </h3>
                    </div>
                    <div className="flex items-center gap-0 pl-2 py-2">
                      <img
                        src="https://img.icons8.com/?size=100&id=3723&format=png&color=341f66"
                        className="block z-10 w-4 sm:w-5 pt-1"
                        alt="location icon"
                      />
                      <p className="z-10 text-left text-base md:text-md  text-[#191308] capitalize pl-1 ">
                        Banasree, Rampura, Dhaka
                      </p>
                    </div>
                    <div className="flex items-center gap-0 pl-2">
                      <div class="h-4 w-4 sm:h-8 sm:w-8 overflow-hidden z-10 rounded-full">
                        <img
                          src="https://img.icons8.com/?size=100&id=u4U9G3tGGHu1&format=png&color=341f66"
                          className="block z-10 w-10 sm:w-20 pt-1"
                          alt="user head icon"
                        />
                      </div>

                      <h3 className="z-10 text-left text-base md:text-xl font-semibold text-[#191308] capitalize pl-1 ">
                        Abdur Rahim
                      </h3>
                    </div>
                    <div className="z-10 gap-y-1 overflow-hidden text-sm leading-6 "></div>
                  </article>
                </Link>
              </div>
              <div
                data-aos-once="true"
                data-aos="fade-up"
                data-aos-duration="900"
                data-aos-easing="linear"
                className="block w-full text-center p-1 mt-10 lg:w-1/4"
              >
                <Link to={"/services/tiles"}>
                  <article
                    className="relative isolate flex flex-col justify-end overflow-hidden rounded-2xl 
                  px-2 py-5 h-56 max-w-sm mx-auto "
                  >
                    {" "}
                    <img
                      src="https://images.pexels.com/photos/4117152/pexels-photo-4117152.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                      alt="University of Southern California"
                      className="absolute inset-0 h-full w-full object-cover"
                    />
                    <div className="absolute inset-0 bg-gradient-to-t from-gray-300 via-gray-300/40 hover:from-gray-200"></div>
                    <div className="flex gap-0 ">
                      <h3 className="text-left z-10 text-lg md:text-2xl font-bold text-[#191308] capitalize pl-2">
                        400ft plastered surface change to tiles
                      </h3>
                    </div>
                    <div className="flex items-center gap-0 pl-2 py-2">
                      <img
                        src="https://img.icons8.com/?size=100&id=3723&format=png&color=341f66"
                        className="block z-10 w-4 sm:w-5 pt-1"
                        alt="location icon"
                      />
                      <p className="z-10 text-left text-base md:text-md  text-[#191308] capitalize pl-1 ">
                        Banasree, Rampura, Dhaka
                      </p>
                    </div>
                    <div className="flex items-center gap-0 pl-2">
                      <div class="h-4 w-4 sm:h-8 sm:w-8 overflow-hidden z-10 rounded-full">
                        <img
                          src="https://img.icons8.com/?size=100&id=u4U9G3tGGHu1&format=png&color=341f66"
                          className="block z-10 w-10 sm:w-20 pt-1"
                          alt="user head icon"
                        />
                      </div>

                      <h3 className="z-10 text-left text-base md:text-xl font-semibold text-[#191308] capitalize pl-1 ">
                        Abdur Rahim
                      </h3>
                    </div>
                    <div className="z-10 gap-y-1 overflow-hidden text-sm leading-6 "></div>
                  </article>
                </Link>
              </div>
              <div
                data-aos-once="true"
                data-aos="fade-down"
                data-aos-duration="900"
                data-aos-easing="linear"
                className="block w-full text-center p-1 mt-10 lg:w-1/4"
              >
                <Link to={"/services/tiles"}>
                  <article
                    className="relative isolate flex flex-col justify-end overflow-hidden rounded-2xl 
                  px-2 py-5 h-56 max-w-sm mx-auto "
                  >
                    {" "}
                    <img
                      src="https://images.pexels.com/photos/4117152/pexels-photo-4117152.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                      alt="University of Southern California"
                      className="absolute inset-0 h-full w-full object-cover"
                    />
                    <div className="absolute inset-0 bg-gradient-to-t from-gray-300 via-gray-300/40 hover:from-gray-200"></div>
                    <div className="flex gap-0 ">
                      <h3 className="text-left z-10 text-lg md:text-2xl font-bold text-[#191308] capitalize pl-2">
                        Tiles repair
                      </h3>
                    </div>
                    <div className="flex items-center gap-0 pl-2 py-2">
                      <img
                        src="https://img.icons8.com/?size=100&id=3723&format=png&color=341f66"
                        className="block z-10 w-4 sm:w-5 pt-1"
                        alt="location icon"
                      />
                      <p className="z-10 text-left text-base md:text-md  text-[#191308] capitalize pl-1 ">
                        Banasree, Rampura, Dhaka
                      </p>
                    </div>
                    <div className="flex items-center gap-0 pl-2">
                      <div class="h-4 w-4 sm:h-8 sm:w-8 overflow-hidden z-10 rounded-full">
                        <img
                          src="https://img.icons8.com/?size=100&id=u4U9G3tGGHu1&format=png&color=341f66"
                          className="block z-10 w-10 sm:w-20 pt-1"
                          alt="user head icon"
                        />
                      </div>

                      <h3 className="z-10 text-left text-base md:text-xl font-semibold text-[#191308] capitalize pl-1 ">
                        Abdur Rahim
                      </h3>
                    </div>
                    <div className="z-10 gap-y-1 overflow-hidden text-sm leading-6 "></div>
                  </article>
                </Link>
              </div>

              <div
                data-aos-once="true"
                data-aos="fade-up"
                data-aos-duration="900"
                data-aos-easing="linear"
                className="block w-full text-center p-1 mt-10 lg:w-1/4 "
              >
                <Link to={"#"}>
                  <article
                    className="relative isolate flex flex-col justify-end overflow-hidden rounded-2xl 
                  px-2 py-5 h-56 max-w-sm mx-auto "
                  >
                    <img
                      src="https://images.pexels.com/photos/18274115/pexels-photo-18274115/free-photo-of-close-up-of-a-water-pipe.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                      alt="University of Southern California"
                      className="absolute inset-0 h-full w-full object-cover"
                    />
                    <div className="absolute inset-0 bg-gradient-to-t from-gray-300 via-gray-300/40 hover:from-gray-200"></div>

                    <div className="flex gap-0 ">
                      <h3 className="text-left z-10 text-lg md:text-2xl font-bold text-[#191308] capitalize pl-2">
                        Pipe leaking in kitchen
                      </h3>
                    </div>
                    <div className="flex items-center gap-0 pl-2 py-2">
                      <img
                        src="https://img.icons8.com/?size=100&id=3723&format=png&color=341f66"
                        className="block z-10 w-4 sm:w-5 pt-1 "
                        alt="location icon"
                      />
                      <p className="z-10 text-left text-sm md:text-md  text-[#191308] capitalize pl-1 ">
                        Banasree, Rampura, Dhaka
                      </p>
                    </div>
                    <div className="flex items-center gap-0 pl-2">
                      <div class="h-4 w-4 sm:h-8 sm:w-8 overflow-hidden z-10 rounded-full">
                        <img
                          src="https://img.icons8.com/?size=100&id=u4U9G3tGGHu1&format=png&color=341f66"
                          className="block z-10 w-10 sm:w-20 pt-1"
                          alt="user head icon"
                        />
                      </div>

                      <h3 className="z-10 text-left text-sm md:text-xl font-semibold text-[#191308] capitalize pl-1 ">
                        Abdur Rahim
                      </h3>
                    </div>

                    <div className="z-10 gap-y-1 overflow-hidden text-sm leading-6 "></div>
                  </article>
                </Link>
              </div>
              <div
                data-aos-once="true"
                data-aos="fade-down"
                data-aos-duration="900"
                data-aos-easing="linear"
                className="block w-full text-center p-1 mt-10 lg:w-1/4"
              >
                <Link to={"#"}>
                  <article
                    className="relative isolate flex flex-col justify-end overflow-hidden rounded-2xl 
                  px-2 py-5 h-56 max-w-sm mx-auto "
                  >
                    <img
                      src="https://images.pexels.com/photos/28153616/pexels-photo-28153616/free-photo-of-two-cones.png?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                      alt="University of Southern California"
                      className="absolute inset-0 h-full w-full object-cover"
                    />
                    <div className="absolute inset-0 bg-gradient-to-t from-gray-300 via-gray-300/40 hover:from-gray-200"></div>
                    <div className="flex gap-0 ">
                      <h3 className="text-left z-10 text-lg md:text-2xl font-bold text-[#191308] capitalize pl-2">
                        electrical wiring 1200ft flat
                      </h3>
                    </div>
                    <div className="flex items-center gap-0 pl-2 py-2">
                      <img
                        src="https://img.icons8.com/?size=100&id=3723&format=png&color=341f66"
                        className="block z-10 w-4 sm:w-5 pt-1"
                        alt="location icon"
                      />
                      <p className="z-10 text-left text-base md:text-md  text-[#191308] capitalize pl-1 ">
                        Banasree, Rampura, Dhaka
                      </p>
                    </div>
                    <div className="flex items-center gap-0 pl-2">
                      <div class="h-4 w-4 sm:h-8 sm:w-8 overflow-hidden z-10 rounded-full">
                        <img
                          src="https://img.icons8.com/?size=100&id=u4U9G3tGGHu1&format=png&color=341f66"
                          className="block z-10 w-10 sm:w-20 pt-1"
                          alt="user head icon"
                        />
                      </div>

                      <h3 className="z-10 text-left text-base md:text-xl font-semibold text-[#191308] capitalize pl-1 ">
                        Abdur Rahim
                      </h3>
                    </div>
                    <div className="z-10 gap-y-1 overflow-hidden text-sm leading-6 "></div>
                  </article>
                </Link>
              </div>
              <div
                data-aos-once="true"
                data-aos="fade-up"
                data-aos-duration="900"
                data-aos-easing="linear"
                className="block w-full text-center p-1 mt-10 lg:w-1/4"
              >
                <Link to={"/services/tiles"}>
                  <article
                    className="relative isolate flex flex-col justify-end overflow-hidden rounded-2xl 
                  px-2 py-5 h-56 max-w-sm mx-auto "
                  >
                    {" "}
                    <img
                      src="https://images.pexels.com/photos/4117152/pexels-photo-4117152.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                      alt="University of Southern California"
                      className="absolute inset-0 h-full w-full object-cover"
                    />
                    <div className="absolute inset-0 bg-gradient-to-t from-gray-300 via-gray-300/40 hover:from-gray-200"></div>
                    <div className="flex gap-0 ">
                      <h3 className="text-left z-10 text-lg md:text-2xl font-bold text-[#191308] capitalize pl-2">
                        400ft plastered surface change to tiles
                      </h3>
                    </div>
                    <div className="flex items-center gap-0 pl-2 py-2">
                      <img
                        src="https://img.icons8.com/?size=100&id=3723&format=png&color=341f66"
                        className="block z-10 w-4 sm:w-5 pt-1"
                        alt="location icon"
                      />
                      <p className="z-10 text-left text-base md:text-md  text-[#191308] capitalize pl-1 ">
                        Banasree, Rampura, Dhaka
                      </p>
                    </div>
                    <div className="flex items-center gap-0 pl-2">
                      <div class="h-4 w-4 sm:h-8 sm:w-8 overflow-hidden z-10 rounded-full">
                        <img
                          src="https://img.icons8.com/?size=100&id=u4U9G3tGGHu1&format=png&color=341f66"
                          className="block z-10 w-10 sm:w-20 pt-1"
                          alt="user head icon"
                        />
                      </div>

                      <h3 className="z-10 text-left text-base md:text-xl font-semibold text-[#191308] capitalize pl-1 ">
                        Abdur Rahim
                      </h3>
                    </div>
                    <div className="z-10 gap-y-1 overflow-hidden text-sm leading-6 "></div>
                  </article>
                </Link>
              </div>
              <div
                data-aos-once="true"
                data-aos="fade-down"
                data-aos-duration="900"
                data-aos-easing="linear"
                className="block w-full text-center p-1 mt-10 lg:w-1/4"
              >
                <Link to={"/services/tiles"}>
                  <article
                    className="relative isolate flex flex-col justify-end overflow-hidden rounded-2xl 
                  px-2 py-5 h-56 max-w-sm mx-auto "
                  >
                    {" "}
                    <img
                      src="https://images.pexels.com/photos/4117152/pexels-photo-4117152.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                      alt="University of Southern California"
                      className="absolute inset-0 h-full w-full object-cover"
                    />
                    <div className="absolute inset-0 bg-gradient-to-t from-gray-300 via-gray-300/40 hover:from-gray-200"></div>
                    <div className="flex gap-0 ">
                      <h3 className="text-left z-10 text-lg md:text-2xl font-bold text-[#191308] capitalize pl-2">
                        Tiles repair
                      </h3>
                    </div>
                    <div className="flex items-center gap-0 pl-2 py-2">
                      <img
                        src="https://img.icons8.com/?size=100&id=3723&format=png&color=341f66"
                        className="block z-10 w-4 sm:w-5 pt-1"
                        alt="location icon"
                      />
                      <p className="z-10 text-left text-base md:text-md  text-[#191308] capitalize pl-1 ">
                        Banasree, Rampura, Dhaka
                      </p>
                    </div>
                    <div className="flex items-center gap-0 pl-2">
                      <div class="h-4 w-4 sm:h-8 sm:w-8 overflow-hidden z-10 rounded-full">
                        <img
                          src="https://img.icons8.com/?size=100&id=u4U9G3tGGHu1&format=png&color=341f66"
                          className="block z-10 w-10 sm:w-20 pt-1"
                          alt="user head icon"
                        />
                      </div>

                      <h3 className="z-10 text-left text-base md:text-xl font-semibold text-[#191308] capitalize pl-1 ">
                        Abdur Rahim
                      </h3>
                    </div>
                    <div className="z-10 gap-y-1 overflow-hidden text-sm leading-6 "></div>
                  </article>
                </Link>
              </div>

              <div
                data-aos-once="true"
                data-aos="fade-up"
                data-aos-duration="900"
                data-aos-easing="linear"
                className="block w-full text-center p-1 mt-10 lg:w-1/4 "
              >
                <Link to={"#"}>
                  <article
                    className="relative isolate flex flex-col justify-end overflow-hidden rounded-2xl 
                  px-2 py-5 h-56 max-w-sm mx-auto "
                  >
                    <img
                      src="https://images.pexels.com/photos/18274115/pexels-photo-18274115/free-photo-of-close-up-of-a-water-pipe.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                      alt="University of Southern California"
                      className="absolute inset-0 h-full w-full object-cover"
                    />
                    <div className="absolute inset-0 bg-gradient-to-t from-gray-300 via-gray-300/40 hover:from-gray-200"></div>

                    <div className="flex gap-0 ">
                      <h3 className="text-left z-10 text-lg md:text-2xl font-bold text-[#191308] capitalize pl-2">
                        Pipe leaking in kitchen
                      </h3>
                    </div>
                    <div className="flex items-center gap-0 pl-2 py-2">
                      <img
                        src="https://img.icons8.com/?size=100&id=3723&format=png&color=341f66"
                        className="block z-10 w-4 sm:w-5 pt-1 "
                        alt="location icon"
                      />
                      <p className="z-10 text-left text-sm md:text-md  text-[#191308] capitalize pl-1 ">
                        Banasree, Rampura, Dhaka
                      </p>
                    </div>
                    <div className="flex items-center gap-0 pl-2">
                      <div class="h-4 w-4 sm:h-8 sm:w-8 overflow-hidden z-10 rounded-full">
                        <img
                          src="https://img.icons8.com/?size=100&id=u4U9G3tGGHu1&format=png&color=341f66"
                          className="block z-10 w-10 sm:w-20 pt-1"
                          alt="user head icon"
                        />
                      </div>

                      <h3 className="z-10 text-left text-sm md:text-xl font-semibold text-[#191308] capitalize pl-1 ">
                        Abdur Rahim
                      </h3>
                    </div>

                    <div className="z-10 gap-y-1 overflow-hidden text-sm leading-6 "></div>
                  </article>
                </Link>
              </div>
              <div
                data-aos-once="true"
                data-aos="fade-down"
                data-aos-duration="900"
                data-aos-easing="linear"
                className="block w-full text-center p-1 mt-10 lg:w-1/4"
              >
                <Link to={"#"}>
                  <article
                    className="relative isolate flex flex-col justify-end overflow-hidden rounded-2xl 
                  px-2 py-5 h-56 max-w-sm mx-auto "
                  >
                    <img
                      src="https://images.pexels.com/photos/28153616/pexels-photo-28153616/free-photo-of-two-cones.png?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                      alt="University of Southern California"
                      className="absolute inset-0 h-full w-full object-cover"
                    />
                    <div className="absolute inset-0 bg-gradient-to-t from-gray-300 via-gray-300/40 hover:from-gray-200"></div>
                    <div className="flex gap-0 ">
                      <h3 className="text-left z-10 text-lg md:text-2xl font-bold text-[#191308] capitalize pl-2">
                        electrical wiring 1200ft flat
                      </h3>
                    </div>
                    <div className="flex items-center gap-0 pl-2 py-2">
                      <img
                        src="https://img.icons8.com/?size=100&id=3723&format=png&color=341f66"
                        className="block z-10 w-4 sm:w-5 pt-1"
                        alt="location icon"
                      />
                      <p className="z-10 text-left text-base md:text-md  text-[#191308] capitalize pl-1 ">
                        Banasree, Rampura, Dhaka
                      </p>
                    </div>
                    <div className="flex items-center gap-0 pl-2">
                      <div class="h-4 w-4 sm:h-8 sm:w-8 overflow-hidden z-10 rounded-full">
                        <img
                          src="https://img.icons8.com/?size=100&id=u4U9G3tGGHu1&format=png&color=341f66"
                          className="block z-10 w-10 sm:w-20 pt-1"
                          alt="user head icon"
                        />
                      </div>

                      <h3 className="z-10 text-left text-base md:text-xl font-semibold text-[#191308] capitalize pl-1 ">
                        Abdur Rahim
                      </h3>
                    </div>
                    <div className="z-10 gap-y-1 overflow-hidden text-sm leading-6 "></div>
                  </article>
                </Link>
              </div>
              <div
                data-aos-once="true"
                data-aos="fade-up"
                data-aos-duration="900"
                data-aos-easing="linear"
                className="block w-full text-center p-1 mt-10 lg:w-1/4"
              >
                <Link to={"/services/tiles"}>
                  <article
                    className="relative isolate flex flex-col justify-end overflow-hidden rounded-2xl 
                  px-2 py-5 h-56 max-w-sm mx-auto "
                  >
                    {" "}
                    <img
                      src="https://images.pexels.com/photos/4117152/pexels-photo-4117152.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                      alt="University of Southern California"
                      className="absolute inset-0 h-full w-full object-cover"
                    />
                    <div className="absolute inset-0 bg-gradient-to-t from-gray-300 via-gray-300/40 hover:from-gray-200"></div>
                    <div className="flex gap-0 ">
                      <h3 className="text-left z-10 text-lg md:text-2xl font-bold text-[#191308] capitalize pl-2">
                        400ft plastered surface change to tiles
                      </h3>
                    </div>
                    <div className="flex items-center gap-0 pl-2 py-2">
                      <img
                        src="https://img.icons8.com/?size=100&id=3723&format=png&color=341f66"
                        className="block z-10 w-4 sm:w-5 pt-1"
                        alt="location icon"
                      />
                      <p className="z-10 text-left text-base md:text-md  text-[#191308] capitalize pl-1 ">
                        Banasree, Rampura, Dhaka
                      </p>
                    </div>
                    <div className="flex items-center gap-0 pl-2">
                      <div class="h-4 w-4 sm:h-8 sm:w-8 overflow-hidden z-10 rounded-full">
                        <img
                          src="https://img.icons8.com/?size=100&id=u4U9G3tGGHu1&format=png&color=341f66"
                          className="block z-10 w-10 sm:w-20 pt-1"
                          alt="user head icon"
                        />
                      </div>

                      <h3 className="z-10 text-left text-base md:text-xl font-semibold text-[#191308] capitalize pl-1 ">
                        Abdur Rahim
                      </h3>
                    </div>
                    <div className="z-10 gap-y-1 overflow-hidden text-sm leading-6 "></div>
                  </article>
                </Link>
              </div>
              <div
                data-aos-once="true"
                data-aos="fade-down"
                data-aos-duration="900"
                data-aos-easing="linear"
                className="block w-full text-center p-1 mt-10 lg:w-1/4"
              >
                <Link to={"/services/tiles"}>
                  <article
                    className="relative isolate flex flex-col justify-end overflow-hidden rounded-2xl 
                  px-2 py-5 h-56 max-w-sm mx-auto "
                  >
                    {" "}
                    <img
                      src="https://images.pexels.com/photos/4117152/pexels-photo-4117152.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                      alt="University of Southern California"
                      className="absolute inset-0 h-full w-full object-cover"
                    />
                    <div className="absolute inset-0 bg-gradient-to-t from-gray-300 via-gray-300/40 hover:from-gray-200"></div>
                    <div className="flex gap-0 ">
                      <h3 className="text-left z-10 text-lg md:text-2xl font-bold text-[#191308] capitalize pl-2">
                        Tiles repair
                      </h3>
                    </div>
                    <div className="flex items-center gap-0 pl-2 py-2">
                      <img
                        src="https://img.icons8.com/?size=100&id=3723&format=png&color=341f66"
                        className="block z-10 w-4 sm:w-5 pt-1"
                        alt="location icon"
                      />
                      <p className="z-10 text-left text-base md:text-md  text-[#191308] capitalize pl-1 ">
                        Banasree, Rampura, Dhaka
                      </p>
                    </div>
                    <div className="flex items-center gap-0 pl-2">
                      <div class="h-4 w-4 sm:h-8 sm:w-8 overflow-hidden z-10 rounded-full">
                        <img
                          src="https://img.icons8.com/?size=100&id=u4U9G3tGGHu1&format=png&color=341f66"
                          className="block z-10 w-10 sm:w-20 pt-1"
                          alt="user head icon"
                        />
                      </div>

                      <h3 className="z-10 text-left text-base md:text-xl font-semibold text-[#191308] capitalize pl-1 ">
                        Abdur Rahim
                      </h3>
                    </div>
                    <div className="z-10 gap-y-1 overflow-hidden text-sm leading-6 "></div>
                  </article>
                </Link>
              </div>

              <div
                data-aos-once="true"
                data-aos="fade-up"
                data-aos-duration="900"
                data-aos-easing="linear"
                className="block w-full text-center p-1 mt-10 lg:w-1/4 "
              >
                <Link to={"#"}>
                  <article
                    className="relative isolate flex flex-col justify-end overflow-hidden rounded-2xl 
                  px-2 py-5 h-56 max-w-sm mx-auto "
                  >
                    <img
                      src="https://images.pexels.com/photos/18274115/pexels-photo-18274115/free-photo-of-close-up-of-a-water-pipe.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                      alt="University of Southern California"
                      className="absolute inset-0 h-full w-full object-cover"
                    />
                    <div className="absolute inset-0 bg-gradient-to-t from-gray-300 via-gray-300/40 hover:from-gray-200"></div>

                    <div className="flex gap-0 ">
                      <h3 className="text-left z-10 text-lg md:text-2xl font-bold text-[#191308] capitalize pl-2">
                        Pipe leaking in kitchen
                      </h3>
                    </div>
                    <div className="flex items-center gap-0 pl-2 py-2">
                      <img
                        src="https://img.icons8.com/?size=100&id=3723&format=png&color=341f66"
                        className="block z-10 w-4 sm:w-5 pt-1 "
                        alt="location icon"
                      />
                      <p className="z-10 text-left text-sm md:text-md  text-[#191308] capitalize pl-1 ">
                        Banasree, Rampura, Dhaka
                      </p>
                    </div>
                    <div className="flex items-center gap-0 pl-2">
                      <div class="h-4 w-4 sm:h-8 sm:w-8 overflow-hidden z-10 rounded-full">
                        <img
                          src="https://img.icons8.com/?size=100&id=u4U9G3tGGHu1&format=png&color=341f66"
                          className="block z-10 w-10 sm:w-20 pt-1"
                          alt="user head icon"
                        />
                      </div>

                      <h3 className="z-10 text-left text-sm md:text-xl font-semibold text-[#191308] capitalize pl-1 ">
                        Abdur Rahim
                      </h3>
                    </div>

                    <div className="z-10 gap-y-1 overflow-hidden text-sm leading-6 "></div>
                  </article>
                </Link>
              </div>
              <div
                data-aos-once="true"
                data-aos="fade-down"
                data-aos-duration="900"
                data-aos-easing="linear"
                className="block w-full text-center p-1 mt-10 lg:w-1/4"
              >
                <Link to={"#"}>
                  <article
                    className="relative isolate flex flex-col justify-end overflow-hidden rounded-2xl 
                  px-2 py-5 h-56 max-w-sm mx-auto "
                  >
                    <img
                      src="https://images.pexels.com/photos/28153616/pexels-photo-28153616/free-photo-of-two-cones.png?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                      alt="University of Southern California"
                      className="absolute inset-0 h-full w-full object-cover"
                    />
                    <div className="absolute inset-0 bg-gradient-to-t from-gray-300 via-gray-300/40 hover:from-gray-200"></div>
                    <div className="flex gap-0 ">
                      <h3 className="text-left z-10 text-lg md:text-2xl font-bold text-[#191308] capitalize pl-2">
                        electrical wiring 1200ft flat
                      </h3>
                    </div>
                    <div className="flex items-center gap-0 pl-2 py-2">
                      <img
                        src="https://img.icons8.com/?size=100&id=3723&format=png&color=341f66"
                        className="block z-10 w-4 sm:w-5 pt-1"
                        alt="location icon"
                      />
                      <p className="z-10 text-left text-base md:text-md  text-[#191308] capitalize pl-1 ">
                        Banasree, Rampura, Dhaka
                      </p>
                    </div>
                    <div className="flex items-center gap-0 pl-2">
                      <div class="h-4 w-4 sm:h-8 sm:w-8 overflow-hidden z-10 rounded-full">
                        <img
                          src="https://img.icons8.com/?size=100&id=u4U9G3tGGHu1&format=png&color=341f66"
                          className="block z-10 w-10 sm:w-20 pt-1"
                          alt="user head icon"
                        />
                      </div>

                      <h3 className="z-10 text-left text-base md:text-xl font-semibold text-[#191308] capitalize pl-1 ">
                        Abdur Rahim
                      </h3>
                    </div>
                    <div className="z-10 gap-y-1 overflow-hidden text-sm leading-6 "></div>
                  </article>
                </Link>
              </div>
              <div
                data-aos-once="true"
                data-aos="fade-up"
                data-aos-duration="900"
                data-aos-easing="linear"
                className="block w-full text-center p-1 mt-10 lg:w-1/4"
              >
                <Link to={"/services/tiles"}>
                  <article
                    className="relative isolate flex flex-col justify-end overflow-hidden rounded-2xl 
                  px-2 py-5 h-56 max-w-sm mx-auto "
                  >
                    {" "}
                    <img
                      src="https://images.pexels.com/photos/4117152/pexels-photo-4117152.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                      alt="University of Southern California"
                      className="absolute inset-0 h-full w-full object-cover"
                    />
                    <div className="absolute inset-0 bg-gradient-to-t from-gray-300 via-gray-300/40 hover:from-gray-200"></div>
                    <div className="flex gap-0 ">
                      <h3 className="text-left z-10 text-lg md:text-2xl font-bold text-[#191308] capitalize pl-2">
                        400ft plastered surface change to tiles
                      </h3>
                    </div>
                    <div className="flex items-center gap-0 pl-2 py-2">
                      <img
                        src="https://img.icons8.com/?size=100&id=3723&format=png&color=341f66"
                        className="block z-10 w-4 sm:w-5 pt-1"
                        alt="location icon"
                      />
                      <p className="z-10 text-left text-base md:text-md  text-[#191308] capitalize pl-1 ">
                        Banasree, Rampura, Dhaka
                      </p>
                    </div>
                    <div className="flex items-center gap-0 pl-2">
                      <div class="h-4 w-4 sm:h-8 sm:w-8 overflow-hidden z-10 rounded-full">
                        <img
                          src="https://img.icons8.com/?size=100&id=u4U9G3tGGHu1&format=png&color=341f66"
                          className="block z-10 w-10 sm:w-20 pt-1"
                          alt="user head icon"
                        />
                      </div>

                      <h3 className="z-10 text-left text-base md:text-xl font-semibold text-[#191308] capitalize pl-1 ">
                        Abdur Rahim
                      </h3>
                    </div>
                    <div className="z-10 gap-y-1 overflow-hidden text-sm leading-6 "></div>
                  </article>
                </Link>
              </div>
              <div
                data-aos-once="true"
                data-aos="fade-down"
                data-aos-duration="900"
                data-aos-easing="linear"
                className="block w-full text-center p-1 mt-10 lg:w-1/4"
              >
                <Link to={"/services/tiles"}>
                  <article
                    className="relative isolate flex flex-col justify-end overflow-hidden rounded-2xl 
                  px-2 py-5 h-56 max-w-sm mx-auto "
                  >
                    {" "}
                    <img
                      src="https://images.pexels.com/photos/4117152/pexels-photo-4117152.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                      alt="University of Southern California"
                      className="absolute inset-0 h-full w-full object-cover"
                    />
                    <div className="absolute inset-0 bg-gradient-to-t from-gray-300 via-gray-300/40 hover:from-gray-200"></div>
                    <div className="flex gap-0 ">
                      <h3 className="text-left z-10 text-lg md:text-2xl font-bold text-[#191308] capitalize pl-2">
                        Tiles repair
                      </h3>
                    </div>
                    <div className="flex items-center gap-0 pl-2 py-2">
                      <img
                        src="https://img.icons8.com/?size=100&id=3723&format=png&color=341f66"
                        className="block z-10 w-4 sm:w-5 pt-1"
                        alt="location icon"
                      />
                      <p className="z-10 text-left text-base md:text-md  text-[#191308] capitalize pl-1 ">
                        Banasree, Rampura, Dhaka
                      </p>
                    </div>
                    <div className="flex items-center gap-0 pl-2">
                      <div class="h-4 w-4 sm:h-8 sm:w-8 overflow-hidden z-10 rounded-full">
                        <img
                          src="https://img.icons8.com/?size=100&id=u4U9G3tGGHu1&format=png&color=341f66"
                          className="block z-10 w-10 sm:w-20 pt-1"
                          alt="user head icon"
                        />
                      </div>

                      <h3 className="z-10 text-left text-base md:text-xl font-semibold text-[#191308] capitalize pl-1 ">
                        Abdur Rahim
                      </h3>
                    </div>
                    <div className="z-10 gap-y-1 overflow-hidden text-sm leading-6 "></div>
                  </article>
                </Link>
              </div>

              <div
                data-aos-once="true"
                data-aos="fade-up"
                data-aos-duration="900"
                data-aos-easing="linear"
                className="block w-full text-center p-1 mt-10 lg:w-1/4 "
              >
                <Link to={"#"}>
                  <article
                    className="relative isolate flex flex-col justify-end overflow-hidden rounded-2xl 
                  px-2 py-5 h-56 max-w-sm mx-auto "
                  >
                    <img
                      src="https://images.pexels.com/photos/18274115/pexels-photo-18274115/free-photo-of-close-up-of-a-water-pipe.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                      alt="University of Southern California"
                      className="absolute inset-0 h-full w-full object-cover"
                    />
                    <div className="absolute inset-0 bg-gradient-to-t from-gray-300 via-gray-300/40 hover:from-gray-200"></div>

                    <div className="flex gap-0 ">
                      <h3 className="text-left z-10 text-lg md:text-2xl font-bold text-[#191308] capitalize pl-2">
                        Pipe leaking in kitchen
                      </h3>
                    </div>
                    <div className="flex items-center gap-0 pl-2 py-2">
                      <img
                        src="https://img.icons8.com/?size=100&id=3723&format=png&color=341f66"
                        className="block z-10 w-4 sm:w-5 pt-1 "
                        alt="location icon"
                      />
                      <p className="z-10 text-left text-sm md:text-md  text-[#191308] capitalize pl-1 ">
                        Banasree, Rampura, Dhaka
                      </p>
                    </div>
                    <div className="flex items-center gap-0 pl-2">
                      <div class="h-4 w-4 sm:h-8 sm:w-8 overflow-hidden z-10 rounded-full">
                        <img
                          src="https://img.icons8.com/?size=100&id=u4U9G3tGGHu1&format=png&color=341f66"
                          className="block z-10 w-10 sm:w-20 pt-1"
                          alt="user head icon"
                        />
                      </div>

                      <h3 className="z-10 text-left text-sm md:text-xl font-semibold text-[#191308] capitalize pl-1 ">
                        Abdur Rahim
                      </h3>
                    </div>

                    <div className="z-10 gap-y-1 overflow-hidden text-sm leading-6 "></div>
                  </article>
                </Link>
              </div>
              <div
                data-aos-once="true"
                data-aos="fade-down"
                data-aos-duration="900"
                data-aos-easing="linear"
                className="block w-full text-center p-1 mt-10 lg:w-1/4"
              >
                <Link to={"#"}>
                  <article
                    className="relative isolate flex flex-col justify-end overflow-hidden rounded-2xl 
                  px-2 py-5 h-56 max-w-sm mx-auto "
                  >
                    <img
                      src="https://images.pexels.com/photos/28153616/pexels-photo-28153616/free-photo-of-two-cones.png?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                      alt="University of Southern California"
                      className="absolute inset-0 h-full w-full object-cover"
                    />
                    <div className="absolute inset-0 bg-gradient-to-t from-gray-300 via-gray-300/40 hover:from-gray-200"></div>
                    <div className="flex gap-0 ">
                      <h3 className="text-left z-10 text-lg md:text-2xl font-bold text-[#191308] capitalize pl-2">
                        electrical wiring 1200ft flat
                      </h3>
                    </div>
                    <div className="flex items-center gap-0 pl-2 py-2">
                      <img
                        src="https://img.icons8.com/?size=100&id=3723&format=png&color=341f66"
                        className="block z-10 w-4 sm:w-5 pt-1"
                        alt="location icon"
                      />
                      <p className="z-10 text-left text-base md:text-md  text-[#191308] capitalize pl-1 ">
                        Banasree, Rampura, Dhaka
                      </p>
                    </div>
                    <div className="flex items-center gap-0 pl-2">
                      <div class="h-4 w-4 sm:h-8 sm:w-8 overflow-hidden z-10 rounded-full">
                        <img
                          src="https://img.icons8.com/?size=100&id=u4U9G3tGGHu1&format=png&color=341f66"
                          className="block z-10 w-10 sm:w-20 pt-1"
                          alt="user head icon"
                        />
                      </div>

                      <h3 className="z-10 text-left text-base md:text-xl font-semibold text-[#191308] capitalize pl-1 ">
                        Abdur Rahim
                      </h3>
                    </div>
                    <div className="z-10 gap-y-1 overflow-hidden text-sm leading-6 "></div>
                  </article>
                </Link>
              </div>
              <div
                data-aos-once="true"
                data-aos="fade-up"
                data-aos-duration="900"
                data-aos-easing="linear"
                className="block w-full text-center p-1 mt-10 lg:w-1/4"
              >
                <Link to={"/services/tiles"}>
                  <article
                    className="relative isolate flex flex-col justify-end overflow-hidden rounded-2xl 
                  px-2 py-5 h-56 max-w-sm mx-auto "
                  >
                    {" "}
                    <img
                      src="https://images.pexels.com/photos/4117152/pexels-photo-4117152.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                      alt="University of Southern California"
                      className="absolute inset-0 h-full w-full object-cover"
                    />
                    <div className="absolute inset-0 bg-gradient-to-t from-gray-300 via-gray-300/40 hover:from-gray-200"></div>
                    <div className="flex gap-0 ">
                      <h3 className="text-left z-10 text-lg md:text-2xl font-bold text-[#191308] capitalize pl-2">
                        400ft plastered surface change to tiles
                      </h3>
                    </div>
                    <div className="flex items-center gap-0 pl-2 py-2">
                      <img
                        src="https://img.icons8.com/?size=100&id=3723&format=png&color=341f66"
                        className="block z-10 w-4 sm:w-5 pt-1"
                        alt="location icon"
                      />
                      <p className="z-10 text-left text-base md:text-md  text-[#191308] capitalize pl-1 ">
                        Banasree, Rampura, Dhaka
                      </p>
                    </div>
                    <div className="flex items-center gap-0 pl-2">
                      <div class="h-4 w-4 sm:h-8 sm:w-8 overflow-hidden z-10 rounded-full">
                        <img
                          src="https://img.icons8.com/?size=100&id=u4U9G3tGGHu1&format=png&color=341f66"
                          className="block z-10 w-10 sm:w-20 pt-1"
                          alt="user head icon"
                        />
                      </div>

                      <h3 className="z-10 text-left text-base md:text-xl font-semibold text-[#191308] capitalize pl-1 ">
                        Abdur Rahim
                      </h3>
                    </div>
                    <div className="z-10 gap-y-1 overflow-hidden text-sm leading-6 "></div>
                  </article>
                </Link>
              </div>
              <div
                data-aos-once="true"
                data-aos="fade-down"
                data-aos-duration="900"
                data-aos-easing="linear"
                className="block w-full text-center p-1 mt-10 lg:w-1/4"
              >
                <Link to={"/services/tiles"}>
                  <article
                    className="relative isolate flex flex-col justify-end overflow-hidden rounded-2xl 
                  px-2 py-5 h-56 max-w-sm mx-auto "
                  >
                    {" "}
                    <img
                      src="https://images.pexels.com/photos/4117152/pexels-photo-4117152.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                      alt="University of Southern California"
                      className="absolute inset-0 h-full w-full object-cover"
                    />
                    <div className="absolute inset-0 bg-gradient-to-t from-gray-300 via-gray-300/40 hover:from-gray-200"></div>
                    <div className="flex gap-0 ">
                      <h3 className="text-left z-10 text-lg md:text-2xl font-bold text-[#191308] capitalize pl-2">
                        Tiles repair
                      </h3>
                    </div>
                    <div className="flex items-center gap-0 pl-2 py-2">
                      <img
                        src="https://img.icons8.com/?size=100&id=3723&format=png&color=341f66"
                        className="block z-10 w-4 sm:w-5 pt-1"
                        alt="location icon"
                      />
                      <p className="z-10 text-left text-base md:text-md  text-[#191308] capitalize pl-1 ">
                        Banasree, Rampura, Dhaka
                      </p>
                    </div>
                    <div className="flex items-center gap-0 pl-2">
                      <div class="h-4 w-4 sm:h-8 sm:w-8 overflow-hidden z-10 rounded-full">
                        <img
                          src="https://img.icons8.com/?size=100&id=u4U9G3tGGHu1&format=png&color=341f66"
                          className="block z-10 w-10 sm:w-20 pt-1"
                          alt="user head icon"
                        />
                      </div>

                      <h3 className="z-10 text-left text-base md:text-xl font-semibold text-[#191308] capitalize pl-1 ">
                        Abdur Rahim
                      </h3>
                    </div>
                    <div className="z-10 gap-y-1 overflow-hidden text-sm leading-6 "></div>
                  </article>
                </Link>
              </div>
              <div
                data-aos-once="true"
                data-aos="fade-down"
                data-aos-duration="900"
                data-aos-easing="linear"
                className="block w-full text-center p-1 mt-10 lg:w-1/4"
              >
                <Link to={"#"}>
                  <article
                    className="relative isolate flex flex-col justify-end overflow-hidden rounded-2xl 
                  px-2 py-5 h-56 max-w-sm mx-auto "
                  >
                    <img
                      src="https://images.pexels.com/photos/28153616/pexels-photo-28153616/free-photo-of-two-cones.png?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                      alt="University of Southern California"
                      className="absolute inset-0 h-full w-full object-cover"
                    />
                    <div className="absolute inset-0 bg-gradient-to-t from-gray-300 via-gray-300/40 hover:from-gray-200"></div>
                    <div className="flex gap-0 ">
                      <h3 className="text-left z-10 text-lg md:text-2xl font-bold text-[#191308] capitalize pl-2">
                        electrical wiring 1200ft flat
                      </h3>
                    </div>
                    <div className="flex items-center gap-0 pl-2 py-2">
                      <img
                        src="https://img.icons8.com/?size=100&id=3723&format=png&color=341f66"
                        className="block z-10 w-4 sm:w-5 pt-1"
                        alt="location icon"
                      />
                      <p className="z-10 text-left text-base md:text-md  text-[#191308] capitalize pl-1 ">
                        Banasree, Rampura, Dhaka
                      </p>
                    </div>
                    <div className="flex items-center gap-0 pl-2">
                      <div class="h-4 w-4 sm:h-8 sm:w-8 overflow-hidden z-10 rounded-full">
                        <img
                          src="https://img.icons8.com/?size=100&id=u4U9G3tGGHu1&format=png&color=341f66"
                          className="block z-10 w-10 sm:w-20 pt-1"
                          alt="user head icon"
                        />
                      </div>

                      <h3 className="z-10 text-left text-base md:text-xl font-semibold text-[#191308] capitalize pl-1 ">
                        Abdur Rahim
                      </h3>
                    </div>
                    <div className="z-10 gap-y-1 overflow-hidden text-sm leading-6 "></div>
                  </article>
                </Link>
              </div>
              <div
                data-aos-once="true"
                data-aos="fade-down"
                data-aos-duration="900"
                data-aos-easing="linear"
                className="block w-full text-center p-1 mt-10 lg:w-1/4"
              >
                <Link to={"/services/tiles"}>
                  <article
                    className="relative isolate flex flex-col justify-end overflow-hidden rounded-2xl 
                  px-2 py-5 h-56 max-w-sm mx-auto "
                  >
                    {" "}
                    <img
                      src="https://images.pexels.com/photos/4117152/pexels-photo-4117152.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                      alt="University of Southern California"
                      className="absolute inset-0 h-full w-full object-cover"
                    />
                    <div className="absolute inset-0 bg-gradient-to-t from-gray-300 via-gray-300/40 hover:from-gray-200"></div>
                    <div className="flex gap-0 ">
                      <h3 className="text-left z-10 text-lg md:text-2xl font-bold text-[#191308] capitalize pl-2">
                        Tiles repair
                      </h3>
                    </div>
                    <div className="flex items-center gap-0 pl-2 py-2">
                      <img
                        src="https://img.icons8.com/?size=100&id=3723&format=png&color=341f66"
                        className="block z-10 w-4 sm:w-5 pt-1"
                        alt="location icon"
                      />
                      <p className="z-10 text-left text-base md:text-md  text-[#191308] capitalize pl-1 ">
                        Banasree, Rampura, Dhaka
                      </p>
                    </div>
                    <div className="flex items-center gap-0 pl-2">
                      <div class="h-4 w-4 sm:h-8 sm:w-8 overflow-hidden z-10 rounded-full">
                        <img
                          src="https://img.icons8.com/?size=100&id=u4U9G3tGGHu1&format=png&color=341f66"
                          className="block z-10 w-10 sm:w-20 pt-1"
                          alt="user head icon"
                        />
                      </div>

                      <h3 className="z-10 text-left text-base md:text-xl font-semibold text-[#191308] capitalize pl-1 ">
                        Abdur Rahim
                      </h3>
                    </div>
                    <div className="z-10 gap-y-1 overflow-hidden text-sm leading-6 "></div>
                  </article>
                </Link>
              </div>

              <div
                data-aos-once="true"
                data-aos="fade-down"
                data-aos-duration="900"
                data-aos-easing="linear"
                className="block w-full text-center p-1 mt-10 lg:w-1/4"
              >
                <Link to={"#"}>
                  <article
                    className="relative isolate flex flex-col justify-end overflow-hidden rounded-2xl 
                  px-2 py-5 h-56 max-w-sm mx-auto "
                  >
                    <img
                      src="https://images.pexels.com/photos/28153616/pexels-photo-28153616/free-photo-of-two-cones.png?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                      alt="University of Southern California"
                      className="absolute inset-0 h-full w-full object-cover"
                    />
                    <div className="absolute inset-0 bg-gradient-to-t from-gray-300 via-gray-300/40 hover:from-gray-200"></div>
                    <div className="flex gap-0 ">
                      <h3 className="text-left z-10 text-lg md:text-2xl font-bold text-[#191308] capitalize pl-2">
                        electrical wiring 1200ft flat
                      </h3>
                    </div>
                    <div className="flex items-center gap-0 pl-2 py-2">
                      <img
                        src="https://img.icons8.com/?size=100&id=3723&format=png&color=341f66"
                        className="block z-10 w-4 sm:w-5 pt-1"
                        alt="location icon"
                      />
                      <p className="z-10 text-left text-base md:text-md  text-[#191308] capitalize pl-1 ">
                        Banasree, Rampura, Dhaka
                      </p>
                    </div>
                    <div className="flex items-center gap-0 pl-2">
                      <div class="h-4 w-4 sm:h-8 sm:w-8 overflow-hidden z-10 rounded-full">
                        <img
                          src="https://img.icons8.com/?size=100&id=u4U9G3tGGHu1&format=png&color=341f66"
                          className="block z-10 w-10 sm:w-20 pt-1"
                          alt="user head icon"
                        />
                      </div>

                      <h3 className="z-10 text-left text-base md:text-xl font-semibold text-[#191308] capitalize pl-1 ">
                        Abdur Rahim
                      </h3>
                    </div>
                    <div className="z-10 gap-y-1 overflow-hidden text-sm leading-6 "></div>
                  </article>
                </Link>
              </div>

              <div className="!text-[#d1d1d1] pl-2 pt-5 sm:pt-10 text-lg block w-full ">
                <Pagination
                  count={10}
                  page={page}
                  onChange={handleChange}
                  color="primary"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Projects;
