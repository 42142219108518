import React, { useEffect } from "react";
import PopularService from "../components/PopularService";
import Header from "../components/Header";
import Features from "../components/Features";
import Overview from "../components/Overview";
import Feedback from "../components/Feedback";
import FeaturedProject from "../components/FeaturedProject";
import CounterSection from "../components/CounterSection";

function Home() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="pt-16">
      <Header />
      <PopularService />
      <Overview />
      <Features />
      <FeaturedProject />
      <CounterSection />
      <Feedback />
    </div>
  );
}

export default Home;
