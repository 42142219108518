import React from "react";
import { motion } from "framer-motion";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectCoverflow, Pagination } from "swiper/modules";
import sundTiles from "../../assets/img/service/sundaram tiles logo-03.png";
import { useEffect } from "react";
import ChatBox from "../card/ChatBox";
import { Link } from "react-router-dom";

const TilesDetails = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
    
  return (
    <div className="pt-16 Corporate_Blue_bg">
      <div className="services-banner-sec">
        <div className="h-[200px] md:h-[300px] ">
          <div className="max-w-screen-xl mx-auto pt-20 md:pt-28">
            {/* relative h-[400px] md:h-[570px] bg-gradient-to-b from-amber-600 via-amber-400 to-amber-50 */}
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.5, ease: "easeOut", delay: 0.1 }}
              className="flex flex-col gap-4 md:gap-8 justify-center items-center w-full h-full px-3 md:px-0"
            >
              <motion.h1
                initial={{ opacity: 0, scale: 0.7, y: 0 }}
                animate={{ opacity: 1, scale: 1, y: 0 }}
                transition={{ duration: 1, ease: "easeOut", delay: 0.1 }}
                id="typewriter"
                className="capitalize relative text-4xl md:text-5xl lg:text-6xl font-bold text-white text-center"
              >
                Tiles Details
              </motion.h1>
              <motion.p
                initial={{ opacity: 0, x: 50 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 1, ease: "easeOut", delay: 0.1 }}
                className="text-gray-200 relative capitalize"
              >
                Building your future with passion
              </motion.p>
            </motion.div>
          </div>
        </div>
      </div>
      <div className="">
        <div className="mx-auto px-4 py-8">
          <div className="flex flex-wrap -mx-4">
          <div className="w-full md:w-1/12 hidden md:block px-4 mb-8"></div>
            <div className="w-full md:w-5/12 px-4 mb-8 details-img bg-white bg-opacity-5">
              <Swiper
                effect={"coverflow"}
                grabCursor={true}
                centeredSlides={true}
                slidesPerView={"auto"}
                coverflowEffect={{
                  rotate: 50,
                  stretch: 0,
                  depth: 100,
                  modifier: 1,
                  slideShadows: true,
                }}
                pagination={true}
                modules={[EffectCoverflow, Pagination]}
                className="mySwiper "
              >
                <SwiperSlide>
                  <img
                    src={sundTiles}
                    alt="Product"
                    className="w-full h-auto rounded-lg shadow-md mb-4"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    src="https://images.pexels.com/photos/1088165/pexels-photo-1088165.jpeg"
                    alt="Product"
                    className="w-full h-auto rounded-lg shadow-md mb-4"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    src="https://images.pexels.com/photos/9221288/pexels-photo-9221288.jpeg"
                    alt="Product"
                    className="w-full h-auto rounded-lg shadow-md mb-4"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    src="https://images.pexels.com/photos/3148596/pexels-photo-3148596.jpeg"
                    alt="Product"
                    className="w-full h-auto rounded-lg shadow-md mb-4"
                  />
                </SwiperSlide>
              </Swiper>
            </div>

            <div className="w-full md:w-5/12 px-4">
              <h2 className="text-3xl font-bold mb-2 Orange_Yellow">Sundaram Tiles</h2>
              <div className="flex items-center mb-4"></div>
              <p className="Light_Grey mb-6 pr-9">Delve into the world of luxury with our Mirror Polish 60x60 floor tiles. Crafted for those who revel in grandeur and an elevated lifestyle.</p>
              <div className="flex flex-wrap items-start  pb-5">
                <Link
                  className=" relative px-3 py-1 m-2 rounded-md shadow-sm sm:py-2 sm:text-base ring ring-transparent group md:px-4 hover:ring hover:ring-opacity-50 focus:ring-opacity-50 hover:ring-[#F6AE28] text-[#191308] bg-[#D1D1D1] dark:bg-gray-400 dark:text-gray-200 "
                  to={"#"}
                >
                  <span className="text-sm">Tiles Fittings & Pasting</span>
                </Link>
                <Link
                  className=" relative px-3 py-1 m-2 rounded-md shadow-sm sm:py-2 sm:text-base ring ring-transparent group md:px-4 hover:ring hover:ring-opacity-50 focus:ring-opacity-50 hover:ring-[#F6AE28] text-[#191308] bg-[#D1D1D1] dark:bg-gray-400 dark:text-gray-200 "
                  to={"#"}
                >
                  <span className="text-sm">Broken tiles fix</span>
                </Link>
                <Link
                  className=" relative px-3 py-1 m-2 rounded-md shadow-sm sm:py-2 sm:text-base ring ring-transparent group md:px-4 hover:ring hover:ring-opacity-50 focus:ring-opacity-50 hover:ring-[#F6AE28] text-[#191308] bg-[#D1D1D1] dark:bg-gray-400 dark:text-gray-200 "
                  to={"#"}
                >
                  <span className="text-sm">Wholesale</span>
                </Link>
              </div>
              <button
                onClick={() =>
                  document.getElementById("chatBox_modal").showModal()
                }
                className="bg-[#B80000] rounded-full w-48 h-16 text-white font-semibold"
              >
                <div className="flex gap-3 justify-center items-center">
                  <span>
                    <img
                      className="w-10"
                      src="https://img.icons8.com/?size=100&id=7859&format=png&color=F6AE28"
                      alt="chatIcon"
                    />
                  </span>
                  <span className="text-2xl capitalize">Text us</span>
                </div>
              </button>
            </div>
            <div className="w-full md:w-1/12 hidden md:block px-4 mb-8"></div>
          </div>
        </div>
        <div className="mx-auto px-4 py-8">
          <div className="flex flex-wrap -mx-4">
            <div className="w-full md:w-2/12 px-4 mb-8"></div>
            <div className="w-full md:w-10/12 px-4 mb-8 ">
              <div className="">
                <div className="md:grid md:grid-cols-2 md:gap-x-2 md:gap-y-8">
                  <div className="flex">
                    <div className="flex-shrink-0">
                      <div className="flex items-center justify-center h-12 w-12 rounded-md bg-amber-600 text-white">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          className="w-6 h-6"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M12 21a9.004 9.004 0 008.716-6.747M12 21a9.004 9.004 0 01-8.716-6.747M12 21c2.485 0 4.5-4.03 4.5-9S14.485 3 12 3m0 18c-2.485 0-4.5-4.03-4.5-9S9.515 3 12 3m0 0a8.997 8.997 0 017.843 4.582M12 3a8.997 8.997 0 00-7.843 4.582m15.686 0A11.953 11.953 0 0112 10.5c-2.998 0-5.74-1.1-7.843-2.918m15.686 0A8.959 8.959 0 0121 12c0 .778-.099 1.533-.284 2.253m0 0A17.919 17.919 0 0112 16.5c-3.162 0-6.133-.815-8.716-2.247m0 0A9.015 9.015 0 013 12c0-1.605.42-3.113 1.157-4.418"
                          />
                        </svg>
                      </div>
                    </div>
                    <div className="ml-4">
                      <dt className="text-lg leading-6 Light_Grey font-semibold">
                        Address
                      </dt>
                      <dd className="mt-2 text-base Light_Grey">
                      338 Sonargaon Road,  <br></br>
                      Hatirpool, Dhaka- 1205
                      </dd>
                    </div>
                  </div>

                  <div className="flex">
                    <div className="flex-shrink-0">
                      <div className="flex items-center justify-center h-12 w-12 rounded-md bg-amber-600 text-white">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          className="w-6 h-6"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z"
                          />
                        </svg>
                      </div>
                    </div>
                    <div className="ml-4">
                      <dt className="text-lg leading-6 font-semibold Light_Grey">
                        Phone number
                      </dt>
                      <dd className="mt-2 text-base Light_Grey">
                      +880-2-9668653
                      </dd>
                    </div>
                  </div>

                  <div className="flex">
                    <div className="flex-shrink-0">
                      <div className="flex items-center justify-center h-12 w-12 rounded-md bg-amber-600 text-white">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          className="w-6 h-6"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75"
                          />
                        </svg>
                      </div>
                    </div>
                    <div className="ml-4">
                      <dt className="text-lg leading-6 font-semibold Light_Grey">
                        Email
                      </dt>
                      <dd className="mt-2 text-base Light_Grey">
                        info@sundaramtile.com
                      </dd>
                    </div>
                  </div>

                  <div className="flex">
                    <div className="flex-shrink-0">
                      <div className="flex items-center justify-center h-12 w-12 rounded-md bg-amber-600 text-white">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          className="w-6 h-6"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z"
                          />
                        </svg>
                      </div>
                    </div>
                    <div className="ml-4">
                      <dt className="text-lg leading-6 font-semibold Light_Grey">
                        Store Hours
                      </dt>
                      <div className="mt-2 text-base Light_Grey">
                        Monday - Friday: 9am to 8pm<br></br>
                        Saturday: 10am to 6pm<br></br>
                        Sunday: 12pm to 4pm
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* chat box modal  */}
      <ChatBox name={"Sundaram Tiles"} />
      {/* chat box modal  */}
    </div>
  )
}

export default TilesDetails
