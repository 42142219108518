import React from "react";
import { Form } from "react-router-dom";
import PrivacyPolicy from "./PrivacyPolicy";

const PrRegister = () => {
  
  return (
    <div>
      <dialog id="providerRegister_modal" className="modal">
        <div className="modal-box max-w-screen-md">
          <div className="modal-action pr-2 mt-0">
            <form method="dialog">
              <button className="p-1 Organic_Black bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg">
                <svg
                  className="w-5 h-5"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </button>
            </form>
          </div>
          <div className="flex flex-col text-center pt-4">
            <h3 className="text-xl font-semibold Organic_Black lg:text-3xl">
              Provider Registration form
            </h3>
            <p className="max-w-xl text-sm Organic_Black opacity-75 m-auto pt-2">
              Showcase your expertise and connect with potential clients seeking
              your construction services. Please complete this registration form
              to create your provider profile.
            </p>
          </div>
          <div className="mt-12"></div>
          <Form>
            <div className="mb-5">
              <label
                htmlFor="prType"
                className="mb-3 block text-base font-semibold Organic_Black "
              >
                Provider Type
              </label>
              <select
                required
                name="prType"
                id="prType"
                className="w-full rounded-md border border-[#D1D1D1] bg-white py-3 px-6 text-base placeholder:text-[#D1D1D1] font-medium text-[#191308] outline-none focus:border-[#F6AE28] focus:shadow-md"
              >
                <option className="font-semibold text-slate-300">
                  Select Type
                </option>
                <option
                  value={"Contractor"}
                  className="font-semibold text-slate-300"
                >
                  Contractor
                </option>
                <option
                  value={"Architect"}
                  className="font-semibold text-slate-300"
                >
                  Architect
                </option>
                <option
                  value={"Engineer"}
                  className="font-semibold text-slate-300"
                >
                  Engineer
                </option>
                <option
                  value={"Mason"}
                  className="font-semibold text-slate-300"
                >
                  Mason
                </option>
                <option
                  value={"Supplier"}
                  className="font-semibold text-slate-300"
                >
                  Supplier
                </option>
              </select>
            </div>
            <div className="mb-5">
              <label
                htmlFor="name"
                className="mb-3 block text-base font-semibold Organic_Black "
              >
                Full Name
              </label>
              <input
                required
                type="text"
                name="name"
                id="name"
                placeholder="Full Name"
                className="w-full rounded-md border border-[#D1D1D1] bg-white py-3 px-6 text-base placeholder:text-[#D1D1D1] font-medium text-[#191308] outline-none focus:border-[#F6AE28] focus:shadow-md"
              />
            </div>
            <div className="mb-5">
              <label
                htmlFor="license"
                className="mb-3 block text-base font-semibold Organic_Black"
              >
                License Number
              </label>
              <input
                required
                type="text"
                name="license"
                id="license"
                placeholder="Enter your license number"
                className="w-full rounded-md border border-[#D1D1D1] bg-white py-3 px-6 text-base placeholder:text-[#D1D1D1] font-medium text-[#191308] outline-none focus:border-[#F6AE28] focus:shadow-md"
              />
            </div>
            <div className="mb-5">
              <label
                htmlFor="experience"
                className="mb-3 block text-base font-semibold Organic_Black "
              >
                Operational Experience
              </label>
              <select
                required
                name="experience"
                id="experience"
                className="w-full rounded-md border border-[#D1D1D1] bg-white py-3 px-6 text-base placeholder:text-[#D1D1D1] font-medium text-[#191308] outline-none focus:border-[#F6AE28] focus:shadow-md"
              >
                <option value={"<1"} className="font-semibold text-slate-300">
                  Less than one years
                </option>
                <option value={"<2"} className="font-semibold text-slate-300">
                  Less than two years
                </option>
                <option value={">2"} className="font-semibold text-slate-300">
                  More than two years
                </option>
                <option value={">5"} className="font-semibold text-slate-300">
                  More than five years
                </option>
              </select>
            </div>

            <div className="mb-5">
              <label className="mb-3 block text-base font-semibold Organic_Black ">
                Area of Service
              </label>
              <div className="grid grid-cols-3 sm:grid-cols-5 gap-4 sm:gap-8 items-center">
                <div className="flex gap-3">
                  <input
                    required
                    type="checkbox"
                    id="plumber"
                    name="languageCheckbox"
                    className="h-4 w-4 rounded border-gray-300 text-teal-600 shadow-sm focus:border-teal-300 focus:ring focus:ring-teal-200 focus:ring-opacity-50 focus:ring-offset-0 disabled:cursor-not-allowed disabled:text-gray-400"
                  />
                  <label htmlFor="plumber" className="space-x-2 text-sm">
                    Plumber
                  </label>
                </div>
                <div className="flex gap-3">
                  <input
                    required
                    type="checkbox"
                    id="plumber"
                    name="languageCheckbox"
                    className="h-4 w-4 rounded border-gray-300 text-teal-600 shadow-sm focus:border-teal-300 focus:ring focus:ring-teal-200 focus:ring-opacity-50 focus:ring-offset-0 disabled:cursor-not-allowed disabled:text-gray-400"
                  />
                  <label htmlFor="plumber" className="space-x-2 text-sm">
                    Plumber
                  </label>
                </div>
                <div className="flex gap-3">
                  <input
                    required
                    type="checkbox"
                    id="plumber"
                    name="languageCheckbox"
                    className="h-4 w-4 rounded border-gray-300 text-teal-600 shadow-sm focus:border-teal-300 focus:ring focus:ring-teal-200 focus:ring-opacity-50 focus:ring-offset-0 disabled:cursor-not-allowed disabled:text-gray-400"
                  />
                  <label htmlFor="plumber" className="space-x-2 text-sm">
                    Plumber
                  </label>
                </div>
                <div className="flex gap-3">
                  <input
                    required
                    type="checkbox"
                    id="plumber"
                    name="languageCheckbox"
                    className="h-4 w-4 rounded border-gray-300 text-teal-600 shadow-sm focus:border-teal-300 focus:ring focus:ring-teal-200 focus:ring-opacity-50 focus:ring-offset-0 disabled:cursor-not-allowed disabled:text-gray-400"
                  />
                  <label htmlFor="plumber" className="space-x-2 text-sm">
                    Plumber
                  </label>
                </div>
                <div className="flex gap-3">
                  <input
                    required
                    type="checkbox"
                    id="plumber"
                    name="languageCheckbox"
                    className="h-4 w-4 rounded border-gray-300 text-teal-600 shadow-sm focus:border-teal-300 focus:ring focus:ring-teal-200 focus:ring-opacity-50 focus:ring-offset-0 disabled:cursor-not-allowed disabled:text-gray-400"
                  />
                  <label htmlFor="plumber" className="space-x-2 text-sm">
                    Plumber
                  </label>
                </div>
                <div className="flex gap-3">
                  <input
                    required
                    type="checkbox"
                    id="plumber"
                    name="languageCheckbox"
                    className="h-4 w-4 rounded border-gray-300 text-teal-600 shadow-sm focus:border-teal-300 focus:ring focus:ring-teal-200 focus:ring-opacity-50 focus:ring-offset-0 disabled:cursor-not-allowed disabled:text-gray-400"
                  />
                  <label htmlFor="plumber" className="space-x-2 text-sm">
                    Plumber
                  </label>
                </div>
                <div className="flex gap-3">
                  <input
                    required
                    type="checkbox"
                    id="plumber"
                    name="languageCheckbox"
                    className="h-4 w-4 rounded border-gray-300 text-teal-600 shadow-sm focus:border-teal-300 focus:ring focus:ring-teal-200 focus:ring-opacity-50 focus:ring-offset-0 disabled:cursor-not-allowed disabled:text-gray-400"
                  />
                  <label htmlFor="plumber" className="space-x-2 text-sm">
                    Plumber
                  </label>
                </div>
                <div className="flex gap-3">
                  <input
                    required
                    type="checkbox"
                    id="plumber"
                    name="languageCheckbox"
                    className="h-4 w-4 rounded border-gray-300 text-teal-600 shadow-sm focus:border-teal-300 focus:ring focus:ring-teal-200 focus:ring-opacity-50 focus:ring-offset-0 disabled:cursor-not-allowed disabled:text-gray-400"
                  />
                  <label htmlFor="plumber" className="space-x-2 text-sm">
                    Plumber
                  </label>
                </div>
                <div className="flex gap-3">
                  <input
                    required
                    type="checkbox"
                    id="plumber"
                    name="languageCheckbox"
                    className="h-4 w-4 rounded border-gray-300 text-teal-600 shadow-sm focus:border-teal-300 focus:ring focus:ring-teal-200 focus:ring-opacity-50 focus:ring-offset-0 disabled:cursor-not-allowed disabled:text-gray-400"
                  />
                  <label htmlFor="plumber" className="space-x-2 text-sm">
                    Plumber
                  </label>
                </div>
              </div>
            </div>
            <div className="mb-5 pt-3">
              <label className="mb-3 block text-base font-semibold Organic_Black ">
                Service Location
              </label>
              <div className="-mx-3 flex flex-wrap">
                <div className="w-full px-3 sm:w-1/3">
                  <div className="mb-5">
                    <select
                      name="sdivision"
                      id="sdivision"
                      className="w-full rounded-md border border-[#D1D1D1] bg-white py-3 px-6 text-base placeholder:text-[#D1D1D1] font-medium text-[#191308] outline-none focus:border-[#F6AE28] focus:shadow-md"
                    >
                      <option className="font-semibold text-slate-300">
                        Select Division
                      </option>
                      <option
                        value={"Dhaka"}
                        className="font-semibold text-slate-300"
                      >
                        Dhaka
                      </option>
                    </select>
                  </div>
                </div>
                <div className="w-full px-3 sm:w-1/3">
                  <div className="mb-5">
                    <select
                      name="sdistrict"
                      id="sdistrict"
                      className="w-full rounded-md border border-[#D1D1D1] bg-white py-3 px-6 text-base placeholder:text-[#D1D1D1] font-medium text-[#191308] outline-none focus:border-[#F6AE28] focus:shadow-md"
                    >
                      <option className="font-semibold text-slate-300">
                        Select District
                      </option>
                      <option
                        value={"Dhaka"}
                        className="font-semibold text-slate-300"
                      >
                        Dhaka
                      </option>
                    </select>
                  </div>
                </div>
                <div className="w-full px-3 sm:w-1/3">
                  <div className="mb-5">
                    <select
                      name="sthana"
                      id="sthana"
                      className="w-full rounded-md border border-[#D1D1D1] bg-white py-3 px-6 text-base placeholder:text-[#D1D1D1] font-medium text-[#191308] outline-none focus:border-[#F6AE28] focus:shadow-md"
                    >
                      <option className="font-semibold text-slate-300">
                        Select Thana
                      </option>
                      <option
                        value={"Savar"}
                        className="font-semibold text-slate-300"
                      >
                        Savar
                      </option>
                    </select>
                  </div>
                </div>

                <div className="w-full px-3 sm:w-3/3">
                  <div className="mb-5">
                    <textarea
                      name="stextarea"
                      id="stext"
                      cols="30"
                      rows="4"
                      className="h-30 w-full resize-none rounded-md border border-[#D1D1D1] p-5 text-base text-[#191308] outline-none focus:border-[#F6AE28] focus:shadow-md"
                      placeholder="Address"
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
            <div className="mb-5">
              <label
                htmlFor="prType"
                className="mb-3 block text-base font-semibold Organic_Black "
              >
                Complete projects
              </label>
              <select
                required
                name="prType"
                id="prType"
                className="w-full rounded-md border border-[#D1D1D1] bg-white py-3 px-6 text-base placeholder:text-[#D1D1D1] font-medium text-[#191308] outline-none focus:border-[#F6AE28] focus:shadow-md"
              >
                <option value={"<1"} className="font-semibold text-slate-300">
                  Less than 5
                </option>
                <option value={"<2"} className="font-semibold text-slate-300">
                  Less than 10
                </option>
                <option value={">2"} className="font-semibold text-slate-300">
                  More than 15
                </option>
                <option value={">5"} className="font-semibold text-slate-300">
                  More than 20
                </option>
              </select>
            </div>

            <div className="mb-5 pt-3">
              <label className="mb-5 block text-base font-semibold Organic_Black sm:text-xl">
                Contact Information
              </label>
              <div className="-mx-3 flex flex-wrap pl-4">
                <div className="w-full px-3 sm:w-1/2">
                  <div className="mb-5">
                    <label
                      htmlFor="name"
                      className="mb-3 block text-base font-semibold Organic_Black "
                    >
                      Full Name
                    </label>
                    <input
                      required
                      type="text"
                      name="name"
                      id="name"
                      placeholder="Full Name"
                      className="w-full rounded-md border border-[#D1D1D1] bg-white py-3 px-6 text-base placeholder:text-[#D1D1D1] font-medium text-[#191308] outline-none focus:border-[#F6AE28] focus:shadow-md"
                    />
                  </div>
                </div>
                <div className="w-full px-3 sm:w-1/2">
                  <div className="mb-5">
                    <label
                      htmlFor="phone"
                      className="mb-3 block text-base font-semibold Organic_Black"
                    >
                      Phone Number
                    </label>
                    <input
                      required
                      type="text"
                      name="phone"
                      id="phone"
                      placeholder="Enter your phone number"
                      className="w-full rounded-md border border-[#D1D1D1] bg-white py-3 px-6 text-base placeholder:text-[#D1D1D1] font-medium text-[#191308] outline-none focus:border-[#F6AE28] focus:shadow-md"
                    />
                  </div>
                </div>
                <div className="w-full px-3 sm:w-1/2">
                  <div className="mb-5">
                    <label
                      htmlFor="email"
                      className="mb-3 block text-base font-semibold Organic_Black"
                    >
                      Email Address
                    </label>
                    <input
                      type="email"
                      name="email"
                      id="email"
                      placeholder="Enter your email"
                      className="w-full rounded-md border border-[#D1D1D1] bg-white py-3 px-6 text-base placeholder:text-[#D1D1D1] font-medium text-[#191308] outline-none focus:border-[#F6AE28] focus:shadow-md"
                    />
                  </div>
                </div>
                <div className="w-full px-3 sm:w-1/2">
                  <div className="mb-5">
                    <label
                      htmlFor="website"
                      className="mb-3 block text-base font-semibold Organic_Black "
                    >
                      Website
                    </label>
                    <input
                      type="text"
                      name="website"
                      id="website"
                      placeholder="Type your website address (optional)"
                      className="w-full rounded-md border border-[#D1D1D1] bg-white py-3 px-6 text-base placeholder:text-[#D1D1D1] font-medium text-[#191308] outline-none focus:border-[#F6AE28] focus:shadow-md"
                    />
                  </div>
                </div>
                <div className="w-full px-3 ">
                  <div className="mb-5">
                    <label className="mb-3 block text-base font-semibold Organic_Black ">
                      Address
                    </label>
                    <textarea
                      name="stextarea"
                      id="stext"
                      cols="30"
                      rows="4"
                      className="h-20 w-full resize-none rounded-md border border-[#D1D1D1] p-5 text-base text-[#191308] outline-none focus:border-[#F6AE28] focus:shadow-md"
                      placeholder="Address"
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>

            <div className="mb-5 pt-3">
              <label className="mb-5 block text-base font-semibold Organic_Black sm:text-xl">
                Additional information for business
              </label>
              <div className="-mx-3 flex flex-wrap pl-4">
                <div className="w-full px-3 ">
                  <div className="mb-5 ">
                    <label className="mb-3 block text-base font-semibold Organic_Black ">
                      Company Logo
                    </label>
                    <div className="flex items-center space-x-6 pl-2">
                      <div className="shrink-0 ">
                        <img
                          id="logo"
                          className="h-16 w-16 object-cover rounded-full"
                          src="https://img.icons8.com/?size=100&id=bA8yiYCNSBKi&format=png&color=#F6AE28"
                          alt="Default pic"
                        />
                      </div>
                      <label className="block">
                        <span className="sr-only">Choose company logo</span>
                        <input
                          type="file"
                          name="clogo"
                          // onChange={loadFile()}
                          className="block w-full text-sm text-slate-500
        file:mr-4 file:py-2 file:px-4
        file:rounded-full file:border-0
        file:text-sm file:font-semibold
        file:bg-amber-50 file:text-[#F6AE28]
        hover:file:bg-amber-100
      "
                        />
                      </label>
                    </div>
                  </div>
                </div>
                <div className="w-full px-3">
                  <div className="mb-5">
                    <label
                      htmlFor="description"
                      className="mb-3 block text-base font-semibold Organic_Black "
                    >
                      Company Description
                    </label>
                    <textarea
                      name="description"
                      id="description"
                      cols="30"
                      rows="4"
                      className="h-30 w-full resize-none rounded-md border border-[#D1D1D1] p-5 text-base text-[#191308] outline-none focus:border-[#F6AE28] focus:shadow-md"
                      placeholder="Share your experience, expertise, and unique selling points"
                    ></textarea>
                  </div>
                </div>
                <div className="mb-5 pl-2">
                  <label className="mb-3 block text-base font-semibold Organic_Black ">
                    Licence and other documents
                  </label>
                  <div className="flex items-center space-x-6 pl-2">
                    <label className="block">
                      <span className="sr-only">
                        Licence and other documents
                      </span>
                      <input
                        type="file"
                        name="documents"
                        className="block w-full text-sm text-slate-500
        file:mr-4 file:py-2 file:px-4
        file:rounded-full file:border-0
        file:text-sm file:font-semibold
        file:bg-amber-50 file:text-[#F6AE28]
        hover:file:bg-amber-100
      "
                      />
                    </label>
                  </div>
                </div>
              </div>
            </div>

            <div className="mb-5 pt-3">
              <label className="mb-5 block text-base font-semibold Organic_Black sm:text-xl">
                Account Information
              </label>
              <div className="-mx-3 flex flex-wrap">
                <div className="w-full px-3 sm:w-2/2">
                  <div className="mb-5">
                    <label
                      htmlFor="username"
                      className="mb-3 block text-base font-semibold Organic_Black"
                    >
                      Username
                    </label>
                    <input
                      required
                      type="text"
                      name="username"
                      id="username"
                      placeholder="Username will be your phone number"
                      className="w-full rounded-md border border-[#D1D1D1] bg-white py-3 px-6 text-base placeholder:text-[#D1D1D1] font-medium text-[#191308] outline-none focus:border-[#F6AE28] focus:shadow-md"
                    />
                  </div>
                </div>
                <div className="w-full px-3 sm:w-1/2">
                  <div className="mb-5">
                    <label
                      htmlFor="password"
                      className="mb-3 block text-base font-semibold Organic_Black"
                    >
                      Password
                    </label>
                    <input
                      required
                      type="password"
                      name="password"
                      id="password"
                      placeholder="Enter your password"
                      className="w-full rounded-md border border-[#D1D1D1] bg-white py-3 px-6 text-base placeholder:text-[#D1D1D1] font-medium text-[#191308] outline-none focus:border-[#F6AE28] focus:shadow-md"
                    />
                  </div>
                </div>
                <div className="w-full px-3 sm:w-1/2">
                  <div className="mb-5">
                    <label
                      htmlFor="confirmPassword"
                      className="mb-3 block text-base font-semibold Organic_Black"
                    >
                      Confirm Password
                    </label>
                    <input
                      required
                      type="password"
                      name="confirmPassword"
                      id="confirmPassword"
                      placeholder="Confirm your password"
                      className="w-full rounded-md border border-[#D1D1D1] bg-white py-3 px-6 text-base placeholder:text-[#D1D1D1] font-medium text-[#191308] outline-none focus:border-[#F6AE28] focus:shadow-md"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="mb-5">
              <div className="flex gap-3 items-center">
                <input
                  required
                  type="checkbox"
                  id="privacy"
                  name="languageCheckbox"
                  className="h-4 w-4 rounded border-gray-300 text-teal-600 shadow-sm focus:border-teal-300 focus:ring focus:ring-teal-200 focus:ring-opacity-50 focus:ring-offset-0 disabled:cursor-not-allowed disabled:text-gray-400"
                />
                <label
                  htmlFor="privacy"
                  className=" w-full space-x-2 text-sm inline"
                >
                  I have read and accept the{" "}
                  <button
                    onClick={() =>
                      document.getElementById("privacyPolicy_modal").showModal()
                    }
                    className="link px-2 Orange_Yellow  hover:font-semibold "
                  >
                    Privacy statement
                  </button>
                </label>
              </div>
            </div>
            <div className="flex text-center justify-center pt-14 pb-8">
              <button className="text-xl sm:text-2xl hover:shadow-form w-3/6 rounded-md bg-[#F6AE28] py-3 px-4 sm:px-8 text-center font-semibold text-white outline-none">
                Register
              </button>
            </div>
          </Form>
        </div>
      </dialog>

      {/* privacy policy modal  */}
      <PrivacyPolicy />
    </div>
  );
};

export default PrRegister;
