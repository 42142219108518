import React from "react";
import { NavLink } from "react-router-dom";
import AppDownloadIocn from "../assets/img/APP-Download-icon.png";

function Footer() {
  return (
    <div>
      <div  className=" Accent_Red_bg pt-9 ">
        <div  className="max-w-screen-xl mx-auto">
          <div className="mx-auto w-full px-4 xl:px-0 ">
            <div className="flex flex-col justify-between sm:px-[18px] md:flex-row md:px-10">
              <div className="md:w-[316px]">
                <h1 data-aos-once="true" data-aos="fade-right" data-aos-duration="900" className="Light_Grey font-extrabold text-3xl">
                  BMC Portal
                </h1>
                <p data-aos-once="true" data-aos="fade-right" data-aos-duration="1500" className="text-justify mt-[18px] text-[15px] font-normal Light_Grey">
                  BMC Portal is where you connect with the skilled professionals
                  who will bring your construction vision to life. Whether
                  you're a homeowner embarking on a dream renovation or a
                  construction professional seeking to expand your reach, the
                  BMC Portal provides the tools and connections you need.
                </p>
                <div className="mt-[18px] flex gap-4">
                  <NavLink data-aos-once="true" data-aos="fade-right" data-aos-duration="1100"  className="hover:scale-110" target="_blank" to={"#"}>
                    <img
                      alt="facebook icon"
                      loading="lazy"
                      width="36"
                      height="36"
                      decoding="async"
                      data-nimg="1"
                      className="bg-transparent"
                      src="https://img.icons8.com/?size=100&id=118497&format=png&color=000000"
                    />
                  </NavLink>
                  <NavLink data-aos-once="true" data-aos="fade-right" data-aos-duration="1300"  className="hover:scale-110" target="_blank" to={"#"}>
                    <img
                      alt="NavLinkdin icon"
                      loading="lazy"
                      width="36"
                      height="36"
                      decoding="async"
                      data-nimg="1"
                      className="bg-transparent"
                      src="https://img.icons8.com/?size=100&id=13930&format=png&color=000000"
                    />
                  </NavLink>
                  <NavLink data-aos-once="true" data-aos="fade-right" data-aos-duration="1500"  className="hover:scale-110" target="_blank" to={"#"}>
                    <img
                      alt="instagram icon"
                      loading="lazy"
                      width="36"
                      height="36"
                      decoding="async"
                      data-nimg="1"
                      className="bg-transparent"
                      src="https://img.icons8.com/?size=100&id=32323&format=png&color=000000"
                    />
                  </NavLink>
                  <NavLink data-aos-once="true" data-aos="fade-right" data-aos-duration="1700" className="hover:scale-110" target="_blank" to={"#"}>
                    <img
                      alt="twitter icon"
                      loading="lazy"
                      width="36"
                      height="36"
                      decoding="async"
                      data-nimg="1"
                      className="bg-transparent"
                      src="https://img.icons8.com/?size=100&id=13963&format=png&color=000000"
                    />
                  </NavLink>
                  <NavLink
                    className="hover:scale-110"
                    target="_blank"
                    to={"https://www.youtube.com/"}
                     data-aos="fade-right" data-aos-duration="1900" data-aos-once="true"
                  >
                    <img
                      alt="youtube icon"
                      loading="lazy"
                      width="36"
                      height="36"
                      decoding="async"
                      data-nimg="1"
                      className="bg-transparent"
                      src="https://img.icons8.com/?size=100&id=19318&format=png&color=000000"
                    />
                  </NavLink>
                </div>
              </div>
              <div className="md:w-[316px]">
                <div data-aos-once="true" data-aos="fade-up" data-aos-duration="1100" className="mt-[23px] flex">
                  <div className="flex h-[38px] w-[38px] items-center justify-center rounded-[75%]">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M20.8472 14.8554L16.4306 12.8764L16.4184 12.8707C16.1892 12.7727 15.939 12.7333 15.6907 12.7562C15.4424 12.7792 15.2037 12.8636 14.9963 13.002C14.9718 13.0181 14.9484 13.0357 14.9259 13.0545L12.6441 14.9998C11.1984 14.2976 9.70595 12.8164 9.00376 11.3895L10.9519 9.07294C10.9706 9.0495 10.9884 9.02606 11.0053 9.00075C11.1407 8.79384 11.2229 8.55667 11.2445 8.31035C11.2661 8.06402 11.2264 7.81618 11.1291 7.58887V7.57762L9.14438 3.15356C9.0157 2.85662 8.79444 2.60926 8.51362 2.44841C8.2328 2.28756 7.9075 2.22184 7.58626 2.26106C6.31592 2.42822 5.14986 3.05209 4.30588 4.01615C3.4619 4.98021 2.99771 6.21852 3.00001 7.49981C3.00001 14.9436 9.05626 20.9998 16.5 20.9998C17.7813 21.0021 19.0196 20.5379 19.9837 19.6939C20.9477 18.85 21.5716 17.6839 21.7388 16.4136C21.7781 16.0924 21.7125 15.7672 21.5518 15.4864C21.3911 15.2056 21.144 14.9843 20.8472 14.8554ZM16.5 19.4998C13.3185 19.4963 10.2682 18.2309 8.01856 15.9813C5.76888 13.7316 4.50348 10.6813 4.50001 7.49981C4.49648 6.58433 4.82631 5.69887 5.42789 5.00879C6.02947 4.3187 6.86167 3.87118 7.76907 3.74981C7.7687 3.75355 7.7687 3.75732 7.76907 3.76106L9.73782 8.16731L7.80001 10.4867C7.78034 10.5093 7.76247 10.5335 7.74657 10.5589C7.60549 10.7754 7.52273 11.0246 7.5063 11.2825C7.48988 11.5404 7.54035 11.7981 7.65282 12.0307C8.5022 13.7679 10.2525 15.5051 12.0084 16.3536C12.2428 16.465 12.502 16.5137 12.7608 16.495C13.0196 16.4762 13.2692 16.3907 13.485 16.2467C13.5091 16.2305 13.5322 16.2129 13.5544 16.1942L15.8334 14.2498L20.2397 16.2232C20.2397 16.2232 20.2472 16.2232 20.25 16.2232C20.1301 17.1319 19.6833 17.9658 18.9931 18.5689C18.3028 19.172 17.4166 19.5029 16.5 19.4998Z"
                        fill="#f6ae28"
                      ></path>
                    </svg>
                  </div>
                  <div className="ml-[18px] flex items-center">
                    <a
                      href="tel:+8801313444716"
                      className="font-Inter text-[14px] font-medium text-white "
                    >
                      +880 1313 444716
                    </a>
                    {/* <p className="font-Inter text-[12px] font-medium text-white">
                    Support Number
                  </p> */}
                  </div>
                </div>
                <div data-aos-once="true" data-aos="fade-up" data-aos-duration="1400"  className="mt-[23px] flex">
                  <div className="flex h-[38px] w-[38px] items-center justify-center rounded-[75%]">
                    <svg
                      width="20"
                      height="15"
                      viewBox="0 0 20 15"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M19 0H1C0.801088 0 0.610322 0.0790178 0.46967 0.21967C0.329018 0.360322 0.25 0.551088 0.25 0.75V13.5C0.25 13.8978 0.408035 14.2794 0.68934 14.5607C0.970644 14.842 1.35218 15 1.75 15H18.25C18.6478 15 19.0294 14.842 19.3107 14.5607C19.592 14.2794 19.75 13.8978 19.75 13.5V0.75C19.75 0.551088 19.671 0.360322 19.5303 0.21967C19.3897 0.0790178 19.1989 0 19 0ZM10 7.98281L2.92844 1.5H17.0716L10 7.98281ZM7.25406 7.5L1.75 12.5447V2.45531L7.25406 7.5ZM8.36406 8.51719L9.48906 9.55312C9.62743 9.68014 9.80842 9.75062 9.99625 9.75062C10.1841 9.75062 10.3651 9.68014 10.5034 9.55312L11.6284 8.51719L17.0659 13.5H2.92844L8.36406 8.51719ZM12.7459 7.5L18.25 2.45438V12.5456L12.7459 7.5Z"
                        fill="#f6ae28"
                      ></path>
                    </svg>
                  </div>
                  <div className="ml-[18px] flex items-center">
                    <a
                      href="mailto:bmc.com.bd@gmail.com"
                      className="font-Inter text-[14px] font-medium Light_Grey"
                    >
                      bmc.com.bd@gmail.com
                    </a>
                    {/* <p className="font-Inter text-[12px] font-medium Light_Grey">
                    Support Email
                  </p> */}
                  </div>
                </div>
                <div data-aos-once="true" data-aos="fade-up" data-aos-duration="1700"  className="mt-[23px] flex">
                  <div className="flex h-[38px] w-[38px] items-center justify-center rounded-[75%] pl-2.5">
                    <svg
                      width="18"
                      height="21"
                      viewBox="0 0 18 21"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9 4.5C8.25832 4.5 7.5333 4.71993 6.91661 5.13199C6.29993 5.54404 5.81928 6.12971 5.53545 6.81494C5.25162 7.50016 5.17736 8.25416 5.32205 8.98159C5.46675 9.70902 5.8239 10.3772 6.34835 10.9017C6.8728 11.4261 7.54098 11.7833 8.26841 11.9279C8.99584 12.0726 9.74984 11.9984 10.4351 11.7145C11.1203 11.4307 11.706 10.9501 12.118 10.3334C12.5301 9.7167 12.75 8.99168 12.75 8.25C12.75 7.25544 12.3549 6.30161 11.6517 5.59835C10.9484 4.89509 9.99456 4.5 9 4.5ZM9 10.5C8.55499 10.5 8.11998 10.368 7.74997 10.1208C7.37996 9.87357 7.09157 9.52217 6.92127 9.11104C6.75097 8.6999 6.70642 8.2475 6.79323 7.81105C6.88005 7.37459 7.09434 6.97368 7.40901 6.65901C7.72368 6.34434 8.12459 6.13005 8.56105 6.04323C8.9975 5.95642 9.4499 6.00097 9.86104 6.17127C10.2722 6.34157 10.6236 6.62996 10.8708 6.99997C11.118 7.36998 11.25 7.80499 11.25 8.25C11.25 8.84674 11.0129 9.41903 10.591 9.84099C10.169 10.2629 9.59674 10.5 9 10.5ZM9 0C6.81273 0.00248131 4.71575 0.872472 3.16911 2.41911C1.62247 3.96575 0.752481 6.06273 0.75 8.25C0.75 11.1938 2.11031 14.3138 4.6875 17.2734C5.84552 18.6108 7.14886 19.8151 8.57344 20.8641C8.69954 20.9524 8.84978 20.9998 9.00375 20.9998C9.15772 20.9998 9.30796 20.9524 9.43406 20.8641C10.856 19.8147 12.1568 18.6104 13.3125 17.2734C15.8859 14.3138 17.25 11.1938 17.25 8.25C17.2475 6.06273 16.3775 3.96575 14.8309 2.41911C13.2843 0.872472 11.1873 0.00248131 9 0ZM9 19.3125C7.45031 18.0938 2.25 13.6172 2.25 8.25C2.25 6.45979 2.96116 4.7429 4.22703 3.47703C5.4929 2.21116 7.20979 1.5 9 1.5C10.7902 1.5 12.5071 2.21116 13.773 3.47703C15.0388 4.7429 15.75 6.45979 15.75 8.25C15.75 13.6153 10.5497 18.0938 9 19.3125Z"
                        fill="#f6ae28"
                      ></path>
                    </svg>
                  </div>
                  <div className="ml-[18px] flex items-center">
                    <p className="font-Inter text-[14px] font-medium Light_Grey">
                      House # KA-24, Pragati Sarani A Mozid Tower, 4th Floor,
                      Kuril, Vatara Dhaka - 1229
                    </p>
                  </div>
                </div>
              </div>
              <div className="mt-6 flex w-full  justify-between Light_Grey flex-row md:mt-0 md:max-w-[341px]">
                <div className="">
                  <p data-aos-once="true" data-aos="zoom-out" data-aos-duration="1100"  className="text-deutziawhite font-inter text-[18px] leading-normal font-semibold">
                    Pages
                  </p>
                  <ul data-aos-once="true" data-aos="zoom-in" data-aos-duration="1100" className="Orange_Yellow ">
                    <li className="mt-[15px]">
                      <NavLink
                        className="text-deutziawhite hover:text-white hover:text-deutziawhite/80 font-inter text-[15px] font-normal hover:font-semibold"
                        to={"/"}
                      >
                        Home
                      </NavLink>
                    </li>
                    <li className="mt-[15px]">
                      <NavLink
                        className="text-deutziawhite hover:text-white hover:text-deutziawhite/80 font-inter text-[15px] font-normal hover:font-semibold"
                        to={"services"}
                      >
                        Services
                      </NavLink>
                    </li>
                    <li className="mt-[15px]">
                      <NavLink
                        className="text-deutziawhite hover:text-white hover:text-deutziawhite/80 font-inter text-[15px] font-normal hover:font-semibold"
                        to={"users"}
                      >
                        Users
                      </NavLink>
                    </li>
                    <li className="mt-[15px]">
                      <NavLink
                        className="text-deutziawhite hover:text-white hover:text-deutziawhite/80 font-inter text-[15px] font-normal hover:font-semibold"
                        to={"providers"}
                      >
                        Providers
                      </NavLink>
                    </li>
                    <li className="mt-[15px]">
                      <NavLink
                        className="text-deutziawhite hover:text-white hover:text-deutziawhite/80 font-inter text-[15px] font-normal hover:font-semibold"
                        to={"projects"}
                      >
                        Projects
                      </NavLink>
                    </li>
                    <li className="mt-[15px]">
                      <NavLink
                        className="text-deutziawhite hover:text-white hover:text-deutziawhite/80 font-inter text-[15px] font-normal hover:font-semibold"
                        to={"support"}
                      >
                        Support
                      </NavLink>
                    </li>
                  </ul>
                </div>
                <div data-aos-once="true" data-aos="flip-left" data-aos-duration="1700" className="mt-6 flex flex-col gap-4 sm:mt-0">
                  <div className="flex gap-4 sm:flex-col">
                    <NavLink
                      target="_blank"
                      to={"#"}
                      className="flex text-blue-700"
                    >
                      <img
                        alt="PlayStore icon"
                        loading="lazy"
                        decoding="async"
                        data-nimg="1"
                        className="bg-transparent w-24 sm:w-36 "
                        src={AppDownloadIocn}
                      />
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
            <hr className="mt-[30px] Light_Grey" />
            <div  className="flex items-center justify-center pb-8 pt-[9px] md:py-8">
              <p className="text-[10px] font-normal Light_Grey md:text-[12px]">
                &copy; Copyright {new Date().getFullYear()}, All Rights Reserved
                by BMC Group
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
