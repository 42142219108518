import React from "react";
import { Link } from "react-router-dom";
import mstrader from "../../assets/img/service/mstradingpic.jpg";
import { motion } from "framer-motion";
import { useEffect } from "react";

const Plumber = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="pt-16">
      <div className="services-banner-sec">
        <div className="h-[200px] md:h-[300px] ">
          <div className="max-w-screen-xl mx-auto pt-20 md:pt-28">
            {/* relative h-[400px] md:h-[570px] bg-gradient-to-b from-amber-600 via-amber-400 to-amber-50 */}
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.5, ease: "easeOut", delay: 0.1 }}
              className="flex flex-col gap-4 md:gap-8 justify-center items-center w-full h-full px-3 md:px-0"
            >
              <motion.h1
                initial={{ opacity: 0, scale: 0.7, y: 0 }}
                animate={{ opacity: 1, scale: 1, y: 0 }}
                transition={{ duration: 1, ease: "easeOut", delay: 0.1 }}
                id="typewriter"
                className="capitalize relative text-4xl md:text-5xl lg:text-6xl font-bold text-white text-center"
              >
                Plumber Category
              </motion.h1>
              <motion.p
                initial={{ opacity: 0, x: 50 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 1, ease: "easeOut", delay: 0.1 }}
                className="text-gray-200 relative capitalize"
              >
                Building your future with passion
              </motion.p>
            </motion.div>
          </div>
        </div>
      </div>
      <div class="grid grid-cols-11 gap-4">
        <div className="grid col-span-11 md:col-span-2">
          
        </div>
        <div className="grid md:grid-cols-2 col-span-11  md:col-span-7">
          <Link to={"plumber1"}>
            <div className="w-full mx-auto p-4">
              <div className="flex gap-3 bg-white border border-gray-300 rounded-xl overflow-hidden items-center justify-start">
                <div className="relative w-28 h-32 flex-shrink-0 ml-2">
                  <img
                    className="object-contain absolute left-0 top-0 w-full h-full object-center transition duration-50"
                    loading="lazy"
                    src={mstrader}
                    alt="M/S. Dana Trading"
                  />
                </div>

                <div className="flex flex-col gap-2 py-2">
                  <p className="text-xl font-bold">M/S. Dana Trading</p>

                  <p className="text-gray-500 two-lines"> Dana Trading Pipe and Foundry has been the one-source supplier for pipe and fittings for residential, commercial, industrial, and municipal projects.</p>
                </div>
              </div>
            </div>
          </Link>
          <Link to={"plumber1"}>
            <div className="w-full mx-auto p-4">
              <div className="flex gap-3 bg-white border border-gray-300 rounded-xl overflow-hidden items-center justify-start">
                <div className="relative w-28 h-32 flex-shrink-0 ml-2">
                  <img
                    className="object-contain absolute left-0 top-0 w-full h-full object-center transition duration-50"
                    loading="lazy"
                    src={mstrader}
                    alt="M/S. Dana Trading"
                  />
                </div>

                <div className="flex flex-col gap-2 py-2">
                  <p className="text-xl font-bold">M/S. Dana Trading</p>

                  <p className="text-gray-500 two-lines"> Dana Trading Pipe and Foundry has been the one-source supplier for pipe and fittings for residential, commercial, industrial, and municipal projects.</p>
                </div>
              </div>
            </div>
          </Link>
          <Link to={"plumber1"}>
            <div className="w-full mx-auto p-4">
              <div className="flex gap-3 bg-white border border-gray-300 rounded-xl overflow-hidden items-center justify-start">
                <div className="relative w-28 h-32 flex-shrink-0 ml-2">
                  <img
                    className="object-contain absolute left-0 top-0 w-full h-full object-center transition duration-50"
                    loading="lazy"
                    src={mstrader}
                    alt="M/S. Dana Trading"
                  />
                </div>

                <div className="flex flex-col gap-2 py-2">
                  <p className="text-xl font-bold">M/S. Dana Trading</p>

                  <p className="text-gray-500 two-lines"> Dana Trading Pipe and Foundry has been the one-source supplier for pipe and fittings for residential, commercial, industrial, and municipal projects.</p>
                </div>
              </div>
            </div>
          </Link>
        </div>
        <div className="grid col-span-11 md:col-span-2"></div>
      </div>
    </div>
  );
};

export default Plumber;
