import React, { useEffect } from "react";

const ChatBox = ({name}) => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
  return (
    <div>
      <dialog id="chatBox_modal" className="modal  ">
        <div className="modal-box absolute bottom-5 right-5 w-full max-w-md  bg-gray-100 p-0 m-0 pt-5 ">
          <div className="modal-action pr-5 mt-0">
            <form method="dialog">
              <button className="p-1 Organic_Black bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg">
                <svg
                  className="w-5 h-5"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </button>
            </form>
          </div>

          <div className="w-full max-w-md bg-gray-100  dark:bg-gray-800 rounded-2xl neo-shadow p-6 space-y-6">
            <div className="flex items-center space-x-4 ">
              <div className="w-12 h-12 rounded-full neo-shadow flex items-center justify-center shadow-md bg-gray-50">
                <span className="text-xl font-semibold text-gray-700 dark:text-gray-300">
                {name[0]}
                </span>
              </div>
              <h1 className="text-2xl font-bold text-gray-800 dark:text-gray-200">
                Chat with {name}
              </h1>
            </div>

            <div className=" h-52 overflow-y-auto neo-inset p-4 rounded-xl space-y-4">
              <div className="flex items-start space-x-2">
                <div className="w-8 h-8 rounded-full neo-shadow flex-shrink-0 flex items-center justify-center shadow-md bg-gray-50">
                  <span className="text-sm font-medium text-gray-700 dark:text-gray-300">
                  {name[0]}
                  </span>
                </div>
                <div className="bg-white dark:bg-gray-700 p-3 rounded-lg neo-shadow max-w-xs">
                  <p className="text-sm text-gray-700 dark:text-gray-300">
                    Hey there! How's it going?
                  </p>
                </div>
              </div>

              <div className="flex items-start justify-end space-x-2">
                <div className="bg-[#F6AE28] p-3 rounded-lg neo-shadow max-w-xs">
                  <p className="text-sm text-white">
                    Hi John! I'm doing well, thanks for asking. How about you?
                  </p>
                </div>
              </div>
              <div className="flex items-start justify-end space-x-2">
                <div className="bg-[#F6AE28] p-3 rounded-lg neo-shadow max-w-xs">
                  <p className="text-sm text-white">
                    Hi John! I'm doing well, thanks for asking. How about you?
                  </p>
                </div>
              </div>
              <div className="flex items-start justify-end space-x-2">
                <div className="bg-[#F6AE28] p-3 rounded-lg neo-shadow max-w-xs">
                  <p className="text-sm text-white">
                    Hi John! I'm doing well, thanks for asking. How about you?
                  </p>
                </div>
              </div>
            </div>

            <div className="flex items-center space-x-4">
              <div className="flex-grow">
                <input
                  type="text"
                  placeholder="Type your message..."
                  className="w-full p-4 rounded-xl neo-inset bg-transparent text-gray-700 dark:text-gray-300 placeholder-gray-500 dark:placeholder-gray-400 focus:outline-none"
                />
              </div>
              <button className="p-4 rounded-xl neo-shadow neo-button focus:outline-none">
                <img
                  className="w-7"
                  src="https://img.icons8.com/?size=100&id=16112&format=png&color=F6AE28"
                  alt="send icon"
                />
              </button>
            </div>
          </div>
        </div>
      </dialog>
    </div>
  );
};

export default ChatBox;
