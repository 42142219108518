import React from "react";
import { Link } from "react-router-dom";
import TutorialVideo from "./TutorialVideo";

const ConstractionGuide = () => {
  return (
    <div className="py-16 px-8">
      <div className="flex flex-col md:flex-row  ">
        <div className="w-full md:w-1/2 ">
          <div className="w-full mb-8 md:mb-0">
            <h1 className="text-xl md:text-4xl font-bold text-[#F6AE28] mb-4">
              BMC Portal Construction Guide
            </h1>
            <p className="text-md sm:text-xl text-[#d1d1d1] pt-0 sm:pt-4">
              Featuring product information, installation instructions, and
              technical guidance.
            </p>
          </div>

          <div className="flow-root container m-auto p-5 text-[#D1D1D1]">
            <ul className="-mb-8">
              <li>
                <div className="relative pb-8">
                  <span
                    className="absolute top-5 left-5 -ml-px h-full w-0.5 bg-gray-200 "
                    aria-hidden="true"
                  ></span>
                  <div className="relative flex space-x-3">
                    <div>
                      <span className="h-10 w-10 rounded-full flex items-center bg-[#B80000] p-1 justify-center ring-4 ring-white">
                        <img
                          src="https://img.icons8.com/?size=100&id=99815&format=png&color=F6AE28"
                          alt="guideIcon"
                        />
                      </span>
                    </div>
                    <div className="min-w-0 flex-1 pt-1.5 flex justify-between space-x-4">
                      <div>
                        <Link to={"#"} className="text-lg hover:font-semibold ">
                          Guide 1 pdf link
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div className="relative pb-8">
                <span
                    className="absolute top-5 left-5 -ml-px h-full w-0.5 bg-gray-200 "
                    aria-hidden="true"
                  ></span>
                  <div className="relative flex space-x-3">
                    <div>
                      <span className="h-10 w-10 rounded-full flex items-center bg-[#B80000] p-1 justify-center ring-4 ring-white">
                        <img
                          src="https://img.icons8.com/?size=100&id=99815&format=png&color=F6AE28"
                          alt="guideIcon"
                        />
                      </span>
                    </div>
                    <div className="min-w-0 flex-1 pt-1.5 flex justify-between space-x-4">
                      <div>
                        <Link to={"#"} className="text-lg hover:font-semibold ">
                          Guide 2 pdf link
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div className="relative pb-8 ">
                  <div className="relative flex space-x-3">
                    <div>
                      <span className="h-10 w-10 rounded-full flex items-center bg-[#B80000] p-1 justify-center ring-4 ring-white">
                        <img
                          src="https://img.icons8.com/?size=100&id=99815&format=png&color=F6AE28"
                          alt="guideIcon"
                        />
                      </span>
                    </div>
                    <div className="min-w-0 flex-1 pt-1.5 flex justify-between space-x-4">
                      <div>
                        <Link to={"#"} className="text-lg hover:font-semibold ">
                          Guide 3 pdf link
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div className="w-full md:w-1/2 ">
            <TutorialVideo />
        </div>
      </div>
    </div>
  );
};

export default ConstractionGuide;
