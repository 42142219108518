import React from "react";
import { useItemsList } from "../components/hook/useItemsList";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import SearchBar from "../components/SearchBar";

function Services() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  let { items } = useItemsList();
  // const categories = items.map((item) => item.category);
  // const cats = new Set(categories);
  console.log(items);

  return (
    <div className="pt-16  max-w-screen-xl mx-auto">
      
      <SearchBar />
      <div className="grid grid-cols-12 sm:mx-8 bg-white bg-opacity-10 sm:my-8">
        <div className="col-span-12 pb-10 ">
          <div className=" relative mx-auto ">
            <h1
              data-aos-once="true"
              data-aos="fade-right"
              data-aos-duration="700"
              data-aos-easing="linear"
              className="text-xl pl-10 pt-10 font-semibold tracking-tight text-gray-200 sm:text-3xl font-title"
            >
              All services
            </h1>
            <div className="flex flex-wrap mx-auto lg:w-full md:w-5/6 xl:shadow-small-blue px-7">
              <div
                data-aos-once="true"
                data-aos="fade-up"
                data-aos-duration="900"
                data-aos-easing="linear"
                className="block w-full text-center p-1 mt-10 lg:w-1/4 md:w-1/2 "
              >
                <Link to={"/services/plumber"}>
                  <article className="relative isolate flex flex-col justify-end overflow-hidden rounded-2xl px-2 sm:px-8 pb-8 pt-20 lg:pt-40 md:pt-32 max-w-sm mx-auto ">
                    <img
                      src="https://images.pexels.com/photos/6419128/pexels-photo-6419128.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                      alt="University of Southern California"
                      className="absolute inset-0 h-full w-full object-cover"
                    />
                    <div className="absolute inset-0 bg-gradient-to-t from-gray-300 via-gray-300/40 hover:from-gray-200"></div>

                    <div className="flex items-center gap-0 ">
                      <img
                        src="https://img.icons8.com/?size=100&id=sxDSDWHItjUD&format=png&color=341f66"
                        className="block z-10 w-5 sm:w-8 pt-1"
                        alt="plumber icon"
                      />
                      <h3 className="z-10 text-base lg:text-2xl md:text-xl font-semibold Corporate_Blue capitalize pl-1 sm:pl-3">
                        plumber
                      </h3>
                    </div>

                    <div className="z-10 gap-y-1 overflow-hidden text-sm leading-6 "></div>
                  </article>
                </Link>
              </div>

              <div
                data-aos-once="true"
                data-aos="fade-down"
                data-aos-duration="900"
                data-aos-easing="linear"
                className="block w-1/2 text-center p-1 mt-10 lg:w-1/4 md:w-1/2"
              >
                <Link to={"/services/electrician"}>
                  <article className="relative isolate flex flex-col justify-end overflow-hidden rounded-2xl px-2 sm:px-8 pb-8 pt-20 lg:pt-40 md:pt-32 max-w-sm mx-auto ">
                    <img
                      src="https://images.pexels.com/photos/17842832/pexels-photo-17842832/free-photo-of-electrician-by-fuse-box.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                      alt="University of Southern California"
                      className="absolute inset-0 h-full w-full object-cover"
                    />
                    <div className="absolute inset-0 bg-gradient-to-t from-gray-300 via-gray-300/40 hover:from-gray-200"></div>

                    <div className="flex items-center gap-0 ">
                      <img
                        src="https://img.icons8.com/?size=100&id=Z8GMtHiLgzDC&format=png&color=341f66"
                        className="block z-10 w-5 sm:w-8 pt-1"
                        alt="Electrician icon"
                      />
                      <h3 className="z-10 text-base lg:text-2xl md:text-xl font-semibold Corporate_Blue capitalize pl-1 sm:pl-3">
                        Electrician
                      </h3>
                    </div>

                    <div className="z-10 gap-y-1 overflow-hidden text-sm leading-6 "></div>
                  </article>
                </Link>
              </div>
              <div
                data-aos-once="true"
                data-aos="fade-up"
                data-aos-duration="900"
                data-aos-easing="linear"
                className="block w-1/2 text-center p-1 mt-10 lg:w-1/4 md:w-1/2"
              >
                <Link to={"/services/tiles"}>
                  <article className="relative isolate flex flex-col justify-end overflow-hidden rounded-2xl px-2 sm:px-8 pb-8 pt-20 lg:pt-40 md:pt-32 max-w-sm mx-auto ">
                    <img
                      src="https://images.pexels.com/photos/4977402/pexels-photo-4977402.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                      alt="University of Southern California"
                      className="absolute inset-0 h-full w-full object-cover"
                    />
                    <div className="absolute inset-0 bg-gradient-to-t from-gray-300 via-gray-300/40 hover:from-gray-200"></div>

                    <div className="flex items-center gap-0 ">
                      <img
                        src="https://img.icons8.com/?size=100&id=Z8xNL1K116ai&format=png&color=341f66"
                        className="block z-10 w-5 sm:w-8 pt-1"
                        alt="Tiles icon"
                      />
                      <h3 className="z-10 text-base lg:text-2xl md:text-xl font-semibold Corporate_Blue capitalize pl-1 sm:pl-3">
                        Tiles
                      </h3>
                    </div>

                    <div className="z-10 gap-y-1 overflow-hidden text-sm leading-6 "></div>
                  </article>
                </Link>
              </div>
              <div
                data-aos-once="true"
                data-aos="fade-down"
                data-aos-duration="900"
                data-aos-easing="linear"
                className="block w-1/2 text-center p-1 mt-10 lg:w-1/4 md:w-1/2"
              >
                <Link to={"/services/painter"}>
                  <article className="relative isolate flex flex-col justify-end overflow-hidden rounded-2xl px-2 sm:px-8 pb-8 pt-20 lg:pt-40 md:pt-32 max-w-sm mx-auto ">
                    <img
                      src="https://images.pexels.com/photos/5799086/pexels-photo-5799086.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                      alt="University of Southern California"
                      className="absolute inset-0 h-full w-full object-cover"
                    />
                    <div className="absolute inset-0 bg-gradient-to-t from-gray-300 via-gray-300/40 hover:from-gray-200"></div>

                    <div className="flex items-center gap-0 ">
                      <img
                        src="https://img.icons8.com/?size=100&id=G9ieQh0RXL1N&format=png&color=341f66"
                        className="block z-10 w-5 sm:w-8 pt-1"
                        alt="Painter icon"
                      />
                      <h3 className="z-10 text-base lg:text-2xl md:text-xl font-semibold Corporate_Blue capitalize pl-1 sm:pl-3">
                        Painter
                      </h3>
                    </div>

                    <div className="z-10 gap-y-1 overflow-hidden text-sm leading-6 "></div>
                  </article>
                </Link>
              </div>
              <div
                data-aos-once="true"
                data-aos="fade-up"
                data-aos-duration="900"
                data-aos-easing="linear"
                className="block w-1/2 text-center p-1 mt-10 lg:w-1/4 md:w-1/2"
              >
                <Link to={"#"}>
                  <article className="relative isolate flex flex-col justify-end overflow-hidden rounded-2xl px-2 sm:px-8 pb-8 pt-20 lg:pt-40 md:pt-32 max-w-sm mx-auto ">
                    <img
                      src="https://images.pexels.com/photos/4503261/pexels-photo-4503261.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                      alt="University of Southern California"
                      className="absolute inset-0 h-full w-full object-cover"
                    />
                    <div className="absolute inset-0 bg-gradient-to-t from-gray-300 via-gray-300/40 hover:from-gray-200"></div>

                    <div className="flex items-center gap-0 ">
                      <img
                        src="https://img.icons8.com/?size=100&id=upIWVU5bU91Q&format=png&color=341f66"
                        className="block z-10 w-5 sm:w-8 pt-1"
                        alt="Gardener icon"
                      />
                      <h3 className="z-10 text-base lg:text-2xl md:text-xl font-semibold Corporate_Blue capitalize pl-1 sm:pl-3">
                        Gardener
                      </h3>
                    </div>

                    <div className="z-10 gap-y-1 overflow-hidden text-sm leading-6 "></div>
                  </article>
                </Link>
              </div>
            </div>
          </div>
        </div>
        
      </div>
    </div>
  );
}

export default Services;
