import { useEffect, useState } from "react";
import axios from "axios";

export const useItemsList = () => {
  const [items, setItems] = useState([]);
  
  useEffect(() => {
    axios
      .get("https://dummyjson.com/products/")
      .then((res) => setItems(res.data.products))
      .catch((err) => console.log(err));
  }, []);

  return { items };
};
