import React from "react";
import { Link } from "react-router-dom";

function PopularService() {
  return (
    <div>
      <div className="max-w-screen-xl relative mx-auto my-14 ">
        <div
          className="mx-auto px-7"
          data-aos="zoom-in"
          data-aos-easing="linear"
          data-aos-duration="500"
          data-aos-once="true"
        >
          <h2 className="text-3xl font-bold Light_Grey mb-16 text-center capitalize">
            popular Services
          </h2>
        </div>
        <div className="flex flex-wrap justify-center mx-auto lg:w-full md:w-5/6 xl:shadow-small-blue px-7">
          <div
            className="block w-1/2 text-center p-1 lg:w-1/4"
            data-aos="flip-left"
            data-aos-easing="ease-out-cubic"
            data-aos-duration="500"
            data-aos-once="true"
          >
            <Link to={"/services/plumber"}>
              <div className="py-10 Orange_Yellow_bg ">
                <img
                  src="https://img.icons8.com/?size=100&id=sxDSDWHItjUD&format=png&color=341f66"
                  className="block mx-auto"
                  alt="plumber icon"
                />

                <p className="pt-4 text-sm font-medium capitalize font-body Corporate_Blue lg:text-lg md:text-base md:pt-6">
                  plumber
                </p>
              </div>
            </Link>
          </div>

          <div
            className="  block w-1/2 p-1  text-center lg:w-1/4"
            data-aos="flip-left"
            data-aos-easing="ease-out-cubic"
            data-aos-duration="1000"
            data-aos-once="true"
          >
            <Link to={"/services/electrician"}>
              <div className="py-10 Orange_Yellow_bg ">
                <img
                  src="https://img.icons8.com/?size=100&id=Z8GMtHiLgzDC&format=png&color=341f66"
                  className="block mx-auto"
                  alt="Electrician icon"
                />

                <p className="pt-4 text-sm font-medium capitalize font-body Corporate_Blue lg:text-lg md:text-base md:pt-6">
                  Electrician
                </p>
              </div>
            </Link>
          </div>

          <div
            className=" block w-1/2 text-center p-1  lg:w-1/4"
            data-aos="flip-left"
            data-aos-easing="ease-out-cubic"
            data-aos-duration="1500"
            data-aos-once="true"
          >
            <Link to={"/services/tiles"}>
              <div className="py-10 Orange_Yellow_bg ">
                <img
                  src="https://img.icons8.com/?size=100&id=Z8xNL1K116ai&format=png&color=341f66"
                  className="block mx-auto"
                  alt="Builder icon"
                />

                <p className="pt-4 text-sm font-medium capitalize font-body Corporate_Blue lg:text-lg md:text-base md:pt-6">
                  Tiles
                </p>
              </div>
            </Link>
          </div>

          <div
            className="block w-1/2 text-center p-1  lg:w-1/4 "
            data-aos="flip-left"
            data-aos-easing="ease-out-cubic"
            data-aos-duration="2000"
            data-aos-once="true"
          >
            <Link to={"/services/painter"}>
              <div className="py-10 Orange_Yellow_bg ">
                <img
                  src="https://img.icons8.com/?size=100&id=G9ieQh0RXL1N&format=png&color=341f66"
                  className="block mx-auto"
                  alt="Painter icon"
                />

                <p className="pt-4 text-sm font-medium capitalize font-body Corporate_Blue lg:text-lg md:text-base md:pt-6">
                  Painter
                </p>
              </div>
            </Link>
          </div>
        </div>
        <div
          data-aos="zoom-in"
          data-aos-easing="linear"
          data-aos-duration="500"
          data-aos-once="true"
          className="flex justify-center mx-auto py-5"
        >
          <Link
            to={"popular-service"}
            className="bg-[#F6AE28] hover:bg-[#f6ae28d0] text-[#191308] uppercase font-bold py-3 px-10 rounded text-center"
          >
            View All
          </Link>
        </div>
      </div>
    </div>
  );
}

export default PopularService;
