import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import logoImg from "../assets/img/bmc-portal-logo-img.png";

const Navbar = () => {
  const [nav, setNav] = useState(false);

  const handleNav = () => {
    setNav(!nav);
  };
  return (
    <div className="w-full fixed z-50 Accent_Red_bg">
      <div className="desktop_navbar flex justify-between items-center h-[68px]  max-w-screen-xl mx-auto Orange_Yellow">
        
        <NavLink
          to={"/"}
          className="btn btn-ghost ml-2  hover:bg-transparent"
        >
          <img src={logoImg} alt={logoImg} className="w-8 sm:w-11" />
          <h2 className="text-2xl sm:text-3xl Light_Grey font-bold sm:block md:hidden lg:block">BMC Portal</h2>
          
        </NavLink>

        <ul className=" hidden md:flex items-center uppercase font-semibold text-sm">
          <li className="">
            <NavLink className="" to={"/"}>
              Home
            </NavLink>
          </li>
          <li className="">
            <NavLink to={"services"}>Services</NavLink>
          </li>
          <li className="">
            <NavLink to={"users"}>Users</NavLink>
          </li>
          <li className="">
            <NavLink to={"providers"}>Providers</NavLink>
          </li>
          <li className="">
            <NavLink to={"projects"}>Projects</NavLink>
          </li>
          <li className="">
            <NavLink to={"resources"}>Resources</NavLink>
          </li>
          <li className="">
            <NavLink to={"support"}>Support</NavLink>
          </li>
          <li className=" mr-4 cursor-pointer duration-300 ">
            <NavLink to={"account"} className="btn btn-sm uppercase ">
              Login/Sign Up
            </NavLink>
          </li>
        </ul>

        {/* Mobile Navigation Icon */}
        <div onClick={handleNav} className="block md:hidden mr-5">
          {nav ? (
            <img
              className="w-7"
              src="https://img.icons8.com/?size=100&id=8112&format=png&color=ffffff"
              alt="menuIconClose"
            />
          ) : (
            <img
              className="w-7"
              src="https://img.icons8.com/?size=100&id=36389&format=png&color=ffffff"
              alt="menuIconOpen"
            />
          )}
        </div>

        {/* Mobile Navigation Menu */}
        <ul
          className={
            nav
              ? "fixed md:hidden left-0 top-[68px] w-[60%] h-full border-r border-r-[#F6AE28] bg-[#D1D1D1] ease-in-out duration-500 text-[#191308]"
              : "ease-in-out w-[60%] duration-500 fixed top-[68px] bottom-0 left-[-100%] "
          }
        >
          
          <li>
            <NavLink
              to={"/"}
              onClick={handleNav}
              className="grid grid-flow-col no-underline px-4 py-2 border-b rounded-xl hover:bg-[#F6AE28] duration-300 hover:text-[#191308] cursor-pointer border-[#F6AE28]"
            >
              Home
            </NavLink>
          </li>
          <li>
            <NavLink
              to={"services"}
              onClick={handleNav}
              className="grid grid-flow-col no-underline px-4 py-2 border-b rounded-xl hover:bg-[#F6AE28] duration-300 hover:text-[#191308] cursor-pointer border-[#F6AE28]"
            >
              Services
            </NavLink>
          </li>
          <li>
            <NavLink
              to={"users"}
              onClick={handleNav}
              className="grid grid-flow-col no-underline px-4 py-2 border-b rounded-xl hover:bg-[#F6AE28] duration-300 hover:text-[#191308] cursor-pointer border-[#F6AE28]"
            >
              Users
            </NavLink>
          </li>
          <li>
            <NavLink
              to={"providers"}
              onClick={handleNav}
              className="grid grid-flow-col no-underline px-4 py-2 border-b rounded-xl hover:bg-[#F6AE28] duration-300 hover:text-[#191308] cursor-pointer border-[#F6AE28]"
            >
              Providers
            </NavLink>
          </li>
          <li>
            <NavLink
              to={"projects"}
              onClick={handleNav}
              className="grid grid-flow-col no-underline px-4 py-2 border-b rounded-xl hover:bg-[#F6AE28] duration-300 hover:text-[#191308] cursor-pointer border-[#F6AE28]"
            >
              Projects
            </NavLink>
          </li>
          <li>
            <NavLink
              to={"resources"}
              onClick={handleNav}
              className="grid grid-flow-col no-underline px-4 py-2 border-b rounded-xl hover:bg-[#F6AE28] duration-300 hover:text-[#191308] cursor-pointer border-[#F6AE28]"
            >
              Resources
            </NavLink>
          </li>
          <li>
            <NavLink
              to={"support"}
              onClick={handleNav}
              className="grid grid-flow-col no-underline px-4 py-2 border-b rounded-xl hover:bg-[#F6AE28] duration-300 hover:text-[#191308] cursor-pointer border-[#F6AE28]"
            >
              Support
            </NavLink>
          </li>
          <li>
            <NavLink
              to={"account"}
              onClick={handleNav}
              className="btn btn-sm uppercase cursor-pointer mt-2 ml-2 "
            >
              Login/Sign Up
            </NavLink>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Navbar;
