import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Pagination } from "@mui/material";

const AllPopularService = () => {
    const [page, setPage] = useState(1);
  const handleChange = (event, value) => {
    setPage(value);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="pt-16 max-w-screen-xl mx-auto">
      <div className="py-10 px-8 bg-white bg-opacity-10">
        <h2 className="text-xl md:text-4xl font-bold text-[#F6AE28] mb-4 pt-8 pb-6 sm:pt-0">
          Popular Services
        </h2>
        <div className="flex flex-wrap justify-center mx-auto lg:w-full md:w-5/6 xl:shadow-small-blue px-7">
          <div
            className="block w-full md:w-1/2 text-center px-1 pb-5 lg:w-1/4 "
            data-aos="flip-left"
            data-aos-easing="ease-out-cubic"
            data-aos-duration="500"
            data-aos-once="true"
          >
            <Link to={"/services/plumber"}>
              <div className="py-10 Orange_Yellow_bg relative">
                <img
                  src="https://img.icons8.com/?size=100&id=sxDSDWHItjUD&format=png&color=341f66"
                  className="block mx-auto"
                  alt="plumber icon"
                />

                <p className="pt-4 text-sm font-medium capitalize font-body Corporate_Blue lg:text-lg md:text-base md:pt-6">
                  plumber
                </p>
                <div className="absolute inset-0 bg-gradient-to-t hover:from-gray-300 hover:via-gray-300/40 animate-pulse ease-in"></div>
              </div>
            </Link>
          </div>
          <div
            className="  block w-full md:w-1/2 px-1 pb-5  text-center lg:w-1/4"
            data-aos="flip-left"
            data-aos-easing="ease-out-cubic"
            data-aos-duration="1000"
            data-aos-once="true"
          >
            <Link to={"/services/electrician"}>
              <div className="py-10 Orange_Yellow_bg relative">
                <img
                  src="https://img.icons8.com/?size=100&id=Z8GMtHiLgzDC&format=png&color=341f66"
                  className="block mx-auto"
                  alt="Electrician icon"
                />

                <p className="pt-4 text-sm font-medium capitalize font-body Corporate_Blue lg:text-lg md:text-base md:pt-6">
                  Electrician
                </p>
                <div className="absolute inset-0 bg-gradient-to-t hover:from-gray-300 hover:via-gray-300/40 animate-pulse ease-in"></div>
              </div>
            </Link>
          </div>
          <div
            className=" block w-full md:w-1/2 text-center px-1 pb-5  lg:w-1/4"
            data-aos="flip-left"
            data-aos-easing="ease-out-cubic"
            data-aos-duration="1500"
            data-aos-once="true"
          >
            <Link to={"/services/tiles"}>
              <div className="py-10 Orange_Yellow_bg relative">
                <img
                  src="https://img.icons8.com/?size=100&id=Z8xNL1K116ai&format=png&color=341f66"
                  className="block mx-auto"
                  alt="Builder icon"
                />

                <p className="pt-4 text-sm font-medium capitalize font-body Corporate_Blue lg:text-lg md:text-base md:pt-6">
                  Tiles
                </p>
                <div className="absolute inset-0 bg-gradient-to-t hover:from-gray-300 hover:via-gray-300/40 animate-pulse ease-in"></div>
              </div>
            </Link>
          </div>
          <div
            className="block w-full md:w-1/2 text-center px-1 pb-5  lg:w-1/4 "
            data-aos="flip-left"
            data-aos-easing="ease-out-cubic"
            data-aos-duration="2000"
            data-aos-once="true"
          >
            <Link to={"/services/painter"}>
              <div className="py-10 Orange_Yellow_bg relative">
                <img
                  src="https://img.icons8.com/?size=100&id=G9ieQh0RXL1N&format=png&color=341f66"
                  className="block mx-auto"
                  alt="Painter icon"
                />

                <p className="pt-4 text-sm font-medium capitalize font-body Corporate_Blue lg:text-lg md:text-base md:pt-6">
                  Painter
                </p>
                <div className="absolute inset-0 bg-gradient-to-t hover:from-gray-300 hover:via-gray-300/40 animate-pulse ease-in"></div>
              </div>
            </Link>
          </div>
          <div
            className="block w-full md:w-1/2 text-center px-1 pb-5 lg:w-1/4 "
            data-aos="flip-left"
            data-aos-easing="ease-out-cubic"
            data-aos-duration="500"
            data-aos-once="true"
          >
            <Link to={"/services/plumber"}>
              <div className="py-10 Orange_Yellow_bg relative">
                <img
                  src="https://img.icons8.com/?size=100&id=sxDSDWHItjUD&format=png&color=341f66"
                  className="block mx-auto"
                  alt="plumber icon"
                />

                <p className="pt-4 text-sm font-medium capitalize font-body Corporate_Blue lg:text-lg md:text-base md:pt-6">
                  plumber
                </p>
                <div className="absolute inset-0 bg-gradient-to-t hover:from-gray-300 hover:via-gray-300/40 animate-pulse ease-in"></div>
              </div>
            </Link>
          </div>
          <div
            className="  block w-full md:w-1/2 px-1 pb-5  text-center lg:w-1/4"
            data-aos="flip-left"
            data-aos-easing="ease-out-cubic"
            data-aos-duration="1000"
            data-aos-once="true"
          >
            <Link to={"/services/electrician"}>
              <div className="py-10 Orange_Yellow_bg relative">
                <img
                  src="https://img.icons8.com/?size=100&id=Z8GMtHiLgzDC&format=png&color=341f66"
                  className="block mx-auto"
                  alt="Electrician icon"
                />

                <p className="pt-4 text-sm font-medium capitalize font-body Corporate_Blue lg:text-lg md:text-base md:pt-6">
                  Electrician
                </p>
                <div className="absolute inset-0 bg-gradient-to-t hover:from-gray-300 hover:via-gray-300/40 animate-pulse ease-in"></div>
              </div>
            </Link>
          </div>
          <div
            className=" block w-full md:w-1/2 text-center px-1 pb-5  lg:w-1/4"
            data-aos="flip-left"
            data-aos-easing="ease-out-cubic"
            data-aos-duration="1500"
            data-aos-once="true"
          >
            <Link to={"/services/tiles"}>
              <div className="py-10 Orange_Yellow_bg relative">
                <img
                  src="https://img.icons8.com/?size=100&id=Z8xNL1K116ai&format=png&color=341f66"
                  className="block mx-auto"
                  alt="Builder icon"
                />

                <p className="pt-4 text-sm font-medium capitalize font-body Corporate_Blue lg:text-lg md:text-base md:pt-6">
                  Tiles
                </p>
                <div className="absolute inset-0 bg-gradient-to-t hover:from-gray-300 hover:via-gray-300/40 animate-pulse ease-in"></div>
              </div>
            </Link>
          </div>
          <div
            className="block w-full md:w-1/2 text-center px-1 pb-5  lg:w-1/4 "
            data-aos="flip-left"
            data-aos-easing="ease-out-cubic"
            data-aos-duration="2000"
            data-aos-once="true"
          >
            <Link to={"/services/painter"}>
              <div className="py-10 Orange_Yellow_bg relative">
                <img
                  src="https://img.icons8.com/?size=100&id=G9ieQh0RXL1N&format=png&color=341f66"
                  className="block mx-auto"
                  alt="Painter icon"
                />

                <p className="pt-4 text-sm font-medium capitalize font-body Corporate_Blue lg:text-lg md:text-base md:pt-6">
                  Painter
                </p>
                <div className="absolute inset-0 bg-gradient-to-t hover:from-gray-300 hover:via-gray-300/40 animate-pulse ease-in"></div>
              </div>
            </Link>
          </div>
          <div
            className="block w-full md:w-1/2 text-center px-1 pb-5 lg:w-1/4 "
            data-aos="flip-left"
            data-aos-easing="ease-out-cubic"
            data-aos-duration="500"
            data-aos-once="true"
          >
            <Link to={"/services/plumber"}>
              <div className="py-10 Orange_Yellow_bg relative">
                <img
                  src="https://img.icons8.com/?size=100&id=sxDSDWHItjUD&format=png&color=341f66"
                  className="block mx-auto"
                  alt="plumber icon"
                />

                <p className="pt-4 text-sm font-medium capitalize font-body Corporate_Blue lg:text-lg md:text-base md:pt-6">
                  plumber
                </p>
                <div className="absolute inset-0 bg-gradient-to-t hover:from-gray-300 hover:via-gray-300/40 animate-pulse ease-in"></div>
              </div>
            </Link>
          </div>
          <div
            className="  block w-full md:w-1/2 px-1 pb-5  text-center lg:w-1/4"
            data-aos="flip-left"
            data-aos-easing="ease-out-cubic"
            data-aos-duration="1000"
            data-aos-once="true"
          >
            <Link to={"/services/electrician"}>
              <div className="py-10 Orange_Yellow_bg relative">
                <img
                  src="https://img.icons8.com/?size=100&id=Z8GMtHiLgzDC&format=png&color=341f66"
                  className="block mx-auto"
                  alt="Electrician icon"
                />

                <p className="pt-4 text-sm font-medium capitalize font-body Corporate_Blue lg:text-lg md:text-base md:pt-6">
                  Electrician
                </p>
                <div className="absolute inset-0 bg-gradient-to-t hover:from-gray-300 hover:via-gray-300/40 animate-pulse ease-in"></div>
              </div>
            </Link>
          </div>
          <div
            className=" block w-full md:w-1/2 text-center px-1 pb-5  lg:w-1/4"
            data-aos="flip-left"
            data-aos-easing="ease-out-cubic"
            data-aos-duration="1500"
            data-aos-once="true"
          >
            <Link to={"/services/tiles"}>
              <div className="py-10 Orange_Yellow_bg relative">
                <img
                  src="https://img.icons8.com/?size=100&id=Z8xNL1K116ai&format=png&color=341f66"
                  className="block mx-auto"
                  alt="Builder icon"
                />

                <p className="pt-4 text-sm font-medium capitalize font-body Corporate_Blue lg:text-lg md:text-base md:pt-6">
                  Tiles
                </p>
                <div className="absolute inset-0 bg-gradient-to-t hover:from-gray-300 hover:via-gray-300/40 animate-pulse ease-in"></div>
              </div>
            </Link>
          </div>
          <div
            className="block w-full md:w-1/2 text-center px-1 pb-5  lg:w-1/4 "
            data-aos="flip-left"
            data-aos-easing="ease-out-cubic"
            data-aos-duration="2000"
            data-aos-once="true"
          >
            <Link to={"/services/painter"}>
              <div className="py-10 Orange_Yellow_bg relative">
                <img
                  src="https://img.icons8.com/?size=100&id=G9ieQh0RXL1N&format=png&color=341f66"
                  className="block mx-auto"
                  alt="Painter icon"
                />

                <p className="pt-4 text-sm font-medium capitalize font-body Corporate_Blue lg:text-lg md:text-base md:pt-6">
                  Painter
                </p>
                <div className="absolute inset-0 bg-gradient-to-t hover:from-gray-300 hover:via-gray-300/40 animate-pulse ease-in"></div>
              </div>
            </Link>
          </div>


        </div>
        <div className="!text-[#d1d1d1] pl-2 pt-5 sm:pt-10 text-lg  w-full flex items-end justify-end pr-10 ">
                <Pagination
                  count={10}
                  page={page}
                  onChange={handleChange}
                  color="primary"
                />
              </div>
      </div>
    </div>
  );
};

export default AllPopularService;
