import React from "react";

function Header() {
  return (
    <div className="Orange_Yellow_bg">
      <div className="h-[400px] md:h-[590px] ">
        <div className="max-w-screen-xl mx-auto pt-20 md:pt-36">
          {/* relative h-[400px] md:h-[570px] bg-gradient-to-b from-amber-600 via-amber-400 to-amber-50 */}
          <div className="flex flex-col gap-4 md:gap-8 justify-center items-center w-full h-full px-3 md:px-0">
            <h1
              data-aos-once="true" 
              data-aos="zoom-in"
              data-aos-duration="900"
              data-aos-easing="linear"
              id="typewriter"
              className="relative text-2xl md:text-xl lg:text-6xl font-bold Accent_Red text-center"
            >
              Connecting Homeowners with Trusted Construction Proffessionals
            </h1>
            <p 
            data-aos-once="true" 
            data-aos="fade-left"
            data-aos-duration="700"
            data-aos-easing="linear"
            className="Organic_Black relative text-center ">
              Hassle-free platform to source construction providers & products
            </p>

            <div 
            data-aos-once="true" 
            data-aos="fade-up"
            data-aos-duration="700"
            data-aos-easing="linear"
            className="relative p-3 border border_Light_Grey rounded-lg w-full max-w-lg">
              <input
                type="text"
                className="rounded-md w-full p-3 Light_Grey_bg placeholder:text-sm"
                placeholder="Search for service provider or products..."
              />

              <button
                type="submit"
                className="absolute right-6 top-4 Accent_Red font-bold"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="w-10 h-10 stroke-2"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
